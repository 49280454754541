import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

const DatePicker = ({ value, onChange }) => (
  <div className="datepicker-custom-wrapper">
    <ReactDatePicker selected={value} onChange={onChange} />
  </div>
);

DatePicker.propTypes = { value: PropTypes.any.isRequired, onChange: PropTypes.func.isRequired };

export default DatePicker;
