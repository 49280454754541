import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FontAwesomePro from "../../../../../components/FontAwesomePro";
import FiltersModal from "../../../../../components/FiltersModal";
import { categories } from "../../../../../utils/static";
import { setFilters, setCategories, setStatus } from "../../../../../redux/slices/tendersSearch";
import Status from "./Status";
import Budget from "./Budget";

import "./style.scss";

const Filters = () => {
  const dispatch = useDispatch();
  const { localCategories } = useSelector((state) => ({
    localCategories: state.tendersSearch.filters.categories || [],
  }));
  const [isFiltersModalShown, setIsFiltersModalShown] = useState(false);

  const categoriesListRef = useRef(null);
  const scroll = (scrollOffset) => {
    categoriesListRef.current.scrollLeft += scrollOffset;
  };

  const handleClear = () => {
    dispatch(setFilters({}));
    dispatch(setStatus("open"));
  };

  const handleClick = (item_value) => {
    let result;
    if (localCategories.includes(item_value)) {
      result = localCategories.filter((category) => category !== item_value);
    } else {
      result = [...localCategories, item_value];
    }
    dispatch(setCategories(result));
  };

  const handleModalFilters = () => setIsFiltersModalShown(true);

  return (
    <div className="filters-wrapper">
      <div className="filters">
        <button onClick={handleModalFilters} className="filter icon text-nowrap">
          <FontAwesomePro iconName="fas sliders-h" />
          Filters
        </button>
        <Budget />
        <Status />
      </div>
      <div className="categories">
        <button className="nav-button" onClick={() => scroll(-200)}>
          <FontAwesomePro iconName="fas chevron-left" />
        </button>
        <div className="categories-list" ref={categoriesListRef}>
          {categories.map((item) => (
            <div key={item}>
              <button
                className={`categories-item ${localCategories.includes(item) ? "active" : ""}`}
                onClick={() => handleClick(item)}>
                {item}
              </button>
            </div>
          ))}
        </div>
        <button className="nav-button" onClick={() => scroll(200)}>
          <FontAwesomePro iconName="fas chevron-right" />
        </button>
        <button onClick={handleClear} className="clear-filters">
          Clear
        </button>
      </div>
      {isFiltersModalShown && (
        <FiltersModal visible={isFiltersModalShown} changeVisible={setIsFiltersModalShown} />
      )}
    </div>
  );
};

export default Filters;
