import httpService from "./http.service";

const tenderUrl = "/tenders";
const watchListUrl = "/watchlist";

const GetAll = (pageNumber, tenderState, pageLimit, filters, yourProjects) => {
  return httpService
    .post(
      `${tenderUrl}/page/${pageNumber}/limit/${pageLimit}/state/${tenderState}?${
        yourProjects ? "isYourProjects=true" : ""
      }`,
      filters
    )
    .then(({ data }) => {
      console.log("GetAll response", data);
      return data;
    })
    .catch((err) => Promise.reject(err.message));
};

const GetTenderCompanies = ({ tender_title }) => {
  const params = new URLSearchParams();
  params.set("tender_title", tender_title);
  return httpService
    .get(`${tenderUrl}/getCompanies`, {}, { params })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));
};

const UpdateWatchList = (idProject, idWatch, idCompany) =>
  httpService
    .post(`${tenderUrl}/watch/update`, {
      idProject,
      idWatch,
      idCompany,
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

const GetCompanyWatchList = (idCompany) =>
  httpService
    .get(`${watchListUrl}?id_company=${idCompany}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

const CreateNewTenders = (tenderData) =>
  httpService
    .post(`${tenderUrl}/create`, tenderData)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

const GetTenderHistory = ({ id_tender }) =>
  httpService
    .get(`${tenderUrl}/${id_tender}/history`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

export default {
  GetAll,
  UpdateWatchList,
  CreateNewTenders,
  GetTenderCompanies,
  GetTenderHistory,
  GetCompanyWatchList,
};
