import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import logo from "../../utils/images/logo_signin.png";

import "./style.scss";
// import usersData from "../../redux/reducers/users";

const AfterSignUp = ({ sentEmail }) => (
  <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white w-lg-45">
    <div className="absolute-top-right d-lg-none p-5">
      <a href="/confirmWait" className="toggle btn btn-white btn-icon btn-light" data-target="athPromo">
        <em className="icon ni ni-info" />
      </a>
    </div>
    <div className="nk-block nk-block-middle nk-auth-body">
      <div className="brand-logo pb-1">
        <a href="/" className="logo-link">
          <div className="brand-logo pb-5">
            <Link to="/" className="logo-link">
              <img className="logo-dark logo-img logo-img-lg" src={logo} alt="logo" />
            </Link>
          </div>
        </a>
      </div>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">Let`s get Started!</h5>
          <div className="nk-block-des text-success">
            <p className="text-dark text-decoration-none">Please check your email for verification link.</p>
            <p className="text-dark text-decoration-none">
              We have sent this to:&nbsp;
              <label className="text-blue">{sentEmail}</label>
            </p>
          </div>
        </div>
      </div>
      <div className="form-note-s2 pt-4">
        Having trouble? Contact us at
        <br />
        <a href="mailto:support@tndr.com.au" className="text-blue">
          <strong>support@tndr.com.au</strong>
        </a>
      </div>
    </div>
  </div>
);
AfterSignUp.propTypes = {
  sentEmail: PropTypes.string,
};

AfterSignUp.defaultProps = {
  sentEmail: "",
};

const mapStateToProps = (store) => ({
  sentEmail: store.usersData.sentEmail,
});

export default connect(mapStateToProps)(AfterSignUp);
