import httpService from "./http.service";

const userUrl = "/users";

const UpdateUser = (data) =>
  httpService
    .post(`${userUrl}/update`, { data })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

export default { UpdateUser };
