import React from "react";

import "./style.scss";

const VideoPlayer = () => {
  return (
    <div className="video-wrapper">
      <img src={"images/auth-background.png"} className="video" alt="placeholder-image" />
      {/*<video id="video-player" preload="auto" autoPlay="autoplay" muted className="video" loop="loop">*/}
      {/*  <source src={"videos/main.webm"} type="video/webm" />*/}
      {/*  <source src={"videos/main.mp4"} type="video/mp4" />*/}
      {/*  Your browser does not support the video tag.*/}
      {/*</video>*/}
    </div>
  );
};

export default VideoPlayer;
