import { getDistance } from "geolib";

import types from "../actionType";

const INITIAL_STATE = {
  isFetchingList: false,
  isCreating: false,
  isUpdating: false,
  tenders: [],
  tenderState: "open",
  page: 1,
  limit: 10,
  totalFiltersLength: 0,
  openTendersLength: 0,
  openFilterTenderLength: 0,
  closeTenderLength: 0,
  closeFilterTenderLength: 0,
  awardedTenders: 0,
  awardedFilterTenders: 0,
  hiddenLength: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TENDERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingList: true,
      };
    case types.TENDERS_FETCH_SUCCESS:
      const tendersData = action.payload.data;

      const { companyOffice } = action.payload;

      // enrich with distance
      const newTendersData = [...tendersData.data].reduce((acc, cur) => {
        if (!companyOffice.company_office_latitude || !companyOffice.company_office_longitude) {
          return acc.concat(cur);
        }

        const projectCoordinates = {
          latitude: cur.location_latitude,
          longitude: cur.location_longitude,
        };
        const companyCoordinates = {
          latitude: companyOffice.company_office_latitude,
          longitude: companyOffice.company_office_longitude,
        };

        const distance = Math.round(100 * (getDistance(companyCoordinates, projectCoordinates) / 1000)) / 100;
        const enrichedProject = {
          ...cur,
          distance: `${distance} km`,
        };
        return acc.concat(enrichedProject);
      }, []);

      const newTState = action.payload.tenderState;
      let { openTendersLength } = state;
      let filterOpenTenderLength = state.openFilterTenderLength;
      let { closeTenderLength } = state;
      let { totalFiltersLength } = state;
      let { hiddenLength } = state;
      let filterCloseTenderLength = state.closeFilterTenderLength;
      switch (newTState) {
        case "open":
          openTendersLength = tendersData.openLength[0].count;
          filterOpenTenderLength = tendersData.total[0].count;
          closeTenderLength = tendersData.closeLength[0].count;
          totalFiltersLength = tendersData.total[0].count;
          hiddenLength = openTendersLength - filterOpenTenderLength;
          break;
        case "closed":
          filterCloseTenderLength = tendersData.total[0].count;
          closeTenderLength = tendersData.closeLength[0].count;
          totalFiltersLength = tendersData.total[0].count;
          hiddenLength = closeTenderLength - filterCloseTenderLength;
          break;
        case "awarded":
          break;
        default:
          openTendersLength = tendersData.openLength[0].count;
          filterOpenTenderLength = tendersData.total[0].count;
          closeTenderLength = tendersData.closeLength[0].count;
          totalFiltersLength = tendersData.total[0].count;
          break;
      }
      const { pageNumber, pageLimit } = action.payload;

      return {
        ...state,
        isFetchingList: false,
        tenders: newTendersData,
        tenderState: newTState,
        hiddenLength,
        openTendersLength,
        totalFiltersLength,
        openFilterTenderLength: filterOpenTenderLength,
        closeTenderLength,
        closeFilterTenderLength: filterCloseTenderLength,
        page: pageNumber,
        limit: pageLimit,
      };
    case types.TENDERS_FETCH_FAILURE:
      return {
        ...state,
        isFetchingList: false,
      };
    case types.WATCH_LIST_OPTION_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.WATCH_LIST_OPTION_SUCCESS:
      const { idProject, idWatch } = action.payload;
      const willUpdateTenders = state.tenders;
      const willUpdateTenderItemIndex = willUpdateTenders.findIndex(
        (tenderItem) => tenderItem.id === idProject
      );
      if (willUpdateTenderItemIndex > -1) {
        willUpdateTenders.splice(willUpdateTenderItemIndex, 1, {
          ...willUpdateTenders[willUpdateTenderItemIndex],
          watch_list: idWatch,
        });
      }
      return {
        ...state,
        tenders: willUpdateTenders.slice(0),
        isUpdating: false,
      };
    case types.WATCH_LIST_OPTION_FAILURE:
      return {
        ...state,
        isUpdating: false,
      };
    default:
      return state;
  }
};
