import { useSelector } from "react-redux";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomSelectSetting from "../../components/Elements/CustomSelectSetting";
import logo from "../../utils/images/logo-light.png";

import HeaderTabs from "./HeaderTabs";

export const profile = [
  {
    label: "Settings",
    key: "settings",
  },
  {
    label: "Logout",
    key: "logout",
  },
  {
    label: "View Guides & FAQs",
    key: "view_guide",
  },
  {
    label: "Contact TNDR(r)",
    key: "contact_tndr",
  },
];

function DashHeader({ authUser, signOut, url }) {
  const [menu, setMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const { company_name } = useSelector((state) => ({
    company_name: state.usersData && state.usersData.authUser.data.authUser.company_name,
  }));

  return (
    <div
      className={`dashboard-header ${
        openMenu ? "dashboard-header-open-menu" : ""
      } d-flex flex-column justify-center w-100`}>
      <div className="header-menu justify-content-between d-flex align-items-center">
        <div className="dashboard-header_logosection">
          <Link to="/dashboard" className="logo-link">
            <img src={logo} className="dh-logo-img" alt="dashboard logo" />
          </Link>
          <div className="dh-logo-string">{company_name}</div>
        </div>
        <HeaderTabs
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          setMenu={setMenu}
          menu={menu}
          authUser={authUser}
          signOut={signOut}
          url={url}
        />
        {menu && (
          <div className="dashboard-header_selector">
            <CustomSelectSetting authUser={authUser} signOut={signOut} />
          </div>
        )}
      </div>
    </div>
  );
}

DashHeader.propTypes = {
  authUser: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default DashHeader;
