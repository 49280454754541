import { createSlice } from "@reduxjs/toolkit";
import storage from "../../services/storage.service";

const initialState = {
  filters: storage.getItem("filters") || {},
  displayState: { budgetValue: "", tradeValue: "", categoriesValue: "", moreValue: false },
  status: (storage.getItem("filters") && storage.getItem("filters").status) || "",
};

const tendersSearchSlice = createSlice({
  name: "tendersSearch",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
      storage.setItem("filters", { ...state.filters, status: state.status });
    },
    setCategories: (state, action) => {
      state.filters.categories = action.payload;
      storage.setItem("filters", { ...state.filters, status: state.status });
    },
    setDisplayState: (state, action) => {
      state.displayState = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
      storage.setItem("filters", { ...state.filters, status: state.status });
    },
  },
});

export const { actions, reducer } = tendersSearchSlice;

export const { setCategories, setFilters, setDisplayState, setStatus } = actions;

export default reducer;
