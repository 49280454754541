import httpService from "./http.service";

const tenderUrl = "/directory";

const GetAll = (pageNumber, pageLimit, filters) =>
  httpService
    .post(`${tenderUrl}/page/${pageNumber}/limit/${pageLimit}`, filters)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

const SetFavorite = (id, value) =>
  httpService
    .post(`${tenderUrl}/setfavorite`, {
      id,
      value,
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

export default {
  GetAll,
  SetFavorite,
};
