export default {
  // Fetch countries
  OPTION_FETCH_REQUEST: "OPTION_FETCH_REQUEST",
  OPTION_FETCH_SUCCESS: "OPTION_FETCH_SUCCESS",
  OPTION_FETCH_FAILURE: "OPTION_FETCH_FAILURE",

  // User Sign up
  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",

  // User Sign in
  SIGN_IN_REQUEST: "SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",

  // Update password
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAIL: "UPDATE_PASSWORD_FAIL",

  // Log out
  LOG_OUT_REQUEST: "LOG_OUT_REQUEST",
  LOG_OUT_SUCCESS: "LOG_OUT_SUCCESS",
  LOG_OUT_FAILURE: "LOG_OUT_FAILURE",

  // Fetching tenders
  TENDERS_FETCH_REQUEST: "TENDERS_FETCH_REQUEST",
  TENDERS_FETCH_SUCCESS: "TENDERS_FETCH_SUCCESS",
  TENDERS_FETCH_FAILURE: "TENDERS_FETCH_FAILURE",

  // Define Show Data
  DEFINE_SHOW_DATA: "DEFINE_SHOW_DATA",

  // Update Watch list option
  WATCH_LIST_OPTION_REQUEST: "WATCH_LIST_OPTION_REQUEST",
  WATCH_LIST_OPTION_SUCCESS: "WATCH_LIST_OPTION_SUCCESS",
  WATCH_LIST_OPTION_FAILURE: "WATCH_LIST_OPTION_FAILURE",

  // Company Watch list
  COMPANY_WATCH_LIST_REQUEST: "COMPANY_WATCH_LIST_REQUEST",
  COMPANY_WATCH_LIST_SUCCESS: "COMPANY_WATCH_LIST_SUCCESS",
  COMPANY_WATCH_LIST_FAILURE: "COMPANY_WATCH_LIST_FAILURE",

  // Fetching directories
  DIRECTORIES_FETCH_REQUEST: "DIRECTORIES_FETCH_REQUEST",
  DIRECTORIES_FETCH_SUCCESS: "DIRECTORIES_FETCH_SUCCESS",
  DIRECTORIES_FETCH_FAILURE: "DIRECTORIES_FETCH_FAILURE",

  // Fetching directories
  DIRECTORIES_SET_FAVORITE_REQUEST: "DIRECTORIES_SET_FAVORITE_REQUEST",
  DIRECTORIES_SET_FAVORITE_SUCCESS: "DIRECTORIES_SET_FAVORITE_SUCCESS",
  DIRECTORIES_SET_FAVORITE_FAILURE: "DIRECTORIES_FETCH_FAILURE",

  // Create New TenderData
  CREATE_NEW_TENDER_REQUEST: "CREATE_NEW_TENDER_REQUEST",
  CREATE_NEW_TENDER_SUCCESS: "CREATE_NEW_TENDER_SUCCESS",
  CREATE_NEW_TENDER_FAIL: "CREATE_NEW_TENDER_FAIL",

  // Update user
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_TRADES: "UPDATE_USER_TRADES",

  // Update company
  UPDATE_COMPANY_OFFICE: "UPDATE_COMPANY_OFFICE",

  // Clear errors
  CLEAR_ERRORS: "CLEAR_ERRORS",
};
