import React from "react";
import { Link } from "react-router-dom";
import logo from "../../utils/images/logo_signin.png";

function SigninHeader() {
  return (
    <>
      <div className="logo-wrapper">
        <Link to="/" className="logo-link">
          <img className="logo-dark logo-img logo-img-lg" src={logo} alt="logo" />
        </Link>
      </div>
      <div className="title-wrapper">
        <h5 className="title">Log in</h5>
        <div className="subtitle">
          <p>Please insert email and password correctly</p>
        </div>
      </div>
    </>
  );
}

export default SigninHeader;
