import { getDistance } from "geolib";
import types from "../actionType";

const INITIAL_STATE = {
  isFetchingList: false,
  isCreating: false,
  isUpdatingFlag: false,
  directories: [],
  page: 1,
  limit: 10,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DIRECTORIES_FETCH_REQUEST:
      return {
        ...state,
        isFetchingList: true,
      };
    case types.DIRECTORIES_FETCH_SUCCESS:
      const { data, companyOffice } = action.payload;

      const newDirectories = [...data].reduce((acc, cur) => {
        if (!cur.company_office_longitude || !cur.company_office_latitude) {
          return acc.concat(cur);
        }

        if (!companyOffice.company_office_longitude || !companyOffice.company_office_latitude) {
          return acc.concat(cur);
        }

        const builderCoordinates = {
          latitude: cur.company_office_latitude,
          longitude: cur.company_office_longitude,
        };

        const companyCoordinates = {
          latitude: companyOffice.company_office_latitude,
          longitude: companyOffice.company_office_longitude,
        };

        const distance = Math.round(100 * (getDistance(companyCoordinates, builderCoordinates) / 1000)) / 100;
        const enrichedCompanyInDirectory = {
          ...cur,
          distance: `${distance} km`,
        };
        return acc.concat(enrichedCompanyInDirectory);
      }, []);

      return {
        ...state,
        isFetchingList: false,
        directories: newDirectories,
      };
    case types.DIRECTORIES_FETCH_FAILURE:
      return {
        ...state,
        isFetchingList: false,
      };
    case types.DIRECTORIES_SET_FAVORITE_REQUEST:
      return {
        ...state,
        isUpdatingFlag: true,
      };
    case types.DIRECTORIES_SET_FAVORITE_SUCCESS:
      return {
        ...state,
        isUpdatingFlag: false,
      };
    case types.DIRECTORIES_SET_FAVORITE_FAILURE:
      return {
        ...state,
        isUpdatingFlag: false,
      };
    default:
      return state;
  }
};
