import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Select, Table } from "antd";
import { SettingOutlined, DownloadOutlined, EyeOutlined, DownOutlined } from "@ant-design/icons";

import { setFavorite } from "../../../redux/action/directory";

import "./style.scss";

const { Option } = Select;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: "8%",
    render: (id) => <span className="table_id">{id}</span>,
  },
  {
    title: "Project Name",
    dataIndex: "project",
    key: "project",
    width: "10%",
    render: (project) => (
      <div className="project_name">
        <span className="table_max_budget">{project}</span>
      </div>
    ),
  },
  {
    title: "Invited By",
    dataIndex: "invited",
    key: "invited",
    width: "12%",
    render: (invited) => (
      <div className="invited">
        <span className="invited_container">{invited}</span>
      </div>
    ),
  },
  {
    title: "Team Member",
    key: "team",
    dataIndex: "team",
    width: "10%",
    render: (team) =>
      team.map((team, index) => (
        <span key={index} className="team_member  ">
          {team}
        </span>
      )),
  },
  {
    title: "Location",
    key: "location",
    dataIndex: "location",
    width: "10%",
    render: (location) => (
      <span className="table_location">
        <span>{location}</span>
      </span>
    ),
  },
  {
    title: "Budget",
    key: "budget",
    dataIndex: "budget",
    width: "10%",
    render: (budget) => (
      <div className="table_quotes">
        <span>{`$${budget[0]} - $${budget[1]}`}</span>
      </div>
    ),
  },
  {
    title: "Package",
    key: "package",
    dataIndex: "package",
    width: "10%",
    render: (value) => <span className="package">{value}</span>,
  },
  {
    title: "Quotes Due",
    key: "quotes_due",
    dataIndex: "quotes_due",
    width: "10%",
    render: (quotes) => (
      <span className="table_quotes">
        {quotes.map((item) => (
          <span key={item.date_tender_close} className="quote_item">
            <span>{item.date_tender_close}</span>
          </span>
        ))}
      </span>
    ),
  },
  {
    title: "Your Response",
    key: "response",
    dataIndex: "response",
    width: "10%",
    render: (valueSet) => (
      <Select
        className="table_watchlist"
        style={{ width: 150 }}
        value={valueSet.value}
        onChange={valueSet.onChange}
        onClick={valueSet.onClick}>
        {valueSet.data.map((selectItem) => (
          <Option key={selectItem.key} value={selectItem.key} disabled={selectItem.key === 0}>
            {selectItem.value}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    title: "Doc Status",
    key: "docstatus",
    dataIndex: "docstatus",
    width: "10%",
    render: (value) => <span className={`docstatus ${value}`}>{value}</span>,
  },
  {
    title: () => <SettingOutlined />,
    key: "setting",
    dataIndex: "setting",
    render: () => (
      <div className="settings">
        <button>
          <DownloadOutlined style={{ fontSize: "28px" }} />
        </button>
        <button>
          <EyeOutlined style={{ fontSize: "28px" }} />
        </button>
        <button>
          <DownOutlined style={{ fontSize: "28px" }} />
        </button>
      </div>
    ),
  },
];

function InvitationTable({ data }) {
  const onResponseClick = (e) => {
    e.stopPropagation();
  };

  const tableData = data.map((dataItem, index) => ({
    key: `table-${index}`,
    id: dataItem.id,
    project: dataItem.project,
    invited: dataItem.invited,
    team: dataItem.team,
    budget: dataItem.budget,
    location: dataItem.location,
    package: dataItem.package,
    quotes_due: dataItem.quotes_due,
    response: dataItem.response,
    docstatus: dataItem.docstatus,
  }));

  return (
    <div className="invitation-table-wrapper">
      <Table
        rowSelection={{
          type: "checkbox",
          hideSelectAll: true,
        }}
        columns={columns}
        width={100}
        dataSource={tableData}
        pagination={false}
      />
    </div>
  );
}

InvitationTable.propTypes = {
  data: PropTypes.array,
};

InvitationTable.defaultProps = {
  data: [],
};

const mapStateToProps = (store) => ({
  isUpdatingFlag: store.directoryData.isUpdatingFlag,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFavorite,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InvitationTable);
