import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { bindActionCreators } from "redux";

import QuoteList from "./container/Quotes";
import Quotes from "./container/Dashboard/Quotes";
// import ResetPassword from "./container/Authentication/Signup/ResetPassword";
// import Dashboard from "./container/Dashboard";
import Directory from "./container/Dashboard/Directory";
import MoveToHome from "./MoveToHome";
import NewProject from "./container/Dashboard/NewProject";
// import Invitations from "./container/Invitations";
import Watchlist from "./container/Watchlist";
import Settings from "./container/Settings";

import "./App.css";
import DashHeader from "./container/Dashboard/DashHeader";
import Footer from "./components/Footer";
import { signOut, fetchOptionInfo } from "./redux/action/users";
import "./container/Authentication/Signup/style.scss";
import "./app-style.scss";
import AllTenders from "./container/Dashboard/AllTenders";
import { fetchDirectoryData } from "./redux/action/directory";
import StepPage from "./container/Authentication/Signup/StepPage";
import { loginSteps } from "./utils/static";

function App({ authUser, signOut }) {
  const { filters, status, user_type } = useSelector((state) => ({
    filters: state.tendersSearch.filters,
    status: state.tendersSearch.status,
    user_type: state.usersData.authUser.data && state.usersData.authUser.data.authUser.user_type,
  }));

  const [fetching, setFetching] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user_type) {
      setFetching(true);
      Promise.all(dispatch(fetchDirectoryData()), dispatch(fetchOptionInfo()))
        .then(() => {
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    }
  }, [dispatch, filters, status, user_type]);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={MoveToHome} />
          <Route exact path="/signup" render={() => <StepPage step={loginSteps.signUp} />} />
          <Route exact path="/login" render={() => <StepPage step={loginSteps.login} />} />
          <Route exact path="/confirmWait" render={() => <StepPage step={loginSteps.afterSignUp} />} />
          <Route exact path="/forgotpassword" render={() => <StepPage step={loginSteps.forgotPassword} />} />
          <Route exact path="/resetpassword" render={() => <StepPage step={loginSteps.resetPassword} />} />
          <Route exact path="/updatepassword" render={() => <StepPage step={loginSteps.updatePassword} />} />
          {authUser.data ? (
            <div className="dashboard app-main-wrapper">
              <div className="dashboard-wrapper">
                <DashHeader authUser={authUser.data.authUser} signOut={signOut} url="path" />
                <Route path="/dashboard" render={() => <AllTenders yourProjects={user_type === "1"} />} />
                <Route exact path="/quote_list" component={() => <QuoteList fetching={fetching} />} />
                <Route path="/alltenders" component={AllTenders} />
                <Route path="/quotes/:id" component={Quotes} />
                {/* <Route path="/invitations" component={Invitations} /> */}
                <Route path="/watchlist" component={Watchlist} />
                <Route path="/db" component={Directory} />
                <Route path="/team" component={AllTenders} />
                <Route path="/account/profile" component={Settings} />
                <Route path="/new-project" component={NewProject} />
              </div>
              <Footer />
            </div>
          ) : (
            <Redirect to="/login" />
          )}
        </Switch>
      </Router>
    </div>
  );
}

App.propTypes = {
  authUser: PropTypes.object,
  signOut: PropTypes.func.isRequired,
};

App.defaultProps = {
  authUser: {},
};

const mapStateToProps = (store) => ({
  authUser: store.usersData.authUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
