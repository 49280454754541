import React from "react";
import PropTypes from "prop-types";

const SettingsHeader = ({ tabs, activeTab, setActiveTab }) => {
  const handleClickTab = (e) => {
    setActiveTab(tabs.find((tab) => tab.name === e.target.innerHTML));
  };

  return (
    <div className="settings-bar">
      <div className="container">
        <ul className="menu-items">
          {tabs.map((tab) => (
            <li key={tab.name}>
              <span onClick={handleClickTab} className={`tab ${tab === activeTab ? "active" : ""}`}>
                {tab.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

SettingsHeader.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

SettingsHeader.defaultProps = {
  tabs: [],
  activeTab: {},
  setActiveTab: () => {},
};

export default SettingsHeader;
