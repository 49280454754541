import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { newProjectSlice } from "../../../../../redux/newProject/slice";
import LocationSearchInput from "../../../../../components/Elements/PlaceAutoComplete";

const NewProjectAddress = () => {
  const [setAddress, SetAddressAction] = useState("");

  const dispatch = useDispatch();
  const { locationState } = useSelector((state) => ({
    locationState: state.usersData.locationState,
  }));

  const onChange = useCallback(
    (address, addressObj = null) => {
      let idState = null;
      if (addressObj) {
        const stateObj = locationState.findIndex((item) => item.value === addressObj.state);
        if (stateObj >= 0) {
          idState = locationState[stateObj].key;
        }

        dispatch(
          newProjectSlice.actions.setAddress({
            street: addressObj.street || "",
            suburb: addressObj.suburb,
            postCode: addressObj.zip || null,
            latitude: addressObj.latitude,
            longitude: addressObj.longitude,
            idState,
          })
        );
      }
      SetAddressAction(address);
    },
    [dispatch, locationState]
  );

  return (
    <Form.Item
      name="address"
      rules={[
        {},
        () => ({
          validator(_, value) {
            if (!setAddress && !value) return Promise.reject("This Value required!");

            return Promise.resolve();
          },
        }),
      ]}>
      <LocationSearchInput setCompanyLocation={onChange} companyAddress={setAddress} />
    </Form.Item>
  );
};

export default NewProjectAddress;
