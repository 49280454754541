import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";

import { setFavorite } from "../../../redux/action/directory";

import "./style.scss";

const columns = [
  {
    title: "Builder",
    dataIndex: "builder",
    key: "builder",
    width: "35%",
    render: (title) => (
      <div className="table_prj_name">
        <span className="table-content">
          <span className="table_id">{title}</span>
        </span>
      </div>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    width: "10%",
    render: (phone) => <span className="table_max_budget">{phone}</span>,
  },
  {
    title: "Fax",
    dataIndex: "fax",
    key: "fax",
    width: "8%",
    render: (fax) => <span className="table_distance">{fax}</span>,
  },
  {
    title: "Location",
    key: "location",
    dataIndex: "location",
    width: "11%",
    render: (location) => <span className="table_category">{location}</span>,
  },
  {
    title: "Dist",
    key: "dist",
    dataIndex: "dist",
    width: "10%",
    render: (dist) => (
      <span className="table_quotes">
        <span>{dist}</span>
      </span>
    ),
  },
  {
    title: "# Current Tenders ",
    key: "current_tenders",
    dataIndex: "current_tenders",
    width: "10%",
    render: (currentTender) => (
      <span className="table_quotes">
        <span>{currentTender}</span>
      </span>
    ),
  },
  {
    title: "# Awarded Tenders ",
    key: "awarded_tenders",
    dataIndex: "awarded_tenders",
    width: "10%",
    render: (currentTender) => (
      <span className="table_quotes">
        <span>{currentTender}</span>
      </span>
    ),
  },
];

function DirectoryTable({ data, setFavorite, isUpdatingFlag, limit, page, fetchData, filter }) {
  const onSetFavorite = (id, value) => () => {
    if (!isUpdatingFlag) {
      setFavorite(id, value).then(() => {
        fetchData(page, limit, filter);
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (!isUpdatingFlag) {
        setFavorite("all", selectedRows.length > 0).then(() => {
          fetchData(page, limit, filter);
        });
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
    renderCell: (value, item) => {
      return (
        <FontAwesomeIcon
          icon={faStar}
          color={item.favorite ? "#ffd600" : "#ddd"}
          onClick={onSetFavorite(item.id, !item.favorite)}
        />
      );
    },
  };

  const tableData = data.map((dataItem, index) => ({
    key: `table-${index}`,
    id: dataItem.id,
    favorite: dataItem.favorite,
    builder: dataItem.builder,
    phone: dataItem.phone,
    fax: dataItem.fax,
    location: dataItem.location,
    dist: dataItem.dist,
    current_tenders: dataItem.current_tenders,
    awarded_tenders: dataItem.awarded_tenders,
  }));

  return (
    <div className="custom-table-wrapper">
      <Table columns={columns} width={100} dataSource={tableData} pagination={false} />
    </div>
  );
}

DirectoryTable.propTypes = {
  data: PropTypes.array,
  isUpdatingFlag: PropTypes.bool,
  setFavorite: PropTypes.func,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
};

DirectoryTable.defaultProps = {
  data: [],
  isUpdatingFlag: false,
  setFavorite: () => {},
};

const mapStateToProps = (store) => ({
  isUpdatingFlag: store.directoryData.isUpdatingFlag,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFavorite,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DirectoryTable);
