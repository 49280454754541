import React, { useEffect, useState } from "react";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faDownload } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { Table, Space, Select, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import QuotesService from "../../services/quotes.service";

const QuotesBody = ({ fetching, id_project }) => {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tenderTradeFiles, setTenderTradeFiles] = useState({});

  const { industryTrades, authUser } = useSelector((state) => ({
    industryTrades: state.usersData.industryTrades,
    authUser: state.usersData.authUser.data.authUser,
  }));

  useEffect(() => {
    if (!fetching && industryTrades.length) {
      if (id_project) {
        QuotesService.GetProjectQuotes({ id_project }).then((res) => {
          setQuotes(res);
          setLoading(false);
        });
      } else {
        QuotesService.GetQuotes().then((res) => {
          setQuotes(res);
          setLoading(false);
        });
      }
    }
  }, [fetching, id_project, industryTrades.length]);

  const isBuilder = authUser.user_type === "1" || authUser.user_type === 1;

  if (!loading && !quotes.length)
    return (
      <div className="quotes-body">
        <div className="no-quotes">No results. Please upload quote(s) to use this page.</div>
      </div>
    );

  const handleSelect = (value) => {
    const tradeFile = JSON.parse(value);
    setTenderTradeFiles({ ...tenderTradeFiles, [tradeFile.id_quote]: { file_name: tradeFile.file_name } });
  };

  const columns = [
    {
      title: "Tender title",
      dataIndex: "tender_title",
      key: "tender_title",
    },
    {
      title: "Builder",
      dataIndex: "builder",
      key: "builder",
    },
    {
      title: "Amount",
      dataIndex: "quote_amount",
      key: "quote_amount",
      render: (text) => `$${text}`,
    },
    {
      title: "Message",
      dataIndex: "quote_message",
      key: "quote_message",
    },
    {
      title: "Created Date",
      dataIndex: "created_time",
      key: "created_time",
      render: (text) => <span className="text-nowrap">{text}</span>,
    },
    {
      title: isBuilder ? "Submission Files" : "Trade",
      key: "packages",
      render: (data) => (
        <Select
          className="w-100 select-package"
          defaultActiveFirstOption={true}
          onSelect={handleSelect}
          options={data.packages.map((option) => ({
            label: industryTrades.find((trade) => trade.key === option.id).value,
            value: JSON.stringify({
              id_quote: data.id,
              file_name: option.file_name,
              id_trade: option.id,
            }),
          }))}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Space size="middle">
          <Button
            className="action-button view-button"
            disabled={!tenderTradeFiles[data.id] || !tenderTradeFiles[data.id].file_name}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_API_ORIGIN}/files/quotes/${
                tenderTradeFiles[data.id]?.file_name
              }`}>
              <FontAwesomeIcon icon={faEye} />
              <span className="ml-2">View</span>
            </a>
          </Button>
          <Button
            className="action-button download-button"
            disabled={!tenderTradeFiles[data.id] || !tenderTradeFiles[data.id].file_name}>
            <a
              rel="noreferrer"
              href={`${process.env.REACT_APP_API}/files/quote_file/${tenderTradeFiles[data.id]?.file_name}`}>
              <FontAwesomeIcon icon={faDownload} />
              <span className="ml-2">Download</span>
            </a>
          </Button>
        </Space>
      ),
    },
  ];

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="quotes-body">
      {loading ? (
        <>
          <Spin indicator={antIcon} />
        </>
      ) : (
        <Table
          className="w-70 quotes-table"
          rowSelection={{
            onChange: (_) => {},
          }}
          scroll={{ x: 700 }}
          columns={columns}
          dataSource={quotes}
        />
      )}
    </div>
  );
};

export default QuotesBody;
