import React from "react";

import { Switch } from "antd";

import "./style.scss";

const getNotificationItem = (title, text) => (
  <div className="notification-item">
    <div className="notification-item__header">{title}</div>
    <div className="notification-item__body">
      <Switch disabled />
      <div className="notification-item__text">{text}</div>
    </div>
  </div>
);

const notificationItems = [
  {
    title: "Happy Monday",
    text: "A weekly snapshot of new tenders that have been added to TNDR in the last week.",
  },
  {
    title: "Midweek Update",
    text: "A weekly update on projects that have been added or recently awarded where you trade is required.",
  },
  { title: "Watchlist Update", text: "A regular update on projects you have added to your watchlist." },
  {
    title: "Tender Invitation Reminder",
    text: "Reminder to update your quoting status for projects builders have invited you tender on.",
  },
  {
    title: "Tender Status Change",
    text: "Sent when the status of a project you have been asked to tender on changes.",
  },
];

const Notifications = () => {
  return (
    <div className="notifications-settings-wrapper">
      <div className="page-header">
        <div className="title">Notification Settings</div>
        <div className="subtitle">You will get only notification what have enabled.</div>
      </div>
      <div className="notifications-wrapper">
        {notificationItems.map((item) => getNotificationItem(item.title, item.text))}
      </div>
    </div>
  );
};

export default Notifications;
