/* eslint-disable max-len */
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import authService from "../../services/auth.service";

import logo from "../../utils/images/logo_signin.png";

import "./style.scss";

const ForgotPWD = () => {
  const [form] = Form.useForm();
  const [loadingFg, setLoadingFg] = useState(false);
  const [isReseted, setIsReseted] = useState(false);
  const [address, setAddress] = useState("");
  const handleFinishFailed = useCallback((errorInfo) => {
    console.log("Failed:", errorInfo);
  }, []);

  console.log("render forgot pass");

  const handleFinish = useCallback(async (data) => {
    console.log("handleFinish", data);

    setAddress(data.email_address);
    setLoadingFg(true);

    const response = await authService.ForgotPassword({
      email: data.email_address,
    });

    console.log(response);

    setTimeout(() => {
      setLoadingFg(false);
      setIsReseted(true);
    }, 2000);
  }, []);

  return (
    <div className="reset-password">
      <Form
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        className="reset-form"
        form={form}
        initialValues={{}}>
        <div className="resetpwd-content">
          <div className="logo-wrapper">
            <Link to="/" className="logo-link">
              <img className="logo-dark logo-img logo-img-lg" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="title-wrapper">
            <h5 className="title">Forgot password?</h5>
            <div className="forgot-subtitle">Don&apos;t worry, happens to best of us</div>
          </div>
          {!isReseted ? (
            <>
              <Form.Item
                name="email_address"
                validateTrigger="onSubmit"
                className="email-item"
                rules={[{ required: true, message: "Please input valid email address!", type: "email" }]}>
                <Input id="email_address" placeholder="yourname@email.com" />
              </Form.Item>
              <div className="reset-button-wrapper">
                <Button disabled={loadingFg} className="reset-button" type="primary" htmlType="submit">
                  {loadingFg ? (
                    <div>
                      <span className="align-items-center">Processing</span>
                    </div>
                  ) : (
                    "Send reset link"
                  )}
                </Button>
              </div>
              <Link to="login">
                <span className="">HAVE AN ACCOUNT? LOGIN</span>
              </Link>
            </>
          ) : (
            <>
              <p className="">
                Done. Check your email
                <span className="">{address}</span>
                and follow the instructions
              </p>

              <Button
                onClick={() => form.submit()}
                href="/signin"
                type="primary"
                className=""
                variant="brand-02"
                block>
                Go back to Login
              </Button>
            </>
          )}
          <div className="reset-footer">
            <div>Have account related problems or questions? Contact us at</div>
            <div className="text-blue">support@tndr.com.au</div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPWD;
