import tendersService from "../../services/tenders.service";
import authService from "../../services/auth.service";

import types from "../actionType";

export const fetchCompanyWatchList = (companyId) => (dispatch, getState) => {
  if (getState().usersData.isFetchingList) {
    return Promise.reject();
  }

  dispatch({
    type: types.COMPANY_WATCH_LIST_REQUEST,
  });

  return tendersService
    .GetCompanyWatchList(companyId)
    .then((data) => {
      dispatch({
        type: types.COMPANY_WATCH_LIST_SUCCESS,
        payload: { data },
      });
    })
    .catch((error) => {
      dispatch({
        type: types.COMPANY_WATCH_LIST_FAILURE,
        payload: { error },
      });
      throw error;
    });
};

export const signUp = (userData) => (dispatch, getState) => {
  if (getState().usersData.isCreating) {
    return Promise.reject();
  }

  dispatch({
    type: types.SIGN_UP_REQUEST,
  });

  return authService
    .SignUp({
      ...userData,
      industryText: userData.industry,
    })
    .then((data) => {
      dispatch({
        type: types.SIGN_UP_SUCCESS,
        payload: { data },
      });
    })
    .catch((error) => {
      dispatch({
        type: types.SIGN_UP_FAILURE,
        payload: { error: error.data.message },
      });
      throw error;
    });
};

export const updatePassword = (updateData) => (dispatch, getState) => {
  if (getState().usersData.isUpdating) {
    return Promise.reject();
  }
  const { token } = updateData;

  if (!token) {
    return Promise.reject();
  }

  dispatch({
    type: types.UPDATE_PASSWORD_REQUEST,
  });

  const dataUpdate = {
    userid: updateData.userId,
    password: updateData.password,
  };
  return authService
    .UpdatePassword(dataUpdate, token)
    .then((data) => {
      dispatch({
        type: types.UPDATE_PASSWORD_SUCCESS,
        payload: { data },
      });
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_PASSWORD_FAIL,
        payload: { error: error.data.message },
      });
      throw error;
    });
};

export const resetPassword = (updateData) => (dispatch, getState) => {
  if (getState().usersData.isUpdating) {
    return Promise.reject();
  }
  const { token, resetPasswordToken } = updateData;

  if (!token || !resetPasswordToken) {
    return Promise.reject();
  }

  dispatch({
    type: types.UPDATE_PASSWORD_REQUEST,
  });

  const dataUpdate = {
    userid: updateData.userId,
    password: updateData.password,
  };
  return authService
    .ResetPassword(dataUpdate, token, resetPasswordToken)
    .then((data) => {
      dispatch({
        type: types.UPDATE_PASSWORD_SUCCESS,
        payload: { data },
      });
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_PASSWORD_FAIL,
        payload: { error: error.data.message },
      });
      throw error;
    });
};

export const signIn = (userData) => (dispatch, getState) => {
  if (getState().usersData.isCreating) {
    return Promise.reject();
  }

  dispatch({
    type: types.SIGN_IN_REQUEST,
  });

  return authService
    .SignIn(userData)
    .then((data) => {
      dispatch({
        type: types.SIGN_IN_SUCCESS,
        payload: { data },
      });
    })
    .catch((error) => {
      dispatch({
        type: types.SIGN_IN_FAILURE,
        payload: { error: error.data.message },
      });
      throw error;
    });
};

export const signOut = () => (dispatch, getState) => {
  if (getState().usersData.isCreating) {
    return Promise.reject();
  }

  dispatch({
    type: types.LOG_OUT_REQUEST,
  });

  return dispatch({
    type: types.LOG_OUT_SUCCESS,
  });
};

export const updateUser = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_USER,
    payload: { data },
  });
};

export const updateUserTrades = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_USER_TRADES,
    payload: { ...data },
  });
};

export const clearErrors = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_ERRORS,
  });
};

export const updateOffice = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_COMPANY_OFFICE,
    payload: { data },
  });
};
