import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setFilters } from "../../../../redux/slices/tendersSearch";

import CustomSearchInput from "../../../../components/Elements/CustomSearchInput";
import Filters from "./Filters";

import "./style.scss";

function AllTendersHeader() {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.tendersSearch.filters);
  const [searchString, setSearchString] = useState("");

  const handleSearch = (value) => {
    setSearchString(value);
  };

  const handleSearchButton = () => {
    dispatch(setFilters({ ...filters, searchString }));
  };

  const clearSearch = () => {
    dispatch(setFilters({ ...filters, searchString: "" }));
  };

  const handleInputEnterKey = (e) => {
    if (e.key === "Enter") dispatch(setFilters({ ...filters, searchString }));
  };

  return (
    <div className="header-wrapper">
      <div className="search-bar">
        <CustomSearchInput
          placeholder="Project keywords"
          searchString={searchString}
          clearSearch={clearSearch}
          onChange={handleSearch}
          onKeyDown={handleInputEnterKey}
        />
        <button onClick={handleSearchButton} className="search-bar__button">
          Search
        </button>
      </div>
      <Filters />
    </div>
  );
}

export default AllTendersHeader;
