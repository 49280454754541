import React from "react";
import { Link } from "react-router-dom";

function SignupFooter() {
  return (
    <>
      <div className="form-note-s2 pt-4">
        Already have an account?
        <Link to="/login" className="ml-1">
          <strong>Sign-in instead</strong>
        </Link>
      </div>
    </>
  );
}

export default SignupFooter;
