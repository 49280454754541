import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";

const NewProjectDescription = () => {
  const { description } = useSelector((state) => ({ description: state.newProject.data.description }));
  const dispatch = useDispatch();
  const onChange = (e) => dispatch(newProjectSlice.actions.setDescription(e.target.value));
  return (
    <div className="form-group">
      <label className="form-label" htmlFor="default-01">
        Description of Works
      </label>
      <div className="form-control-wrap">
        <textarea
          value={description}
          onChange={onChange}
          type="text"
          className="form-control"
          placeholder="Description of Works"
        />
      </div>
    </div>
  );
};

export default NewProjectDescription;
