import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu } from "antd";

import { setStatus } from "../../../../../redux/slices/tendersSearch";

const Status = () => {
  const dispatch = useDispatch();

  const status = useSelector((state) => state.tendersSearch.status);

  const handleMenuChange = ({ key }) => {
    dispatch(setStatus(key));
  };

  const Overlay = () => {
    return (
      <Menu onClick={handleMenuChange}>
        <Menu.Item key="open">Open</Menu.Item>
        <Menu.Item key="awarded">Awarded</Menu.Item>
        <Menu.Item key="closed">Closed</Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown overlay={Overlay} placement="bottomCenter" trigger={["click"]} arrow>
      <button className="filter">{status ? `Status: ${status}` : "Status"}</button>
    </Dropdown>
  );
};

export default Status;
