import React from "react";
import { Link } from "react-router-dom";
import logo from "../../utils/images/logo_signin.png";

function SignupHeader() {
  return (
    <>
      <div className="brand-logo pb-5">
        <Link to="/" className="logo-link">
          <img className="logo-dark logo-img logo-img-lg" src={logo} alt="logo" />
        </Link>
      </div>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">Register</h5>
          <div className="nk-block-des">
            <p>Let`s get started.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupHeader;
