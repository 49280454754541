import React, { useCallback, useState, useMemo } from "react";
import { Input, Select } from "antd";
import countries from "./source";

const ValidatorInput = ({ onChange, value = { phone: "" }, selectProps, ...props }) => {
  const defaultCountry = useMemo(() => {
    return countries.find((c) => c.short === "AU");
  }, []);

  const [country, setCountry] = useState(defaultCountry);

  const triggerChange = useCallback(
    (phone, country) => {
      const result = { phone, country };
      onChange(result);
    },
    [onChange]
  );

  const handleCountryChange = useCallback(
    (value) => {
      const c = countries.find((c) => c.short === value);
      if (!c) {
        throw new Error(`Country not found: ${value}`);
      }

      setCountry(c);
      triggerChange(value.phone, c);
    },
    [setCountry, triggerChange]
  );

  const handlePhoneChange = useCallback(
    ({ target }) => {
      const currentValue = target.value;
      if (/^[\d()+]+$/.test(currentValue) || !currentValue) {
        triggerChange(currentValue, country);
      }
    },
    [country, triggerChange]
  );

  return (
    <Input
      addonBefore={
        <Select
          bordered={false}
          dropdownMatchSelectWidth={false}
          {...selectProps}
          optionLabelProp={"label"}
          value={country && country.short}
          onChange={handleCountryChange}>
          {countries.map((item) => {
            const params = {
              value: item.short,
              label: `${item.emoji} ${item.short}`,
            };
            return (
              <Select.Option key={item.short} {...props.optionProps} {...params}>
                {item.emoji} {item.en}
              </Select.Option>
            );
          })}
        </Select>
      }
      {...props}
      value={value.phone}
      autoComplete="new-password"
      onChange={handlePhoneChange}
    />
  );
};

export default ValidatorInput;
