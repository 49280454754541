import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import newProject from "../newProject/slice";
import tendersSearch from "../slices/tendersSearch";
import usersData from "./users";
import tendersData from "./tenders";
import directoryData from "./directory";

export default (history) =>
  combineReducers({
    usersData,
    tendersData,
    directoryData,
    newProject,
    tendersSearch,
    router: connectRouter(history),
  });
