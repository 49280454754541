import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { AutoComplete, Input, Form } from "antd";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

import "./style.scss";

const noop = () => {};

const LocationSearchInput = ({ setCompanyLocation, companyAddress, addressErr, clearLocation = noop }) => {
  const [address, setAddress] = useState(companyAddress);
  const {
    ready,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "au" },
    },
    debounce: 300,
    defaultValue: address,
  });

  const getOptions = () => {
    return data.map((suggestion) => {
      const {
        structured_formatting: { main_text: mainText, secondary_text: secondaryText },
        description,
      } = suggestion;
      return {
        value: description,
        label: (
          <div className={"option"}>
            <strong>{mainText}</strong> <small>{secondaryText}</small>
          </div>
        ),
        address: description,
        place_id: suggestion.place_id,
      };
    });
  };

  const handleChange = useCallback(
    (value = "") => {
      setAddress(value);
      setValue(value);
      if (!value) clearLocation();
    },
    [clearLocation, setValue]
  );

  const handleSelect = useCallback(
    async (value, option = {}) => {
      const address = option.address;
      getGeocode({
        placeId: option.place_id,
      }).then(async (res) => {
        const coordinates = await getLatLng(res[0]);

        const { lat: latitude, lng: longitude } = coordinates;

        const { address_components } = res[0];
        const post_code = address_components.find((elem) => elem.types[0] === "postal_code")?.long_name;
        const suburb = address_components.find(
          (elem) => elem.types[0] === "locality" && elem.types[1] === "political"
        )?.long_name;
        const street_number = address_components.find((elem) => elem.types[0] === "street_number")?.long_name;
        const street_name = address_components.find((elem) => elem.types[0] === "route")?.long_name;
        const state = address_components.find(
          (elem) => elem.types[0] === "administrative_area_level_1"
        )?.short_name;
        setCompanyLocation(address, {
          street: street_number && street_name ? `${street_number} ${street_name}` : null,
          suburb,
          post_code,
          state,
          latitude,
          longitude,
        });
      });
      setAddress(address);
      clearSuggestions();
    },
    [clearSuggestions, setCompanyLocation]
  );

  return (
    <AutoComplete
      disabled={!ready}
      className="location-search-input"
      options={getOptions()}
      value={address}
      onSelect={handleSelect}
      onChange={handleChange}>
      <Input placeholder="Search Places .." size="middle" />
    </AutoComplete>
  );
};

LocationSearchInput.propTypes = {
  setCompanyLocation: PropTypes.func,
  companyAddress: PropTypes.string,
  addressErr: PropTypes.bool,
};

LocationSearchInput.defaultProps = {
  setCompanyLocation: () => {},
  companyAddress: "",
  addressErr: false,
};

export default LocationSearchInput;
