import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import "./style.scss";

const { Option } = Select;

function CustomSelect({ mode, data, onChange, width, onDragMouseDown }) {
  return (
    <nav onMouseDown={onDragMouseDown} className="custom-select-wrapper">
      <Select style={{ width }} onChange={onChange} mode={mode}>
        {data.map((dataItem) => (
          <Option key={dataItem.key} className="custom-select-option" value={dataItem.key}>
            {dataItem.value}
          </Option>
        ))}
      </Select>
    </nav>
  );
}

CustomSelect.propTypes = {
  mode: PropTypes.string,
  data: PropTypes.array,
  width: PropTypes.string,
  onChange: PropTypes.func,
  onDragMouseDown: PropTypes.func.isRequired,
};

CustomSelect.defaultProps = {
  mode: "single",
  data: [],
  width: "120px",
  onChange: () => {},
};

export default CustomSelect;
