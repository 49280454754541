import React, { useState } from "react";
import { Form, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setFilters } from "../../redux/slices/tendersSearch";

import CustomModal from "../CustomModal";

import "./style.scss";

const distanceOptions = [
  { label: "Office", value: "Office" },
  { label: "National", value: "National" },
  { label: "State", value: "State" },
];

const createdDateOptions = [
  { label: "Today", value: "Today" },
  { label: "1 Week", value: "1 Week" },
  { label: "2 Weeks", value: "2 Week" },
  { label: "3 Weeks", value: "3 Week" },
  { label: "1 Month", value: "1 Month" },
  { label: "6 Months", value: "6 Month" },
  { label: "12 Months", value: "12 Month" },
  { label: "All Time", value: "All Time" },
];

const quotesDateOptions = [
  { label: "Today", value: "Today" },
  { label: "1 Week", value: "1 Week" },
  { label: "2 Weeks", value: "2 Week" },
  { label: "3 Weeks", value: "3 Week" },
  { label: "1 Month", value: "1 Month" },
  { label: "6 Months", value: "6 Month" },
  { label: "12 Months", value: "12 Month" },
];

const FiltersModal = ({ visible, changeVisible }) => {
  const onCancel = () => changeVisible(false);
  const dispatch = useDispatch();
  const [tradesFilter, setTradesFilter] = useState([]);

  const { industryTrades, filters } = useSelector((state) => ({
    industryTrades: state.usersData.industryTrades,
    filters: state.tendersSearch.filters,
  }));

  const addFilter = (key, value) => {
    if (key && value) dispatch(setFilters({ ...filters, [key]: value }));
  };

  const addTradeFilter = (_, element) => {
    const newData = [...tradesFilter, element.key];
    setTradesFilter(newData);
    dispatch(setFilters({ ...filters, trades: newData }));
  };

  const removeTradeFilter = (_, element) => {
    const newData = tradesFilter.filter((trade) => trade !== element.key);
    setTradesFilter(newData);
    dispatch(setFilters({ ...filters, trades: newData }));
  };

  return (
    <CustomModal
      width={400}
      title="Filters"
      visible={visible}
      onCancel={onCancel}
      onOk={onCancel}
      hideCancelButton>
      <div className="filters-modal">
        <Form layout="vertical">
          <h6>Packages</h6>
          <Form.Item name="trades">
            <Select
              onSelect={addTradeFilter}
              onDeselect={removeTradeFilter}
              placeholder="Trades"
              className="filters-modal-select"
              defaultValue={
                filters.trades &&
                filters.trades.map((trade) => industryTrades.find((itrade) => itrade.key === trade).value)
              }
              maxTagCount={5}
              mode="multiple"
              options={industryTrades}
            />
          </Form.Item>
          <h6>Distance</h6>
          <Form.Item name="by" label="By">
            <Select
              onSelect={addFilter}
              placeholder="Distance"
              className="filters-modal-select"
              options={distanceOptions}
            />
          </Form.Item>
          <h6>Added Within</h6>
          <Form.Item name="added_within">
            <Select
              onSelect={(value) => addFilter("added_within", value)}
              className="filters-modal-select"
              placeholder="Added Within"
              defaultValue={filters.added_within}
              options={createdDateOptions}
            />
          </Form.Item>
          <h6>Quotes Due Within</h6>
          <Form.Item name="quotes_due">
            <Select
              placeholder="Quotes Due Within"
              onSelect={(value) => addFilter("quotes_due", value)}
              className="filters-modal-select"
              defaultValue={filters.quotes_due}
              options={quotesDateOptions}
            />
          </Form.Item>
        </Form>
      </div>
    </CustomModal>
  );
};

export default FiltersModal;
