import directoryService from "../../services/directory.service";

import types from "../actionType";

export const fetchDirectoryData =
  (page, limit, filters = {}, userType) =>
  (dispatch, getState) => {
    console.log("filter: ", filters); // fixme
    console.log("page: ", page); // fixme
    console.log("authuser: ", userType); // fixme

    if (getState().directoryData.isFetchingList) {
      return Promise.reject();
    }
    const companyOffice = getState().usersData.companyOffice;

    const pageNumber = page ? parseInt(page, 10) : getState().directoryData.page;
    const pageLimit = limit ? parseInt(limit, 10) : getState().directoryData.limit;

    dispatch({
      type: types.DIRECTORIES_FETCH_REQUEST,
    });

    return directoryService
      .GetAll(pageNumber, pageLimit, filters, userType)
      .then((data) => {
        dispatch({
          type: types.DIRECTORIES_FETCH_SUCCESS,
          payload: {
            data,
            pageNumber,
            pageLimit,
            companyOffice,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DIRECTORIES_FETCH_FAILURE,
          payload: { error },
        });
        throw error;
      });
  };

export const setFavorite = (id, value, userType) => (dispatch, getState) => {
  console.log("id: ", id); // fixme
  console.log("value: ", value); // fixme

  if (getState().directoryData.isUpdatingFlag) {
    return Promise.reject();
  }

  dispatch({
    type: types.DIRECTORIES_SET_FAVORITE_REQUEST,
  });

  return directoryService
    .SetFavorite(id, value, userType)
    .then(() => {
      dispatch({
        type: types.DIRECTORIES_SET_FAVORITE_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.DIRECTORIES_SET_FAVORITE_FAILURE,
        payload: { error },
      });
      throw error;
    });
};
