import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select, Table } from "antd";

import "./style.scss";
import { useSelector } from "react-redux";

const columns = [
  {
    title: "Project Name",
    dataIndex: "prj_name",
    key: "prj_name",
    render: (prj_name) => (
      <>
        {prj_name.map((element, index) => (
          <div key={index}>{element}</div>
        ))}
      </>
    ),
  },
  {
    title: "Category",
    key: "category",
    dataIndex: "category",
  },
  {
    title: "Tender Due",
    key: "quotes_due",
    dataIndex: "quotes_due",
    width: "10%",
    render: (quotes) => (
      <span className="table_quotes">
        {quotes.map((item) => (
          <span key={item.date_tender_close} className="quote_item">
            <span>{item.date_tender_close}</span>
          </span>
        ))}
      </span>
    ),
  },
  {
    title: "Budget",
    dataIndex: "max_budget",
    key: "max_budget",
    width: "10%",
    render: (budgets) => <span className="table_max_budget">{budgets}</span>,
  },
  {
    title: "Distance",
    dataIndex: "distance",
    key: "distance",
  },
  {
    title: "Created",
    key: "added",
    dataIndex: "added",
  },
  {
    title: "Watchlist",
    key: "watchlist",
    dataIndex: "watchlist",
    width: "10%",
    render: (watch_list) => (
      <>
        <Select
          className="table_watchlist"
          style={{ width: 150 }}
          value={watch_list.value}
          onChange={watch_list.onChange}
          placeholder="Uncategorised..."
          onClick={(e) => {
            e.stopPropagation();
          }}>
          {watch_list.data.map((selectItem) => (
            <Select.Option key={selectItem.key} value={selectItem.key} disabled={selectItem.key === 0}>
              {selectItem.value}
            </Select.Option>
          ))}
        </Select>
      </>
    ),
  },
  {
    title: "Notes",
    key: "notes",
    dataIndex: "notes",
    width: "3%",
  },
];

function CustomTable({ locale, data, onRowClick, watchList, SelectWatchList = () => {} }) {
  const { companyInfo } = useSelector((state) => ({
    companyInfo: state.usersData.authUser.data.companyInfo,
  }));

  const [updateFg, setUpdateFg] = useState(false);

  const onChangeEvent = (idProject, idCompany) => (idWatch) => {
    setUpdateFg(true);
    SelectWatchList(idProject, idWatch, idCompany)
      .then(() => {
        setUpdateFg(false);
      })
      .catch(() => {
        setUpdateFg(false);
      });
  };
  const onClick = (e) => {
    e.stopPropagation();
  };

  // console.log("Render custom table", data, watchList);

  const tableData = data.map((dataItem, index) => ({
    key: `table-${index}`,
    prj_name: dataItem.prj_name,
    max_budget: dataItem.max_budget,
    distance: dataItem.distance,
    category: dataItem.category,
    quotes_due: dataItem.quoteDue,
    added: dataItem.added,
    watchlist: {
      value: dataItem.watchlist, // selected
      data: [...watchList],
      onChange: updateFg ? () => {} : onChangeEvent(dataItem.prj_name[1], companyInfo.id),
      onClick,
    },
    notes: dataItem.notes,
  }));

  const rowSelection = {
    onChange: () => {},
    getCheckboxProps: () => ({}),
  };

  return (
    <div className="custom-table-wrapper">
      <Table
        columns={columns}
        locale={locale}
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={tableData}
        scroll={{ x: 700 }}
        onRow={(record, index) => ({
          onClick: updateFg
            ? () => {}
            : (e) => {
                e.stopPropagation();
                onRowClick(index);
              },
        })}
      />
    </div>
  );
}

CustomTable.propTypes = {
  data: PropTypes.array,
  watchList: PropTypes.array,
  onRowClick: PropTypes.func.isRequired,
  SelectWatchList: PropTypes.func.isRequired,
  locale: PropTypes.object,
};

CustomTable.defaultProps = {
  data: [],
  watchList: [],
  locale: {},
};

export default CustomTable;
