import React, { useState, useEffect } from "react";

import "./styles.scss";
import { Button, Input, Form, Upload, InputNumber, Select, Checkbox, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import TendersService from "../../../services/tenders.service";
import QuotesService from "../../../services/quotes.service";
import { getAuthHeader } from "../../../services/http.service";

function QuotesBody({ idProject }) {
  const [loading, setLoading] = useState(false);
  const [tenderCompanies, setTenderCompanies] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const handleFinish = (data) => {
    if (selectedFileList.length < selectedPackages.length) {
      message.error("Attach a file to each package, please");
    } else {
      QuotesService.CreateNewQuote({ ...data, fileIds: selectedFileList })
        .then(() => {
          message.success("Quote has been successfully created");
          setRedirectToDashboard(true);
        })
        .catch(() => message.error(message.error("Error when create quote")));
    }
  };

  const uploadButton = (
    <div className="drag-and-drop">
      {loading ? (
        <LoadingOutlined />
      ) : (
        <h5 className="drag-and-drop__text">Drag & drop your quote file here, or browse files</h5>
      )}
    </div>
  );
  const { projObj, industryTrades } = useSelector((state) => ({
    projObj: state.tendersData.tenders[Number(idProject)],
    industryTrades: state.usersData.industryTrades,
  }));

  useEffect(() => {
    if (projObj) {
      TendersService.GetTenderCompanies({ tender_title: projObj.project_title }).then((data) => {
        setTenderCompanies(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!projObj) return <Redirect to="/" />;
  const handleSubmit = () => {
    form.submit();
  };

  const handleChangeUpload = (info) => {
    if (info.file.status === "done") {
      setSelectedFile(info.file);
      setSelectedFileList([...selectedFileList, info.file.response.id]);
      message.success(`${info.file.name} file uploaded successfully`);
    }
  };

  const handleChangeSelect = (value) => {
    setSelectedPackages(value);
  };

  const ContractorRow = ({ name, company_street_post_code, value }) => (
    <div className="d-flex justify-content-between w-100 contractor__row">
      <div className="d-flex">
        <Checkbox disabled={projObj.id_tender === value} value={value} className="contractor-checkbox" />
        <div className="ml-2">{name}</div>
      </div>
      <div>
        <span className="price-text">{company_street_post_code}</span>
      </div>
    </div>
  );

  if (redirectToDashboard) return <Redirect to="/dashboard" />;

  return (
    <div className="quote-body">
      <div className="description">Upload quote files and complete the details below to submit the quote</div>
      <Form
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{ tenderList: [projObj.id_tender] }}
        form={form}>
        <div className="project-details">
          <h4 className="mb-4">Project details</h4>
          <div className="project-details__project-name mb-3">
            <h3 className="mb-0 project-name__project-header">{projObj.project_title}</h3>
            <div className="project-name__project-address">{projObj.location}</div>
          </div>
          <div className="project-details__contractor-package d-flex justify-content-between w-100 mt-4">
            <div className="contractor">
              <div className="label mb-1">Which head contractor would you like to send this quote to?</div>
              <div>
                <Form.Item name="tenderList">
                  <Checkbox.Group className="checkboxes-block flex-column">
                    {tenderCompanies.map((company, index) => (
                      <ContractorRow
                        name={company.company_name}
                        value={company.id_tender}
                        key={index}
                        company_street_post_code={company.company_street_post_code}
                      />
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </div>
            <div className="package">
              <div className="label mb-1">Package</div>
              <Form.Item name="packages">
                <Select
                  mode="multiple"
                  placeholder="Please select"
                  showArrow
                  className="package__select"
                  onChange={handleChangeSelect}
                  options={industryTrades
                    .filter(
                      (trade) =>
                        projObj.project_trades.length === 0 ||
                        projObj.project_trades.find((proj_trade) => proj_trade.id_trade === trade.key)
                    )
                    .map((trade) => ({ value: trade.key, label: trade.value }))}
                  labelInValue="tradeCategory"
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="your-quote mt-4">
          <h4 className="mb-3">Your quote</h4>
          <div className="your-quote__quote-amount mb-4">
            <Form.Item
              label={<div className="label">Quote amount (excluding G.S.T.)</div>}
              name="quote_amount"
              rules={[{ required: true, message: "please enter an amount" }]}>
              <InputNumber
                className="quote_amount__input w-100"
                defaultValue={"1.0"}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </div>
          {selectedPackages.map((tenderPackage) => (
            <div key={tenderPackage.value} className="your-quote__quote-file mb-4">
              <div className="drag-and-drop-wrapper">
                <div className="label-required">Quote file ({tenderPackage.label})</div>
                <Upload
                  accept={".pdf, .xls, .ppt, .doc, .docx, .txt, .zip, .rar "}
                  action={`${process.env.REACT_APP_API}/files/quote_files?id_trade=${tenderPackage.value}`}
                  listType="text"
                  showUploadList={true}
                  className="w-100"
                  onChange={handleChangeUpload}
                  headers={getAuthHeader()}
                  name="quote">
                  {uploadButton}
                </Upload>
              </div>
            </div>
          ))}
          <div className="quote-message">
            <Form.Item label={<div className="label">Quote message (optional)</div>} name="quote_message">
              <TextArea
                className="quote-message__textarea"
                placeholder="Add any information to help clarify your your quote, inclusions and exclusions, etc."
              />
            </Form.Item>
          </div>
        </div>
        <div className="quotes-body__buttons w-100 d-flex justify-content-between">
          <Link to="/dashboard" className="back-link">
            <Button className="quote-button">Cancel</Button>
          </Link>
          <Button type="primary" className="quote-button submit-button" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default QuotesBody;
