import React from "react";

import "../style.scss";
import { useParams } from "react-router-dom";
import QuotesHeader from "./QuotesHeader";
import QuotesBody from "./QuotesBody";

function Quotes() {
  const { id } = useParams();
  return (
    <div className="dashboard-content">
      <QuotesHeader />
      <QuotesBody idProject={id} />
    </div>
  );
}

export default Quotes;
