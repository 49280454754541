import React from "react";
import { Link } from "react-router-dom";

function SigninFooter() {
  return (
    <>
      <div className="footer-wrapper">
        <div>
          <span>Forgot password? </span>
          <span>
            <Link to="/forgotpassword">Reset</Link>
          </span>
        </div>
        <span>You don`t have any account?</span>
        <span>
          <Link to="/signup" className="ml-1">
            Sign-up instead
          </Link>
        </span>
      </div>
    </>
  );
}

export default SigninFooter;
