import React from "react";
import VideoPlayer from "../../../components/VideoPlayer";

import "./style.scss";

const mainDivCN = "right-side";

const RightSide = () => {
  return (
    <div className={mainDivCN}>
      <VideoPlayer />
    </div>
  );
};

export default React.memo(RightSide);
