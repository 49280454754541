import axios from "axios";
import { message } from "antd";
import store from "../redux/store";
import { signOut } from "../redux/action/users";

import storage from "./storage.service";

const USER_TOKEN_KEY = "access_token";
const baseURL = process.env.REACT_APP_API;

const defaultResponse = (response) => {
  return response;
};

const errorInterceptor = (error) => {
  if (error.response.status === 401) {
    message.info("Your session is outdated");
    store.dispatch(signOut());
  }
  return Promise.reject(error);
};

const http = axios.create({ baseURL: `${baseURL}/` });
http.interceptors.response.use(defaultResponse, errorInterceptor);

export const getAuthHeader = () => {
  const auth = storage.getItem(USER_TOKEN_KEY);
  const authHeader = { "x-access-token": auth || null };
  return authHeader;
};

function get(url, headers = {}, params = {}) {
  const accessToken = storage.getItem(USER_TOKEN_KEY);
  let reqHeaders = headers;
  if (accessToken) {
    const authHeader = { "x-access-token": accessToken };
    reqHeaders = { ...authHeader, ...headers };
  }
  return http.get(url, {
    ...params,
    headers: reqHeaders,
  });
}

function post(url, data, headers = {}, params = {}) {
  const accessToken = storage.getItem(USER_TOKEN_KEY);
  let reqHeaders = headers;
  if (accessToken) {
    const authHeader = { "x-access-token": accessToken };
    reqHeaders = { ...authHeader, ...headers };
  }
  return http.post(url, data, {
    ...params,
    headers: reqHeaders,
  });
}

function put(url, data, headers = {}) {
  const accessToken = storage.getItem(USER_TOKEN_KEY);
  let reqHeaders = headers;
  if (accessToken) {
    const authHeader = { "x-access-token": accessToken };
    reqHeaders = { ...authHeader, ...headers };
  }
  return http.put(url, data, { headers: reqHeaders });
}

function remove(url, data, headers = {}) {
  const accessToken = storage.getItem(USER_TOKEN_KEY);
  let reqHeaders = headers;
  if (accessToken) {
    const authHeader = { "x-access-token": accessToken };
    reqHeaders = { ...authHeader, ...headers };
  }
  return http.delete(url, {
    headers: reqHeaders,
    data,
  });
}

export default {
  http,
  get,
  post,
  put,
  remove,
};
