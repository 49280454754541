import React from "react";

const DirectoryHeader = () => (
  <div className="quotes-bar">
    <div className="container">
      <ul className="menu-items">
        <li>
          <a href="/quotes" className="active">
            Quotes
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default DirectoryHeader;
