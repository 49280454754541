import httpService from "./http.service";
import storage from "./storage.service";

const authUrl = "/users";

const SignUp = (userData) =>
  httpService
    .post(`${authUrl}/signup`, userData)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const SignIn = (userData) =>
  httpService
    .post(`${authUrl}/signin`, userData)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const UpdatePassword = (updateData, token) => {
  storage.setItem("access_token", token);
  return httpService
    .put(`${authUrl}/updatepass`, updateData)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));
};

const ResetPassword = (updateData, token, resetPasswordToken) => {
  storage.setItem("access_token", token);
  return httpService
    .put(`${authUrl}/resetpassword`, updateData, { "x-resetpassword-token": resetPasswordToken })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));
};

const ForgotPassword = (forgotPasswordData) => {
  return httpService
    .post(`${authUrl}/forgotpassword`, forgotPasswordData)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));
};

const Logout = () =>
  httpService
    .put(`${authUrl}/logout`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

export default {
  SignUp,
  SignIn,
  Logout,
  UpdatePassword,
  ForgotPassword,
  ResetPassword,
};
