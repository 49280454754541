import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CustomMultiSelect from "../Elements/CustomMultiSelect";
import ABNInput from "../Elements/ABNInput";
import LocationSearchInput from "../Elements/PlaceAutoComplete";

const Subbie = ({
  register,
  errors,
  abnerr,
  setAbnErr,
  selectAgree,
  locationState,
  setAgree,
  filterIndustryTrades,
  onChangeState,
  onChangeIndustry,
  addressErr,
  setCompanyLocation,
  companyAddress,
}) => (
  <>
    <div className="form-group builder-form row">
      <div className="col-lg-12">
        <label className="form-label" htmlFor="subbie_company_name">
          Company name
        </label>
        <input
          type="text"
          className="form-control form-control-lg"
          id="subbie_company_name"
          name="company_name"
          placeholder="Company name"
          ref={register({ required: "Company name is required" })}
        />
        <div className="error-msg">{errors.company_name && errors.company_name.message}</div>
      </div>
    </div>
    <div className="form-group row">
      <div className="col-lg-12 col-sm-12">
        <label className="primary_trade">My Primary trades are</label>
        <CustomMultiSelect data={filterIndustryTrades} onChange={onChangeIndustry} />
      </div>
    </div>
    <div className="form-group row builder-form">
      <div className="col-lg-6">
        <ABNInput errors={errors} register={register} abnerr={abnerr} setAbnErr={setAbnErr} />
      </div>
      <div className="col-lg-6">
        <label className="form-label" htmlFor="select_state">
          Select state
        </label>
        <select
          className="form-control  form-control-lg"
          name="id_state"
          id="select_state"
          onChange={onChangeState}>
          {locationState.map((item) => (
            <option key={item.key} value={item.key}>
              {item.value}
            </option>
          ))}
        </select>
      </div>
    </div>
    <div className="form-group mt-2 builder-form">
      <label className="form-label" htmlFor="location-search-input">
        Company Address
      </label>
      <LocationSearchInput
        addressErr={addressErr}
        setCompanyLocation={setCompanyLocation}
        companyAddress={companyAddress}
      />
    </div>
    <div className="form-group row mt-2">
      <div className="col-lg-12">
        <div className="custom-control custom-control-xs custom-checkbox">
          <input type="checkbox" className="custom-control-input" id="checkbox" onClick={setAgree} />
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          <label className="custom-control-label" htmlFor="checkbox">
            I agree to TNDR.™
            <a tabIndex="-1" href="terms-policy.html" target="_blank" className="ml-1">
              Privacy Policy&nbsp;
            </a>
            &amp;
            <a tabIndex="-1" href="terms-policy.html" target="_blank">
              &nbsp;Terms.
            </a>
          </label>
        </div>
        {selectAgree && <div className="error-msg">Please agree with Policy and terms</div>}
      </div>
    </div>
  </>
);

Subbie.propTypes = {
  register: PropTypes.func.isRequired,
  setAgree: PropTypes.func.isRequired,
  selectAgree: PropTypes.bool.isRequired,
  onChangeState: PropTypes.func.isRequired,
  onChangeIndustry: PropTypes.func.isRequired,
  errors: PropTypes.object,
  locationState: PropTypes.array.isRequired,
  filterIndustryTrades: PropTypes.array.isRequired,
  abnerr: PropTypes.bool.isRequired,
  setAbnErr: PropTypes.func.isRequired,
  addressErr: PropTypes.bool.isRequired,
  setCompanyLocation: PropTypes.func.isRequired,
  companyAddress: PropTypes.string.isRequired,
};

Subbie.defaultProps = {
  errors: {},
};

const mapStateToProps = (store) => ({
  countries: store.usersData.countries,
  locationState: store.usersData.locationState,
});

export default connect(mapStateToProps)(Subbie);
