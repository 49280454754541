import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Upload } from "antd";
import { getAuthHeader } from "../../../../../services/http.service";
import "./style.scss";

const NewProjectTable = ({ files, setFiles }) => {
  const { industryTrades, trades } = useSelector((state) => ({
    industryTrades: state.usersData.industryTrades,
    trades: state.newProject.data.trades,
  }));
  const [selectedFile, setSelectedFile] = useState({});

  const handleChangeUpload = (info, key) => {
    if (info.file.status === "done") {
      setSelectedFile({ ...selectedFile, [key]: info.file });
      setFiles([...files, info.file.response.id]);
    }
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Category</th>
          <th scope="col">Title</th>
          <th scope="col">Document</th>
        </tr>
      </thead>
      <tbody>
        {trades.map((el) => {
          const trade = industryTrades.find((item) => item.key === el);
          if (!trade) return null;
          return (
            <tr key={trade.value}>
              <td>{trade.tradeCategory}</td>
              <td>{trade.value}</td>
              <td className="w-100 upload-project-file">
                <Upload
                  accept={".pdf, .xls, .ppt, .doc, .docx, .txt, .zip, .rar "}
                  action={`${process.env.REACT_APP_API}/files/tender_files?id_trade=${trade.key}`}
                  maxCount={1}
                  listType="text"
                  className="w-100"
                  onChange={(info) => handleChangeUpload(info, trade.key)}
                  showUploadList={true}
                  headers={getAuthHeader()}
                  name="tenderFile">
                  <div className="form-control-wrap w-100">
                    <div className="custom-file w-100">
                      <div className="custom-file-label w-100">
                        {selectedFile[trade.key] && selectedFile[trade.key].name
                          ? selectedFile[trade.key].name
                          : "Choose file"}
                      </div>
                    </div>
                  </div>
                </Upload>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default NewProjectTable;
