import React from "react";
import { Divider } from "antd";
import PropTypes from "prop-types";

import "./style.scss";

function Trades({ builders, industryTrades }) {
  return (
    <div className="project-trades">
      <Divider className="sub-menu-divider">
        <span className="description">Trades</span>
      </Divider>
      {(!builders || !builders.length) && <p>Builders have yet to create trade packages.</p>}
      {builders &&
        builders.length &&
        builders.map((builder) =>
          Object.keys(builder.packagesWithFileNames).map((key) => {
            const trade = industryTrades.find((item) => item.key === Number(key));
            return (
              <div className="trade-item" key={key}>
                <strong>{`${trade.tradeCategory}:`}</strong> {trade.value || ""}
              </div>
            );
          })
        )}
    </div>
  );
}

Trades.propTypes = {
  industryTrades: PropTypes.array,
  builders: PropTypes.array,
};

Trades.defaultProps = {
  industryTrades: [],
  builders: [],
};

export default Trades;
