/* eslint-disable no-param-reassign */
const { createSlice } = require("@reduxjs/toolkit");

export const WizardSteps = {
  Info: "Info",
  Checkboxes: "Checkboxes",
  Result: "Result",
};

const initialState = {
  step: WizardSteps.Info,
  data: {
    name: "",
    visibility: "open",
    address: {
      street: "",
      suburb: "",
      postCode: 0,
      idState: null,
      latitude: undefined,
      longitude: undefined,
    },
    budget: 0,
    quotesDueBy: new Date(),
    anticipatedStartDate: new Date(),
    anticipatedEndDate: new Date(),
    description: "",
    docRequest: "auto",
    category: "Aged Care",
    trades: [],
  },
};

export const newProjectSlice = createSlice({
  name: "newProject",
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setName: (state, action) => {
      state.data.name = action.payload;
    },
    setVisibility: (state, action) => {
      state.data.visibility = action.payload;
    },
    setAddress: (state, action) => {
      state.data.address = action.payload;
    },
    setIdState: (state, action) => {
      state.data.idState = action.payload;
    },
    setBudget: (state, action) => {
      state.data.budget = action.payload;
    },
    setQuotesDueBy: (state, action) => {
      state.data.quotesDueBy = action.payload;
    },
    setAnticipatedStartDate: (state, action) => {
      state.data.anticipatedStartDate = action.payload;
    },
    setAnticipatedEndDate: (state, action) => {
      state.data.anticipatedEndDate = action.payload;
    },
    setDescription: (state, action) => {
      state.data.description = action.payload;
    },
    setDocRequests: (state, action) => {
      state.data.docRequest = action.payload;
    },
    setCategory: (state, action) => {
      state.data.category = action.payload;
    },
    setTrades: (state, action) => {
      if (state.data.trades.includes(action.payload)) {
        state.data.trades = state.data.trades.filter((el) => el !== action.payload);
      } else {
        state.data.trades.push(action.payload);
      }
    },
    setProjectClassification: (state, action) => {
      state.data.projectClassification = action.payload;
    },
  },
});

export default newProjectSlice.reducer;
