import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
// import logger from "redux-logger";
import * as History from "history";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

const initialState = {};
const enhancers = [];

export const history = History.createBrowserHistory();

// const middleware = [thunk, logger, routerMiddleware(history)];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default createStore(rootReducer(history), initialState, composedEnhancers);
