import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

function Description({ data }) {
  return (
    <div className="project-details">
      <div>
        <p>
          Quotes are required to be submitted by&nbsp;
          <strong>{data.quoteDue.length > 0 && data.quoteDue[0].date_due}</strong>.
        </p>
        <p>please contact the tendering builders listed below for further below for further information.</p>
      </div>
      <ul className="detail-list">
        {data.companyDescription.map((item, key) => (
          <li key={key}>
            <strong>
              {item.company_name}
              &nbsp;
            </strong>
            {item.tender_description || "has not provided a description."}
          </li>
        ))}
      </ul>
      <div className="detail-nbc">
        {/*<span className="tab nbc mr-3">NBC</span>*/}
        <div className="nbc description">
          The Code for the Tendering and Performance of Building Work 2016 applies to the building work
          associated with this project. By submitting an expression of interest in, or tender for, this
          project, you will become subject to the Code for the Tendering and Performance of Building Work
          2016.
          <a
            href="https://www.abcc.gov.au/building-code/what-code"
            className="small link-sidebar secondary ml-1"
            target="_blank"
            rel="noopener noreferrer">
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
}

Description.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Description;
