import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CustomSearchInput from "../../../components/Elements/CustomSearchInput";
import CustomSelect from "../../../components/Elements/CustomSelect";
import DirectoryTable from "../../../components/Elements/DirectoryTable";
import Pagination from "../../../components/Elements/PaginationBody";
import Loader from "../../../components/Elements/Loading";

import { fetchDirectoryData } from "../../../redux/action/directory";

import "./styles.scss";
import "../style.scss";

const DirectoryBody = ({ fetchDirectoryData, directories, page, limit, isFetchingList }) => {
  const [directoriesData, setDirectories] = useState([]);
  const [filter, setFilter] = useState({
    name: "",
    favorite: false,
    award: false,
    current: false,
  });

  useEffect(() => {
    const tableData = directories.map((item) => ({
      id: item.id,
      favorite: item.favorite,
      builder: item.company_name,
      phone: item.phone,
      fax: item.fax,
      location: item.location,
      dist: item.distance || "---",
      current_tenders: item.current_tenders,
      awarded_tenders: 0,
    }));
    setDirectories(tableData);
  }, [directories]);

  const searchByName = (searchKey) => {
    if (!isFetchingList) {
      const updateFilter = {
        ...filter,
        name: searchKey,
      };
      fetchDirectoryData(page, limit, updateFilter);
      setFilter(updateFilter);
    }
  };

  const onStatusFilter = (type) => () => {
    if (!isFetchingList) {
      const updateFilter = {
        ...filter,
        [type]: !filter[type],
      };
      fetchDirectoryData(page, limit, updateFilter);
      setFilter(updateFilter);
    }
  };

  return (
    <div className="directory_body">
      <div className="directory_table">
        <div className="row">
          <div className="search_input">
            <CustomSearchInput placeholder="Search by account name" onChange={searchByName} />
          </div>
          <div className="filter_section">
            <label>Filter Within</label>
            <CustomSelect data={[]} onDragMouseDown={() => {}} mode="single" width="200px" />
            <label>from</label>
            <label className="south_kolan">SOUTH KOLAN QLD</label>
          </div>
        </div>
        <div className="row table">
          {isFetchingList ? (
            <Loader />
          ) : (
            <DirectoryTable
              data={directoriesData}
              limit={limit}
              page={page}
              filter={filter}
              fetchData={fetchDirectoryData}
            />
          )}
          <Pagination limit={limit} page={page} data={directories} fetchData={fetchDirectoryData} />
        </div>
      </div>
    </div>
  );
};

DirectoryBody.propTypes = {
  fetchDirectoryData: PropTypes.func.isRequired,
  directories: PropTypes.array,
  isFetchingList: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

DirectoryBody.defaultProps = {
  directories: [],
};

const mapStateToProps = (store) => ({
  directories: store.directoryData.directories,
  isFetchingList: store.directoryData.isFetchingList,
  page: store.tendersData.page,
  limit: store.tendersData.limit,
  error: store.usersData.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDirectoryData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DirectoryBody);
