import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const AddNewProjectButton = ({ onClick }) => (
  <Link className="add-new-project-button" onClick={onClick} to="/new-project">
    + Add New Project
  </Link>
);

AddNewProjectButton.defaultProps = {
  onClick: () => {},
};

export default AddNewProjectButton;
