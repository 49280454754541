import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, useHistory, Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import queryString from "query-string";

import { updatePassword } from "../../redux/action/users";
import logo from "../../utils/images/logo_signin.png";

import "./style.scss";

const UpdatePWD = (props) => {
  const history = useHistory();
  const params = queryString.parse(props.location.search);
  const [pass, setPass] = useState({
    new: "",
    confirm: null,
  });
  const [alertFg, setAlertFg] = useState(false);
  const [misMatch, setMisMatch] = useState(null);

  const onChangePass = (type) => (e) => {
    const newUpdatePass = pass;
    setMisMatch(null);
    newUpdatePass[type] = e.target.value;
    setPass(newUpdatePass);
  };

  const onSubmit = () => {
    if (pass.new.length < 9 || pass.new.length > 30) {
      setMisMatch("Please input password between 9 and 30 characters!");
      return;
    }
    if (pass.new === pass.confirm) {
      const sendInfo = {
        password: pass.new,
        userId: params.u,
        token: params.t,
      };
      props
        .updatePassword(sendInfo)
        .then(() => {
          setAlertFg(false);
          history.push("/login");
        })
        .catch(() => {
          setAlertFg(true);
        });
    } else {
      setMisMatch("The passwords are not the matched.");
    }
  };

  const closeMisMatch = () => {
    setMisMatch(null);
  };

  const confirmExpire = () => {
    history.push("/signup");
  };

  return (
    <div className="update-password">
      {alertFg && <SweetAlert danger title="Your request has expired" onConfirm={confirmExpire} />}
      {misMatch && <SweetAlert danger title={misMatch} onConfirm={closeMisMatch} />}
      <div className="updatepwd-form">
        <div className="logo-wrapper">
          <Link to="/" className="logo-link">
            <img className="logo-dark logo-img logo-img-lg" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="updatepwd-content">
          <div className="fs-24px fw-bold pb-3">Set your password</div>
          <div className="pb-3">Please set a password so we can keep your details secure</div>
          <div className="fs-20px pt-1">
            <span className="pr-2 fw-bold">Your account:</span>
            <span className="heading-email text-purple">{params.e}</span>
          </div>
          <a className="switch-account p-2" href="/login">
            Not you? Click here to switch
          </a>
          <div className="d-flex flex-column w-100">
            <input
              type="password"
              className="form-control form-control-lg update_input mb-2"
              id="new_password"
              name="new_password"
              placeholder="New Password"
              onChange={onChangePass("new")}
            />
            <input
              type="password"
              className="form-control form-control-lg update_input mb-2"
              id="new_password"
              name="new_password"
              placeholder="Confirm Password"
              onChange={onChangePass("confirm")}
            />
            <div className="form-group d-flex justify-center">
              <button
                type="submit"
                className="col-lg-12 pl-4 pr-4 pt-3 pb-3 btn btn-lg btn-block  btn-primary signup-sendbt"
                onClick={onSubmit}>
                Set Password and login
              </button>
            </div>
          </div>
        </div>
        <div className="reset-footer">
          <div>Having trouble? Contact us at</div>
          <div className="text-blue">support@tndr.com.au</div>
        </div>
      </div>
    </div>
  );
};

UpdatePWD.propTypes = {
  updatePassword: PropTypes.func.isRequired,
  location: PropTypes.object,
};

UpdatePWD.defaultProps = {
  location: {
    search: "",
  },
};

const mapStateToProps = (store) => ({
  authUser: store.usersData.authUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdatePWD));
