import httpService from "./http.service";

const getDashboardOptions = () => "/options/dashboard";

const fetch = () =>
  httpService
    .get(getDashboardOptions())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

export default {
  fetch,
};
