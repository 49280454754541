import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Menu } from "antd";

import { setFilters } from "../../../../../redux/slices/tendersSearch";

const Budget = () => {
  const dispatch = useDispatch();

  const { filters, tenderBudgets } = useSelector((state) => ({
    filters: state.tendersSearch.filters,
    tenderBudgets: state.usersData.tenderBudgets,
  }));

  const handleMenuItemClick = ({ key }) => {
    dispatch(setFilters({ ...filters, max: Number(key) }));
  };

  const selectedBudget = tenderBudgets.find((item) => item.key === filters.max);

  const Overlay = () => {
    return (
      <Menu onClick={handleMenuItemClick}>
        {tenderBudgets.map((item) => (
          <Menu.Item key={item.key}>
            <span key={item.key}>{item.value}</span>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <Dropdown overlay={Overlay} placement="bottomCenter" trigger={["click"]} arrow>
      <button className={filters.max ? "filter active" : "filter"}>
        {selectedBudget && selectedBudget.value ? `Budget: ${selectedBudget && selectedBudget.value}` : "Budget"}
      </button>
    </Dropdown>
  );
};

export default Budget;
