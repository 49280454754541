import React from "react";
import DatePicker from "../../../../../components/Elements/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";

const NewProjectAnticipatedEndDate = () => {
  const dispatch = useDispatch();
  const { anticipatedEndDate } = useSelector((state) => ({
    anticipatedEndDate: state.newProject.data.anticipatedEndDate,
  }));

  const onChange = (date) => {
    dispatch(newProjectSlice.actions.setAnticipatedEndDate(date));
  };

  return (
    <div className="form-group">
      <label className="form-label">Anticipated End Date</label>
      <div className="form-control-wrap">
        <DatePicker value={anticipatedEndDate} onChange={onChange} />
      </div>
    </div>
  );
};

export default NewProjectAnticipatedEndDate;
