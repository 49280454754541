import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Form, Input, message } from "antd";
import CustomModal from "../../CustomModal";
import UserService from "../../../services/user.service";
import { updateUser } from "../../../redux/action/users";

const LastNameChangeModal = ({ visible, changeVisible }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const onCancel = () => {
    changeVisible(false);
  };

  const handleFinish = (values) => {
    UserService.UpdateUser(values)
      .then((data) => {
        dispatch(updateUser(data));
        message.success("Updated successfully");
      })
      .catch((error) => {
        message.error("Error");
        console.log(error);
      })
      .finally(() => {
        changeVisible(false);
      });
  };

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <CustomModal
      title="Change your last name"
      subtitle="Change name field and then press OK"
      visible={visible}
      onCancel={onCancel}
      closable={false}
      onOk={handleOk}>
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item
          name="last_name"
          label="Last Name"
          validtaion="onSubmit"
          rules={[{ required: true, message: "Please, input your Last Name" }]}>
          <Input />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

LastNameChangeModal.defaultProps = {
  visible: false,
  changeVisible: () => {},
};

LastNameChangeModal.propTypes = {
  visible: PropTypes.bool,
  changeVisible: PropTypes.func,
};

export default LastNameChangeModal;
