import httpService from "./http.service";

const quotesUrl = "/quotes";

const CreateNewQuote = (quoteData) =>
  httpService
    .post(`${quotesUrl}/create`, quoteData)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

const GetQuotes = () =>
  httpService
    .get(`${quotesUrl}/list`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

const GetProjectQuotes = ({ id_project }) =>
  httpService
    .get(`${quotesUrl}/projects/${id_project}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

export default {
  CreateNewQuote,
  GetProjectQuotes,
  GetQuotes,
};
