import httpService from "./http.service";

const fetch = (abnVal) => {
  if (!abnVal) {
    throw new Error("abn param value undefined");
  }

  return httpService
    .get(`abn?abn=${abnVal}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));
};

export default {
  fetch,
};
