import React, { useEffect, useState } from "react";

import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { getDistance } from "geolib";
import { fetchCompanyWatchList } from "../../../redux/action/companyWatchList";
import { SelectWatchList } from "../../../redux/action/tenders";
import WatchingListTable from "../../../components/Elements/WatchingListTable";

// project_title(pin):"Staircase from Heaven"
// id_project_category(pin):"Aged Care"
// status(pin):1
// id_project(pin):6
// location_latitude(pin):-37.887492
// location_longitude(pin):145.058246
// date_tender_close(pin):"2021-11-22T10:49:08.000Z"
// id_watchlist_type(pin):2
//
//
// [].map(item => {
//
//   return {
//     id: item.id_project,
//     project: item.project_title,
//     category: item.id_project_category,
//     location: item.location,
//     distance: item.distance,
//     dateTenderClose: item.date_tender_close,
//     interest: mapToInterest(item.watchlist_type)
//   }
//
// })

const data = [
  {
    id: "84264",
    project: "Indooroopilly Shopping Centre Mini Majors Project",
    category: "Retail",
    location: "Indooroopilly, QLD",
    dist: "300km",
    quotes_due: [
      {
        date_tender_close: "Kapitol Group",
      },
      {
        date_tender_close: "Kapitol Group",
      },
    ],
    interest: {
      value: "interested",
      data: [
        { key: "interested", value: "Interested" },
        { key: "not_quoting", value: "Not Quoting" },
        { key: "quoting", value: "Quoting" },
        { key: "not_interested", value: "Not Interested" },
      ],
      onChange: () => {},
      onClick: () => {},
    },
  },
  {
    id: "84893",
    project: "\t12 Clarke St, Sunshine",
    category: "Commercial",
    location: "Sunshine VIC",
    dist: "1599km",
    quotes_due: [
      {
        date_tender_close: "Kapitol Group",
      },
      {
        date_tender_close: "Kapitol Group",
      },
    ],
    interest: {
      value: "interested",
      data: [
        { key: "interested", value: "Interested" },
        { key: "not_quoting", value: "Not Quoting" },
        { key: "quoting", value: "Quoting" },
        { key: "not_interested", value: "Not Interested" },
      ],
      onChange: () => {},
      onClick: () => {},
    },
  },
  {
    id: "85020",
    project: "Forest Glen Tech Park",
    category: "Commercial",
    location: "Forest Glen QLD",
    dist: "211km",
    quotes_due: [
      {
        date_tender_close: "Kapitol Group",
      },
      {
        date_tender_close: "Kapitol Group",
      },
    ],
    interest: {
      value: "interested",
      data: [
        { key: "interested", value: "Interested" },
        { key: "not_quoting", value: "Not Quoting" },
        { key: "quoting", value: "Quoting" },
        { key: "not_interested", value: "Not Interested" },
      ],
      onChange: () => {},
      onClick: () => {},
    },
  },
];
function WatchingListBody() {
  const { companyWatchList, companyOffice, companyInfo } = useSelector((state) => ({
    companyWatchList: state.usersData.companyWatchList,
    companyOffice: state.usersData.companyOffice,
    companyInfo: state.usersData.authUser.data.companyInfo,
  }));

  const dispatch = useDispatch();

  const companyId = companyInfo.id;

  useEffect(() => {
    dispatch(fetchCompanyWatchList(companyId));
  }, [dispatch, companyId]);

  const watchListData = companyWatchList.map((item) => {
    const projectCoordinates = {
      latitude: item.location_latitude,
      longitude: item.location_longitude,
    };
    const companyCoordinates = {
      latitude: companyOffice.company_office_latitude,
      longitude: companyOffice.company_office_longitude,
    };

    console.log({
      projectCoordinates,
      companyCoordinates,
    });

    const map = {
      1: { key: "interested", value: "Interested" },
      2: { key: "not_interested", value: "Not Interested" },
      3: { key: "quoting", value: "Quoting" },
      4: { key: "not_quoting", value: "Not Quoting" },
    };

    const mapToInterest = (watchListOptionId) => {
      return map[watchListOptionId].key;
    };
    const mapToWatchListOptionId = (interestValue) => {
      const entry = Object.entries(map).find(([_, value]) => {
        return value.key === interestValue;
      });
      return entry[0];
    };

    const distance =
      companyOffice.company_office_latitude && companyOffice.company_office_longitude
        ? Math.round(100 * (getDistance(companyCoordinates, projectCoordinates) / 1000)) / 100
        : "--";
    return {
      id: item.id_project,
      project: item.project_title,
      category: item.id_project_category,
      location: item.location,
      date_tender_close: item.date_tender_close,
      distance: `${distance} km`,
      interest: {
        value: mapToInterest(item.id_watchlist_type),
        data: Object.values(map),
        onChange: (value) => {
          dispatch(SelectWatchList(item.id_project, mapToWatchListOptionId(value), companyInfo.id));
        },
        onClick: () => {},
      },
    };
  });

  const [selectItem, setSelectItem] = useState("active");

  const selectActive = (item) => () => {
    setSelectItem(item);
  };

  const searchByName = (searchKey) => {
    console.log(searchKey);
  };

  const responseBuilder = [
    { key: "all", label: "All" },
    { key: "unactioned", label: "Unactioned" },
    { key: "not_quoting", label: "Not Quoting" },
    { key: "quoting", label: "Quoting" },
    { key: "quoted", label: "Quoted" },
  ];

  return (
    <div className="invitation_body">
      <div className="invitation_container row">
        <div className="search-section row w-100 mb-2 align-items-center">
          <div className="search-invitations col-4">
            <h3>Open tenders on Watchlist</h3>
          </div>
        </div>
        <WatchingListTable data={watchListData} />
      </div>
    </div>
  );
}

WatchingListBody.propTypes = {};

WatchingListBody.defaultProps = {};

export default WatchingListBody;
