import React from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Form } from "antd";
import phoneLib from "phone";
import ValidatorInput from "./Input";

function PhoneValidator({ hasLabel = true }) {
  return (
    <Form.Item
      label={hasLabel ? "Phone number" : null}
      name="phone"
      validateTrigger="onSubmit"
      rules={[
        {
          required: true,
          type: "regexp",
          pattern: new RegExp(/^[\d()+]+$/),
          message: "Please, input your phone",
        },
        () => ({
          validator(_, values) {
            if (values) values.intPhone = phoneLib(values.phone, values.country?.short || "AU", true)[0];

            if (values?.phone && !isValidPhoneNumber(values?.intPhone || ""))
              return Promise.reject("Please, input correct phone number");

            return Promise.resolve();
          },
        }),
      ]}>
      <ValidatorInput />
    </Form.Item>
  );
}

export default PhoneValidator;
