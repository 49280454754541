import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import CustomModalLayout from "./layout";

import "./style.scss";

const CustomModal = ({
  visible,
  onCancel,
  onOk,
  title,
  subtitle,
  children,
  width,
  buttons,
  className,
  okButtonProps,
  okText,
  cancelText,
  centered,
  footer,
  closable,
  hideOkButton,
  hideCancelButton,
  destroyOnClose,
}) => {
  const modalStyle = buttons ? className : `${className} antd-confirm-modal-wo-buttons`;
  const contentStyle = buttons ? "antd-modal-content" : "antd-modal-content pd-b-20-f";

  const hideButtonStyle = { style: { display: "none" } };

  return (
    <Modal
      title={null}
      icon={null}
      width={width}
      className={modalStyle}
      maskClosable
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      cancelButtonProps={(!buttons || hideCancelButton) && hideButtonStyle}
      okButtonProps={!buttons || hideOkButton ? hideButtonStyle : okButtonProps}
      okText={okText}
      cancelText={cancelText}
      centered={centered}
      footer={footer}
      closable={closable}
      destroyOnClose={destroyOnClose}>
      <CustomModalLayout title={title} subtitle={subtitle} contentStyle={contentStyle}>
        {children}
      </CustomModalLayout>
    </Modal>
  );
};

CustomModal.defaultProps = {
  visible: false,
  onCancel: () => {},
  onOk: () => {},
  title: null,
  subtitle: null,
  children: null,
  okText: undefined,
  cancelText: undefined,
  width: undefined,
  okButtonProps: undefined,
  buttons: true,
  className: "antd-modal-styled",
  centered: true,
  footer: undefined,
  closable: true,
  hideCancelButton: false,
  hideOkButton: false,
  destroyOnClose: false,
};

CustomModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node,
  okText: PropTypes.node,
  cancelText: PropTypes.node,
  width: PropTypes.number,
  buttons: PropTypes.bool,
  className: PropTypes.string,
  okButtonProps: PropTypes.object,
  centered: PropTypes.bool,
  footer: PropTypes.node,
  closable: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  hideOkButton: PropTypes.bool,
  destroyOnClose: PropTypes.bool,
};

export default React.memo(CustomModal);
