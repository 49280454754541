import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";

const NewProjectVisibility = () => {
  const dispatch = useDispatch();
  const { visibility } = useSelector((state) => ({ visibility: state.newProject.data.visibility }));

  const onChange = useCallback(
    (e) => {
      dispatch(newProjectSlice.actions.setVisibility(e.target.value));
    },
    [dispatch]
  );

  return (
    <div className="form-group">
      <label className="form-label">Visibility</label>
      <div className="form-control-wrap">
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="new-project-visibility-open"
            name="new-project-visibility"
            className="custom-control-input"
            value="open"
            checked={visibility === "open"}
            onChange={onChange}
          />
          <label className="custom-control-label" htmlFor="new-project-visibility-open">
            Open Tender
          </label>
        </div>
        <div className="custom-control custom-radio ml-3">
          <input
            type="radio"
            id="new-project-visibility-negotiate"
            name="new-project-visibility"
            className="custom-control-input"
            value="negotiate"
            checked={visibility === "negotiate"}
            onChange={onChange}
          />
          <label className="custom-control-label" htmlFor="new-project-visibility-negotiate">
            Negotiated or Sensitive
          </label>
        </div>
      </div>
    </div>
  );
};

export default NewProjectVisibility;
