import types from "../actionType";

const INITIAL_STATE = {
  isFetchingList: false,
  isCreating: false,
  isUpdating: false,
  locationState: [],
  industryTrades: [],
  watchList: [],
  categories: [],
  tenderBudgets: [],
  error: null,
  tenderClassifications: [],
  authUser: localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : {},
  // companyInfo: {}, //TODO replace with companyOffice eventually
  companyInfo: localStorage.getItem("companyInfo") ? JSON.parse(localStorage.getItem("companyInfo")) : {},
  companyOffice: localStorage.getItem("companyOffice")
    ? JSON.parse(localStorage.getItem("companyOffice"))
    : {},
  companyWatchList: [],
  sentEmail: null,
};

const getIndustryTrades = (data) =>
  data.map((item) => ({
    key: item.key,
    value: item.value,
    tradeCategory: item.category,
  }));

const getCategories = (data) =>
  data.map((item) => ({
    key: item,
    value: item,
  }));

const getTenderClassifications = (data) =>
  data.map((item) => ({
    key: item.id,
    value: item.project_classification_title,
  }));

const getLocationState = (data) =>
  data.map((item) => ({
    key: item.id,
    value: item.state_title,
  }));

const getTenderBudgets = (data) =>
  data.map((item) => ({
    key: item.id,
    value: item.budget_value,
  }));

const getWatchListState = (data) =>
  data.map((item) => ({
    key: item.id,
    value: item.watchlist_title,
  }));

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.OPTION_FETCH_REQUEST:
      return {
        ...state,
        isFetchingList: true,
      };
    case types.OPTION_FETCH_SUCCESS:
      const optionData = action.payload.data;
      const tenderBudgets = getTenderBudgets(optionData.tender_budgets);
      const locationState = getLocationState(optionData.location_state);
      const industryTrades = getIndustryTrades(optionData.industry_trades);
      const watchList = getWatchListState(optionData.watchList);
      const categories = getCategories(optionData.categories);
      const tenderClassifications = getTenderClassifications(optionData.project_classifications);
      return {
        ...state,
        isFetchingList: false,
        locationState,
        tenderBudgets,
        industryTrades,
        categories,
        watchList,
        tenderClassifications,
      };
    case types.OPTION_FETCH_FAILURE:
      return {
        ...state,
        isFetchingList: false,
      };
    case types.SIGN_UP_REQUEST:
      return {
        ...state,
        isCreatingList: true,
      };
    case types.SIGN_UP_SUCCESS:
      const data = action.payload;
      localStorage.setItem("access_token", data.data.token);
      localStorage.setItem("userId", data.data.id);
      localStorage.setItem("companyOffice", JSON.stringify({}));
      localStorage.setItem("companyInfo", JSON.stringify({}));
      return {
        ...state,
        sentEmail: data.data.email,
        error: null,
        isCreatingList: false,
      };
    case types.SIGN_UP_FAILURE:
      const { error } = action.payload;
      return {
        ...state,
        error,
        isCreatingList: false,
      };
    case types.SIGN_IN_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case types.SIGN_IN_SUCCESS:
      const authData = action.payload;
      localStorage.setItem("authUser", JSON.stringify(authData));
      localStorage.setItem(
        "companyOffice",
        authData.data.companyOffice ? JSON.stringify(authData.data.companyOffice) : JSON.stringify({})
      );
      localStorage.setItem(
        "companyInfo",
        authData.data.companyInfo ? JSON.stringify(authData.data.companyInfo) : JSON.stringify({})
      );
      localStorage.setItem("access_token", authData.data.authUser.token);

      return {
        ...state,
        authUser: authData,
        companyOffice: authData.data.companyOffice,
        companyInfo: authData.data.companyInfo,
        error: null,
        isCreating: false,
      };
    case types.SIGN_IN_FAILURE:
      const loginError = action.payload.error;
      return {
        ...state,
        isCreating: false,
        error: loginError,
      };
    case types.LOG_OUT_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case types.LOG_OUT_SUCCESS:
    case types.LOG_OUT_FAILURE:
      localStorage.clear();
      return {
        ...state,
        authUser: {},
        isCreating: false,
      };
    case types.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case types.UPDATE_USER:
      const newUser = action.payload;
      localStorage.setItem("authUser", JSON.stringify(newUser));
      localStorage.setItem("access_token", newUser.data.authUser.token);
      return {
        ...state,
        authUser: { ...state.authUser, ...newUser },
      };
    case types.UPDATE_USER_TRADES:
      const newTrades = action.payload.company_trades.map((id_trade) => ({ id_trade }));
      state.authUser.data.authUser.company_trades = [
        ...newTrades,
        ...state.authUser.data.authUser.company_trades,
      ];
      localStorage.setItem("authUser", JSON.stringify(state.authUser));
      return {
        ...state,
      };
    case types.UPDATE_COMPANY_OFFICE:
      const newOffice = action.payload;
      localStorage.setItem("companyOffice", JSON.stringify(newOffice.data));
      return {
        ...state,
        companyOffice: state.companyOffice
          ? { ...state.companyOffice, ...newOffice.data }
          : { ...newOffice.data },
      };
    case types.COMPANY_WATCH_LIST_SUCCESS:
      const companyWatchList = action.payload;
      return {
        ...state,
        companyWatchList: companyWatchList.data,
      };

    default:
      return state;
  }
};
