import React, { useState } from "react";
import "./style.scss";
import { Form, Input, Select, Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import LocationSearchInput from "../../Elements/PlaceAutoComplete";
import PhoneValidator from "../../Elements/PhoneValidator";
import CompaniesService from "../../../services/companies.service";
import { updateUser, updateOffice } from "../../../redux/action/users";

const CompanyInformation = ({ companyTrades, dispatch }) => {
  const { industryTrades, company_name } = useSelector((state) => ({
    industryTrades: state.usersData.industryTrades,
    company_name: state.usersData.authUser.data.authUser.company_name,
  }));
  const [companyName, setCompanyName] = useState(company_name);

  const handleFinish = (data) => {
    const submitData = { ...data, company_name: companyName };
    CompaniesService.UpdateYourCompany(submitData).then((data) => {
      message.success("Updated successfully");
      dispatch(updateUser(data));
    });
  };

  const handleClick = () => {
    form.submit();
  };

  const handleChangeValue = (e) => {
    setCompanyName(e.target.value);
  };
  const [form] = Form.useForm();
  return (
    <Form
      className="company-information mt-4 d-flex flex-column flex-grow-1"
      form={form}
      onFinish={handleFinish}>
      <h3>Company Information</h3>
      <div className="mt-4">
        <h5>Company Name</h5>
        <Input
          className="company-name__input"
          placeholder="Company Name"
          value={companyName}
          defaultValue={companyName}
          onChange={handleChangeValue}
        />
      </div>
      <div className="mt-2">
        <h5>Trades</h5>
        <Form.Item name="trades">
          <Select
            placeholder="Please Select"
            className="w-100 select-trades"
            mode="multiple"
            defaultValue={companyTrades}
            optionLabelProp="label"
            options={industryTrades.map((elem) => ({ label: elem.value, value: elem.key }))}
          />
        </Form.Item>
      </div>
      <Button onClick={handleClick} type="primary" className="save-button mt-0">
        Save & update changes
      </Button>
    </Form>
  );
};
const OfficeInformation = ({ dispatch }) => {
  const [formOffice] = Form.useForm();

  const { locationState, companyOffice, companyInfo } = useSelector((state) => ({
    locationState: state.usersData.locationState,
    companyOffice: state.usersData.companyOffice,
    companyInfo: state.usersData.companyInfo,
  }));

  console.log({ companyOffice, companyInfo });

  const [address, setAddress] = useState(
    (companyOffice && companyOffice.company_office_street_address) || ""
  );
  const [officeName, setOfficeName] = useState(companyOffice && companyOffice.company_office_name);

  const [companyLocationData, setCompanyLocationData] = useState({
    latitude: companyOffice.company_office_latitude,
    longitude: companyOffice.company_office_longitude,
    post_code: companyOffice && companyOffice.company_office_street_post_code,
    state: companyOffice && locationState?.find((state) => state.key === companyOffice.id_state)?.value,
    suburb: companyOffice && companyOffice.company_office_street_suburb,
    street: companyOffice && companyOffice.company_office_street_address,
  });

  const setCompanyLocation = (address, addressMetadata) => {
    setAddress(address);
    setCompanyLocationData({
      latitude: addressMetadata.latitude,
      longitude: addressMetadata.longitude,
      post_code: addressMetadata.post_code,
      state: addressMetadata.state,
      suburb: addressMetadata.suburb,
      street: addressMetadata.street,
    });
  };

  const handleFinish = (data) => {
    const submitData = {
      ...data,
      address,
      ...companyLocationData,
      id_state: locationState?.find((state) => state.value === companyLocationData.state).key,
    };
    CompaniesService.UpdateYourCompanyOffice(submitData).then((res) => {
      dispatch(updateOffice(res));
      message.success("Updated successfully");
    });
  };
  const handleClick = () => {
    formOffice.submit();
  };

  return (
    <Form
      className="office-information d-flex flex-column flex-grow-1"
      form={formOffice}
      initialValues={{
        ...companyOffice,
        office_name: companyOffice?.company_office_name,
        office_postcode: companyOffice?.company_office_street_post_code,
        office_fax: companyOffice?.company_fax_number,
        office_email: companyOffice?.company_office_email,
        office_website: companyOffice?.company_office_website,
        phone: { phone: companyOffice?.company_office_phone_number },
      }}
      onFinish={handleFinish}>
      <h3 className="mt-4">Office Information</h3>
      <div className="office-name mt-4">
        <h5 className="office-name__name">Office Name</h5>
        <Form.Item name="office_name">
          <Input placeholder="Head Office" value={officeName} />
        </Form.Item>
      </div>
      <div className="office-address mt-2">
        <h5>Street Address</h5>
        <LocationSearchInput
          setCompanyLocation={setCompanyLocation}
          companyAddress={companyLocationData.street}
        />
      </div>
      <div className="address-info mt-2 justify-content-between">
        <div className="address-info-line d-flex mt-3">
          <div className="flex-grow-1">
            <h5>Suburb</h5>
            <Input disabled value={companyLocationData.suburb} className="w-100" placeholder="Suburb" />
          </div>
          <div className="flex-grow-1 mx-4">
            <h5>State</h5>
            <Select
              disabled
              value={companyLocationData.state}
              className="state-selector w-100"
              placeholder="State"
            />
          </div>
          <div className="flex-grow-1">
            <h5>Postcode</h5>
            <Input disabled value={companyLocationData.post_code} placeholder="Postcode" />
          </div>
        </div>
        <div className="address-info-columns d-flex justify-content-between mt-3">
          <div className="d-flex flex-column flex-grow-1">
            <div className="mt-2 mb-4">
              <h5>Phone Number</h5>
              <PhoneValidator hasLabel={false} />
            </div>
            <div className="mt-2">
              <h5>Office Email</h5>
              <Form.Item name="office_email">
                <Input placeholder="Office Email" />
              </Form.Item>
            </div>
          </div>
          <div className="d-flex flex-column flex-grow-1 additional-office-information">
            <div className="mt-2 mb-4">
              <h5>Fax Number</h5>
              <Form.Item name="office_fax">
                <Input placeholder="Fax Number" />
              </Form.Item>
            </div>
            <div className="mt-2">
              <h5>Website</h5>
              <Form.Item name="office_website">
                <Input placeholder="Website" />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <Button type="primary" onClick={handleClick} className="save-button">
        Save & update office details
      </Button>
    </Form>
  );
};

const Account = () => {
  const dispatch = useDispatch();
  const { company_trades } = useSelector((state) => ({
    company_trades: state.usersData.authUser.data.authUser.company_trades,
  }));

  return (
    <div className="account-settings-wrapper">
      <div className="account-settings-container d-flex justify-content-between">
        <OfficeInformation dispatch={dispatch} />
        <CompanyInformation
          dispatch={dispatch}
          companyTrades={company_trades?.map((trade) => trade.id_trade)}
        />
      </div>
    </div>
  );
};

export default Account;
