import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas, far, fal, fab);

const FontAwesomePro = ({ iconName, iconSize, iconClass, color }) => {
  if (iconName && typeof iconName === "string" && iconName !== "") {
    const iconLibName = iconName.split(" ", 2);
    return <FontAwesomeIcon icon={iconLibName} size={iconSize} className={iconClass} color={color} />;
  }
  return null;
};

FontAwesomePro.propTypes = {
  iconName: PropTypes.string,
  iconSize: PropTypes.string,
  iconClass: PropTypes.string,
};

FontAwesomePro.defaultProps = {
  iconName: "fal info-circle",
  iconSize: "1x",
  iconClass: "fa-com-icons",
};

export default React.memo(FontAwesomePro);
