import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

import "./style.scss";

function CustomMultiSelect({ onChange, data }) {
  const optionData = data.map((dataItem) => <option key={dataItem.key}>{dataItem.value}</option>);

  return (
    <Select
      mode="multiple"
      style={{ width: "100%" }}
      name="trade"
      placeholder="Select Industry Trades"
      onChange={onChange}>
      {optionData}
    </Select>
  );
}

CustomMultiSelect.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
};

CustomMultiSelect.defaultProps = {
  data: [],
  onChange: () => {},
};

export default CustomMultiSelect;
