import React from "react";
import "./style.scss";

const Footer = () => (
  <div className="footer">
    <label className="footer-item">
      {`© ${new Date().getFullYear()} TNDR Pty Ltd - All rights reserved Terms of use Privacy Policy`}
    </label>
    <label className="footer-item">Connect with us on</label>
    <span>
      <em className="icon ni ni-facebook-circle text-gray rounded-circle fs-20px pr-2" />
    </span>
    <span>
      <em className="icon ni ni-linkedin-round text-gray rounded-circle fs-20px pr-2" />
    </span>
  </div>
);

export default Footer;
