import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
// import { faPrint } from "@fortawesome/pro-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import { Drawer, Divider } from "antd";

// import CustomSelect from "../CustomSelect";
import Description from "./Description";
import Builder from "./Builder";
import Notes from "./Notes";
import Trades from "./Trades";
import History from "./History";

import "./styles.scss";
import TenderQuotes from "./TenderQuotes/TenderQuotes";

/**
 * React Hook for listening to (horizontal) drag changes
 */
const useDragExpander = ({ min, max }) => {
  const [dragState, setDragState] = useState(0);
  const initialPos = useRef(0);
  const timer = useRef();

  const update = useCallback(
    (xPos) => setDragState((state) => ({ ...state, delta: initialPos.current - xPos + state.lastDelta })),
    []
  );
  const onDragMove = useCallback((e) => update(e.screenX), [update]);

  const dragStart = () => {
    setDragState((state) => ({ ...state, lastDelta: state.delta || 0, isDragging: true }));
    window.addEventListener("mousemove", onDragMove);
  };

  const unbind = () => {
    clearTimeout(timer.current);
    window.removeEventListener("mousemove", onDragMove);
    setDragState((state) => ({ ...state, isDragging: false }));
  };

  const onDragMouseDown = (e) => {
    if (e.button !== 0) return; // only allow left-mouse clicks
    e.preventDefault();
    initialPos.current = e.screenX; // re-set initial position
    timer.current = setTimeout(dragStart, 0, e); // only allow dragging after N duration mouse down
    window.addEventListener("mouseup", unbind);
  };

  const limitDragRange = useCallback((delta) => Math.min(max, Math.max(min, delta || 0)), [max, min]);

  return {
    onDragMouseDown,
    onDragMove,
    dragState,
    limitDragRange,
  };
};

/**
 *
 * @param {Object} scrollParent [DOM node of scrollable element]
 * @param {Array} _targetElements [Array of nodes to spy on]
 */
const spyScroll = (scrollParent, _targetElements) => {
  if (!scrollParent) return false;

  // create an Object with all children that has data-name attribute
  const targetElements =
    _targetElements ||
    [...scrollParent.children].reduce(
      (map, item) => (item.dataset.name ? { [item.dataset.name]: item, ...map } : map),
      {}
    );

  let bestMatch = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const sectionName in targetElements) {
    if (Object.prototype.hasOwnProperty.call(targetElements, sectionName)) {
      const domElm = targetElements[sectionName];
      // check distance from top, takig scroll into account
      const delta = Math.abs(scrollParent.scrollTop - domElm.offsetTop);

      if (!bestMatch.sectionName) bestMatch = { sectionName, delta };

      // check which delet is closest to "0"
      if (delta < bestMatch.delta) {
        bestMatch = { sectionName, delta };
      }
    }
  }

  // update state with best-fit section
  return bestMatch.sectionName;
};

/**
 * Given a parent element ref, this render-props function returns
 * which of the parent's sections is currently scrolled into view
 * @param {Object} sectionsWrapperRef [Scrollable parent node React ref Object]
 */
const CurrentScrolledSection = ({ sectionsWrapperRef, children }) => {
  const [currentSection, setCurrentSection] = useState();
  const throttledOnScroll = _.throttle((e) => setCurrentSection(spyScroll(e.target)), 100);

  /* 
    adding the scroll event listener inside this component, and NOT the parent component, to 
    prevent re-rendering of the parent component when
    the scroll listener is fired and the state is updated, which causes noticable lag. 
  */
  useEffect(() => {
    const wrapperElm = sectionsWrapperRef.current;
    if (wrapperElm) {
      wrapperElm.addEventListener("scroll", throttledOnScroll);
      setCurrentSection(spyScroll(wrapperElm));
    }

    // unbind
    return () => wrapperElm.removeEventListener("scroll", throttledOnScroll);
  }, [sectionsWrapperRef, throttledOnScroll]);

  return children(currentSection);
};

function SideDrawerContenct({ isOpen, onClose, data, tenderState, industryTrades }) {
  const sectionsWrapperRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const { onDragMouseDown, dragState, limitDragRange } = useDragExpander({ min: -50, max: 200 });

  const { id_company } = useSelector((state) => ({
    id_company: state.usersData.authUser.data.authUser.id_company,
  }));
  // prepare DOM refs
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sectionsRefs = {};
  // eslint-disable-next-line react-hooks/rules-of-hooks,no-return-assign
  const SideSection = useCallback(
    ({ children, name, ...rest }) => (
      <section ref={sectionsRefs[name]} {...rest}>
        <div className="sideSectionContent">{children}</div>
      </section>
    ),
    [sectionsRefs]
  );

  return (
    <Drawer width={720} onClose={onClose} visible={isOpen} bodyStyle={{ paddingBottom: 80 }}>
      <div className="side_drawer vertical d-flex flex-column">
        <div className="side_header d-flex justify-between">
          <div className="side_header_left d-flex flex-column w-100">
            <div className="row d-flex justify-between">
              {data.prj_name[1] && <span className="project_id">{`ID ${data.prj_name[1]}`}</span>}
              {/* <span className="icon print">
                <FontAwesomeIcon icon={faPrint} />
                <label>print</label>
              </span> */}
            </div>
            <div className="row d-flex justify-end flex-column">
              {data.prj_name[0] && <span className="project_title">{data.prj_name[0]}</span>}
              <div className="name_section d-flex justify-between align-items-end">
                {data.prj_name[2] && (
                  <span className="w-70">
                    <span className="project_address w-60">{data.prj_name[2]}</span>
                    <a
                      href={`https://www.google.com/maps/place/${data.prj_name[2]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-sidebar ml-2">
                      View map
                    </a>
                  </span>
                )}
                {/* <CustomSelect onDragMouseDown={onDragMouseDown} mode="single" width="200px" /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="side_nav_container">
          <CurrentScrolledSection sectionsWrapperRef={sectionsWrapperRef}>
            {(currentSection) => (
              <>
                <div className="side_sub_header d-flex justify-between">
                  <div className="side_sub_item">
                    <span className="styles__label__25Uim">Tender Status</span>
                    <h3 style={{ textTransform: "capitalize" }}>{tenderState}</h3>
                  </div>
                  <div className="side_sub_item">
                    <span className="styles__label__25Uim">Distance</span>
                    <h3>{data.distance}</h3>
                  </div>
                  <div className="side_sub_item">
                    <span className="styles__label__25Uim">Max Budget</span>
                    {data.max_budget ? <h3>{`${data.max_budget}`}</h3> : <h3>Unknown</h3>}
                  </div>
                  <div className="side_sub_item">
                    <span className="styles__label__25Uim">Category</span>
                    <h3>{data.category}</h3>
                  </div>
                </div>
                <div style={{ padding: "0 2rem" }}>
                  <Divider className="sub-menu-divider">
                    <span className="description">Description</span>
                  </Divider>
                </div>
                <aside
                  className={`asideComp ${dragState.isDragging ? "isDragging" : ""}`}
                  style={{ "--delta": limitDragRange(dragState.delta) }}>
                  <div className="asideContent w-100" ref={sectionsWrapperRef}>
                    <SideSection
                      name="description"
                      data-name="description"
                      className={currentSection === "description" ? "active" : ""}>
                      <Description data={data} />
                    </SideSection>
                    <SideSection
                      name="builder_documents"
                      data-name="builder_documents"
                      className={currentSection === "builder_documents" ? "active" : ""}>
                      <Builder data={data} industryTrades={industryTrades} />
                    </SideSection>
                    <SideSection
                      name="trades"
                      data-name="trades"
                      className={currentSection === "trades" ? "active" : ""}>
                      <Trades builders={data.builders} industryTrades={industryTrades} />
                    </SideSection>
                    <SideSection
                      name="history"
                      data-name="history"
                      className={currentSection === "history" ? "active" : ""}>
                      <History id_tender={data.id_tender} />
                    </SideSection>
                    {id_company === data.id_company && (
                      <SideSection
                        name="Quotes"
                        data-name="quotes"
                        className={currentSection === "quotes" ? "active" : ""}>
                        <TenderQuotes id_project={data.id_project} />
                      </SideSection>
                    )}
                    <SideSection
                      name="notes"
                      data-name="notes"
                      className={currentSection === "notes" ? "active" : ""}>
                      <Notes />
                    </SideSection>
                  </div>
                </aside>
              </>
            )}
          </CurrentScrolledSection>
        </div>
      </div>
    </Drawer>
  );
}

SideDrawerContenct.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  industryTrades: PropTypes.array.isRequired,
  tenderState: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

SideDrawerContenct.defaultProps = {
  isOpen: false,
};

export default React.memo(SideDrawerContenct);
