import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

const PersonalInfo = ({ register, errors }) => (
  <>
    <div className="form-group row">
      <div className="col-lg-12">
        <label className="form-label" htmlFor="first_name">
          First name
        </label>
        <input
          type="text"
          className="form-control form-control-lg"
          id="first_name"
          name="first_name"
          placeholder="First name"
          ref={register({ required: "First name is required" })}
        />
        <div className="error-msg">{errors.first_name && errors.first_name.message}</div>
      </div>
      <div className="col-lg-12">
        <label className="form-label" htmlFor="last_name">
          Last name
        </label>
        <input
          type="text"
          className="form-control form-control-lg"
          id="last_name"
          name="last_name"
          placeholder="Last name"
          ref={register({ required: "Last name is required" })}
        />
        <div className="error-msg">{errors.last_name && errors.last_name.message}</div>
      </div>
      <div className="col-lg-12">
        <label className="form-label" htmlFor="email">
          Email
        </label>
        <input
          type="text"
          className="form-control form-control-lg"
          id="email"
          name="email"
          placeholder="acme@corp.com"
          ref={register({
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address",
            },
          })}
        />
        <div className="error-msg">{errors.email && errors.email.message}</div>
      </div>
    </div>
  </>
);

PersonalInfo.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

PersonalInfo.defaultProps = {
  errors: {},
};

export default PersonalInfo;
