import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { signIn, clearErrors } from "../../redux/action/users";

import "./style.scss";

function SignInBody({ signIn, isCreating, error }) {
  const [form] = Form.useForm();
  const history = useHistory();

  const dispatch = useDispatch();

  const handleSignIn = (values) => {
    if (!isCreating) {
      signIn(values)
        .then(() => {
          history.push("/dashboard");
        })
        .catch((err) => {
          const errorMessage = err?.data?.message || error;
          return message.error(errorMessage);
        });
    }
  };

  const handleButtonClick = () => form.submit();

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  return (
    <div className="form-container">
      <Form form={form} layout="vertical" className="form-wrapper" onFinish={handleSignIn}>
        <div className="form-items">
          <Form.Item required={true} label="E-mail" name="email">
            <Input placeholder="youremail@mail.com" />
          </Form.Item>
          <Form.Item
            required={true}
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}>
            <Input.Password placeholder="Type you password here" />
          </Form.Item>
        </div>
        <div className="signin-button">
          <Button disabled={isCreating} onClick={handleButtonClick} type="primary" htmlType="submit">
            Sign In
          </Button>
        </div>
      </Form>
    </div>
  );
}

SignInBody.propTypes = {
  isCreating: PropTypes.bool,
  signIn: PropTypes.func.isRequired,
  error: PropTypes.string,
};

SignInBody.defaultProps = {
  isCreating: false,
  error: "",
};

const mapStateToProps = (store) => ({
  isCreating: store.usersData.isCreating,
  error: store.usersData.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signIn,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignInBody);
