import React, { useState, useEffect } from "react";
import "./style.scss";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { SelectWatchList, fetchData } from "../../../../redux/action/tenders";
import CustomTable from "../../../../components/Elements/CustomTable";
import SideDrawerContenct from "../../../../components/Elements/SideDrawerContent";
import Loader from "../../../../components/Elements/Loading";

function AllTendersBody({ yourProjects }) {
  const {
    tenders,
    tenderBudgets,
    watchList,
    tenderState,
    industryTrades,
    status,
    filters,
    tendersSearch,
    companyInfo,
  } = useSelector((state) => ({
    tenders: state.tendersData.tenders,
    tenderBudgets: state.usersData.tenderBudgets,
    watchList: state.usersData.watchList,
    tenderState: state.tendersData.tenderState,
    industryTrades: state.usersData.industryTrades,
    status: state.tendersSearch.status,
    filters: state.tendersSearch.filters,
    tendersSearch: state.tendersSearch,
    companyInfo: state.usersData.companyInfo,
  }));
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [sideData, setSideData] = useState(null);
  const [loading, setLoading] = useState(true);

  const data = tenders.map((tender, index) => {
    console.log("tender", tender, tender.watch_list);
    const watchListSelection = Array.isArray(tender.watch_list)
      ? tender.watch_list.find((watchItem) => watchItem.id_company === companyInfo.id)
      : tender.watch_list;

    return {
      key: index,
      id_project: tender.id,
      id_tender: tender.id_tender,
      id_company: tender.id_company,
      prj_name: [tender.project_title, tender.id, tender.location],
      category: tender.id_project_category,
      project_title: tender.project_title,
      max_budget: tenderBudgets[tender.max_budget - 1] && tenderBudgets[tender.max_budget - 1].value,
      distance: tender.distance || 294,
      quoteDue: [
        {
          date_tender_close: moment(tender.quotes_due, "YYYYMMDD HH:mm:ss").add(1, "d").fromNow(), //fix tz
          date_due: moment(tender.quotes_due).format("dddd, MMMM Do YYYY"),
        },
      ],
      added: moment(tender.date_added).tz("Australia/Sydney").format("MMM Do 'YY"), //fix tz
      watchlist:
        watchListSelection instanceof Object ? watchListSelection.id_watchlist_type : watchListSelection,
      builders: tender.builders,
      companyDescription: tender.company_decription,
      description: tender.tender_description,
      notes: "",
    };
  });

  const onClickTableRow = (id) => {
    setSideData(data[id]);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    try {
      dispatch(fetchData(null, status, null, filters, yourProjects));
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, filters, status, yourProjects]);

  const handleSelectWatchList = (idProject, idWatch, idCompany) => {
    if (idWatch) {
      return dispatch(SelectWatchList(idProject, idWatch, idCompany));
    }
  };

  const locale = {
    emptyText: (
      <h6>
        <div className="mb-2 empty-text">It looks like you haven&apos;t yet created a project.</div>
        <Link to="/new-project">Click here to submit a RFT</Link>
      </h6>
    ),
  };

  return (
    <div className="all-tenders-body-container text-center mt-4">
      {!loading ? (
        <>
          <CustomTable
            data={data}
            locale={yourProjects && (!tendersSearch.status || tendersSearch.status === "open") ? locale : {}}
            onRowClick={onClickTableRow}
            watchList={watchList}
            SelectWatchList={handleSelectWatchList}
          />
          {sideData && (
            <SideDrawerContenct
              onClose={onClose}
              tenderState={tenderState}
              isOpen={isOpen}
              industryTrades={industryTrades}
              data={sideData}
            />
          )}
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </div>
  );
}

export default React.memo(AllTendersBody);
