import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";

const InputProjectName = ({ label, value, onChange, id }) => (
  <div className="form-group">
    <label className="form-label" htmlFor={id}>
      {label}
    </label>
    <div className="form-control-wrap">
      <Form.Item
        name="project-name"
        rules={[
          {
            required: true,
            message: "Please input project name",
          },
        ]}>
        <Input
          type="text"
          data-msg="Required"
          value={value}
          onChange={onChange}
          className="form-control required"
          id={id}
          placeholder={label}
        />
      </Form.Item>
    </div>
  </div>
);

InputProjectName.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default InputProjectName;
