import React from "react";
import { faTools, faUserHardHat, faTruckContainer } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MENU_TYPE = {
  LABEL: "label",
  MENU: "menu",
  BUTTON: "button",
};

export const aboutUs = [
  { title: "Our Team", url: "/ourteam" },
  { title: "Our Story", url: "/ourstory" },
  { title: "Our Careers", url: "/ourcarrers" },
];

export const ourProducts = [
  { title: "For Subbies", url: "/subbies" },
  { title: "For Suppliers", url: "/suppliers" },
  { title: "For Builders", url: "/builders" },
];

export const login = [{ title: "login", url: "/login" }];

export const signUp = [{ title: "signup", url: "/signup" }];

export const contact = [{ title: "contact", url: "/contact" }];

export const insight = [{ title: "insight", url: "/insight" }];

export const signUpUserrole = [
  {
    role: 1,
    label: "Builder",
    src: <FontAwesomeIcon icon={faUserHardHat} size="3x" />,
  },
  {
    role: 2,
    label: "Sub-Contractor",
    src: <FontAwesomeIcon icon={faTools} size="3x" />,
  },
  {
    role: 3,
    label: "Supplier",
    src: <FontAwesomeIcon icon={faTruckContainer} size="3x" />,
  },
];

export const multiOption = [
  {
    key: "key1",
    value: "Key1",
  },
  {
    key: "key2",
    value: "Key2",
  },
  {
    key: "key3",
    value: "Key3",
  },
  {
    key: "key4",
    value: "Key4",
  },
];

export const tableData = [
  {
    prj_name: [
      "Ithaca Creek State School - New General Learning Centre",
      "#87091",
      "Ithaca Creek State School 49 Lugg Street, Bardon QLD",
    ],
    max_budget: ["$5m", "$10m"],
    distance: 294,
    category: "Education",
    quotes_due: [
      { quote: "Kane", due: "30 Jul" },
      { quote: "Buildcorp", due: "30 Jul" },
      { quote: "Built", due: "30 Jul" },
      { quote: "OCA Const", due: "3 Aug" },
    ],
    added: "16 Jul 20",
    watchlist: "",
    note: "",
  },
  {
    prj_name: [
      "Ithaca Creek State School - New General Learning Centre",
      "#87091",
      "Ithaca Creek State School 49 Lugg Street, Bardon QLD",
    ],
    max_budget: ["$5m", "$10m"],
    distance: 294,
    category: "Education",
    quotes_due: [
      { quote: "Kane", due: "30 Jul" },
      { quote: "Buildcorp", due: "30 Jul" },
      { quote: "Built", due: "30 Jul" },
      { quote: "OCA Const", due: "3 Aug" },
    ],
    added: "16 Jul 20",
    watchlist: "",
    note: "",
  },
  {
    prj_name: [
      "Ithaca Creek State School - New General Learning Centre",
      "#87091",
      "Ithaca Creek State School 49 Lugg Street, Bardon QLD",
    ],
    max_budget: ["$5m", "$10m"],
    distance: 294,
    category: "Education",
    quotes_due: [
      { quote: "Kane", due: "30 Jul" },
      { quote: "Buildcorp", due: "30 Jul" },
      { quote: "Built", due: "30 Jul" },
      { quote: "OCA Const", due: "3 Aug" },
    ],
    added: "16 Jul 20",
    watchlist: "",
    note: "",
  },
  {
    prj_name: [
      "Ithaca Creek State School - New General Learning Centre",
      "#87091",
      "Ithaca Creek State School 49 Lugg Street, Bardon QLD",
    ],
    max_budget: ["$5m", "$10m"],
    distance: 294,
    category: "Education",
    quotes_due: [
      { quote: "Kane", due: "30 Jul" },
      { quote: "Buildcorp", due: "30 Jul" },
      { quote: "Built", due: "30 Jul" },
      { quote: "OCA Const", due: "3 Aug" },
    ],
    added: "16 Jul 20",
    watchlist: "",
    note: "",
  },
];

export const categories = [
  "Commercial",
  "Aged Care",
  "Education",
  "Fit-Out",
  "Government",
  "Mixed-Use",
  "Other",
  "Recreational",
  "Residential",
  "Retail",
];

export const loginSteps = {
  login: "Login",
  signUp: "SignUp",
  afterSignUp: "AfterSignUp",
  forgotPassword: "ForgotPassword",
  resetPassword: "ResetPassword",
  updatePassword: "UpdatePassword",
};
