import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";

import "./style.scss";

const CustomButtonSelect = ({ onChange, data, role }) => (
  <>
    <label className="form-label" htmlFor="name">
      I work as
    </label>
    <br />
    <Radio.Group className="form-group row flex-nowrap mb-3" defaultValue={role} onChange={onChange}>
      {data.map((dataItem) => (
        <Radio value={dataItem.role}>{dataItem.label}</Radio>
      ))}
    </Radio.Group>
  </>
);

CustomButtonSelect.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  role: PropTypes.string,
};

CustomButtonSelect.defaultProps = {
  data: [],
  onChange: () => {},
  role: "",
};

export default CustomButtonSelect;
