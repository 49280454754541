import React from "react";
import DirectoryBody from "./DirectoryBody";
import DirectoryHeader from "./DirectoryHeader";

import "../style.scss";

function Directory() {
  return (
    <div className="dashboard-content">
      <DirectoryHeader />
      <DirectoryBody />
    </div>
  );
}

export default Directory;
