import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ABNInput from "../Elements/ABNInput";
import LocationSearchInput from "../Elements/PlaceAutoComplete";

import "./style.scss";

const Builder = ({
  register,
  errors,
  abnerr,
  locationState,
  setAgree,
  selectAgree,
  onChangeState,
  setAbnErr,
  addressErr,
  setCompanyLocation,
  companyAddress,
}) => (
  <>
    <div className="form-group builder-form">
      <label className="form-label" htmlFor="company_name">
        Company name
      </label>
      <input
        type="text"
        className="form-control form-control-lg"
        id="company_name"
        name="company_name"
        placeholder="Company name"
        ref={register({ required: "Company is required" })}
      />
      <div className="error-msg">{errors.company_name && errors.company_name.message}</div>
    </div>
    <div className="form-group builder-form">
      <ABNInput errors={errors} register={register} abnerr={abnerr} setAbnErr={setAbnErr} />
      <div className="error-msg">{errors.phone && errors.phone.message}</div>
    </div>
    <div className="form-group">
      <label className="form-label" htmlFor="select_state">
        Select state
      </label>
      <select
        className="form-control  form-control-lg"
        name="id_state"
        id="select_state"
        onChange={onChangeState}>
        {locationState.map((item) => (
          <option key={item.key} value={item.key}>
            {item.value}
          </option>
        ))}
      </select>
    </div>
    <div className="form-group builder-form mt-2">
      <label className="form-label" htmlFor="location-search-input">
        Company Address
      </label>
      <LocationSearchInput
        addressErr={addressErr}
        setCompanyLocation={setCompanyLocation}
        companyAddress={companyAddress}
      />
    </div>
    <div className="form-group mt-2">
      <div className="custom-control custom-control-xs custom-checkbox">
        <input type="checkbox" className="custom-control-input" id="checkbox" onClick={setAgree} />
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <label className="custom-control-label" htmlFor="checkbox">
          I agree to TNDR.™
          <a tabIndex="-1" href="terms-policy.html" target="_blank" className="ml-1">
            Privacy Policy&nbsp;
          </a>
          &amp;
          <a tabIndex="-1" href="terms-policy.html" target="_blank">
            &nbsp;Terms.
          </a>
        </label>
      </div>
      {selectAgree && <div className="error-msg">Please agree with Policy and terms</div>}
    </div>
  </>
);

Builder.propTypes = {
  register: PropTypes.func.isRequired,
  setAgree: PropTypes.func.isRequired,
  selectAgree: PropTypes.bool.isRequired,
  onChangeState: PropTypes.func.isRequired,
  errors: PropTypes.object,
  locationState: PropTypes.array,
  abnerr: PropTypes.bool,
  setAbnErr: PropTypes.func.isRequired,
  addressErr: PropTypes.bool.isRequired,
  setCompanyLocation: PropTypes.func.isRequired,
  companyAddress: PropTypes.string.isRequired,
};

Builder.defaultProps = {
  errors: {},
  locationState: [],
  abnerr: false,
};

const mapStateToProps = (store) => ({
  locationState: store.usersData.locationState,
});

export default connect(mapStateToProps)(Builder);
