import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";
import { categories } from "../../../../../utils/static";

const NewProjectCategory = () => {
  const { category } = useSelector((state) => ({
    category: state.newProject.data.category,
  }));

  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(newProjectSlice.actions.setCategory(e.target.value));
  };

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="fw-nationality">
        Category
      </label>
      <div className="form-control-wrap ">
        <div className="form-control-select">
          <select className="form-control" onChange={onChange} value={category}>
            {categories.map((el) => (
              <option value={el} key={el}>
                {el}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default NewProjectCategory;
