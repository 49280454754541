import React from "react";
import SignupHeader from "../../../components/Signup/SignupHeader";
import SignUpBody from "./SignupBody";
import SignupFooter from "./SignupFooter";

function SignUpLeft() {
  return (
    <div className="auth-wrapper">
      <SignupHeader />
      <SignUpBody />
      <SignupFooter />
    </div>
  );
}

export default SignUpLeft;
