import React from "react";
import { Table, Button } from "antd";

import "./styles.scss";
import { useSelector } from "react-redux";

const dataSource = [
  {
    key: "1",
    licenseType: "Free Membership",
    price: "$0.00",
    billingCycle: "-",
    startDate: "Jun 1st 21",
    renewDate: "-",
    creditCard: "-",
  },
];

const columns = [
  {
    title: "Product",
    dataIndex: "licenseType",
    key: "licenseType",
  },
  {
    title: "Price (inc. GST)",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Billing Cycle",
    dataIndex: "billingCycle",
    key: "billingCycle",
  },
  {
    title: "Registration Date",
    dataIndex: "startDate",
    key: "startDate",
  },
  {
    title: "Auto Renew Date",
    dataIndex: "renewDate",
    key: "renewDate",
  },
  {
    title: "Credit Card",
    dataIndex: "creditCard",
    key: "creditCard",
  },
];

const Licenses = () => {
  const { companyInfo } = useSelector((state) => ({
    companyInfo: state.usersData.companyInfo,
  }));

  console.log({ companyInfo });

  return (
    <div className="profile-settings-wrapper">
      <div className="page-header">
        <div className="title">Licences</div>
      </div>
      <div className="licenses-table">
        <Table
          dataSource={[{ ...dataSource[0], startDate: new Date(companyInfo.created_date).toDateString() }]}
          columns={columns}
          pagination={false}
        />
      </div>
      <div className="upgrade-button">
        <Button disabled type="primary">
          Upgrade Membership
        </Button>
      </div>
    </div>
  );
};

export default Licenses;
