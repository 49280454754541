import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Select, Table } from "antd";
import { SettingOutlined, DownloadOutlined, EyeOutlined, DownOutlined } from "@ant-design/icons";

import { setFavorite } from "../../../redux/action/directory";

import "./style.scss";

const { Option } = Select;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id) => <span className="table_id">{id}</span>,
  },
  {
    title: "Project Name",
    dataIndex: "project",
    key: "project",
    render: (project) => (
      <div>
        <span>{project}</span>
      </div>
    ),
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (invited) => (
      <div>
        <span>{invited}</span>
      </div>
    ),
  },
  {
    title: "Tender Due",
    key: "date_tender_close",
    dataIndex: "date_tender_close",
    render: (date_tender_close) => (
      <span className="table_quotes">
        <span className="quote_item">
          <span>{date_tender_close}</span>
        </span>
      </span>
    ),
  },
  {
    title: "Location",
    key: "location",
    dataIndex: "location",
    render: (location) => (
      <span className="table_location">
        <span>{location}</span>
      </span>
    ),
  },
  {
    title: "Distance",
    key: "distance",
    dataIndex: "distance",
    render: (distance) => (
      <div className="table_quotes">
        <span>{`${distance}`}</span>
      </div>
    ),
  },

  {
    title: "Interest",
    key: "interest",
    dataIndex: "interest",
    render: (valueSet) => (
      <Select
        className="table_watchlist_select"
        placeholder="Uncategorised..."
        style={{ width: 150 }}
        value={valueSet.value}
        onChange={valueSet.onChange}
        onClick={valueSet.onClick}>
        {valueSet.data.map((selectItem) => (
          <Option key={selectItem.key} value={selectItem.key} disabled={selectItem.key === 0}>
            {selectItem.value}
          </Option>
        ))}
      </Select>
    ),
  },
];

function WatchingListTable({ data }) {
  const onResponseClick = (e) => {
    e.stopPropagation();
  };

  const tableData = data.map((dataItem, index) => ({
    key: `table-${index}`,
    id: dataItem.id,
    project: dataItem.project,
    category: dataItem.category,
    location: dataItem.location,
    distance: dataItem.distance,
    date_tender_close: new Date(dataItem.date_tender_close).toLocaleString(),
    interest: dataItem.interest,
  }));

  return (
    <div className="watchlist-table-wrapper">
      <Table
        // rowSelection={{
        //   type: "checkbox",
        //   hideSelectAll: true,
        // }}
        columns={columns}
        width={100}
        dataSource={tableData}
        pagination={false}
      />
    </div>
  );
}

WatchingListTable.propTypes = {
  data: PropTypes.array,
};

WatchingListTable.defaultProps = {
  data: [],
};

const mapStateToProps = (store) => ({
  isUpdatingFlag: store.directoryData.isUpdatingFlag,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFavorite,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WatchingListTable);
