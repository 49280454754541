import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { newProjectSlice } from "../../../../../redux/newProject/slice";

const NewProjectClassification = () => {
  const { tenderClassifications, projectClassification } = useSelector((state) => ({
    tenderClassifications: state.usersData.tenderClassifications,
    projectClassification: state.newProject.data.projectClassification,
  }));
  const dispatch = useDispatch();

  const onChange = (eventValue) => {
    dispatch(newProjectSlice.actions.setProjectClassification(eventValue));
  };

  console.log({
    tenderClassifications,
    projectClassification,
  });

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="fw-nationality">
        Classification
      </label>
      <div className="form-control-wrap ">
        <div className="form-control-select">
          <Select
            className="table_watchlist"
            placeholder="Please select"
            style={{ width: "100%" }}
            value={projectClassification}
            onChange={onChange}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            {tenderClassifications.map((selectItem) => (
              <Select.Option key={selectItem.key} value={selectItem.key} disabled={selectItem.key === 0}>
                {selectItem.value}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default NewProjectClassification;
