import React, { useState } from "react";
import PropTypes from "prop-types";

import abnService from "../../../services/abn.service";

const ABNInput = ({ errors, register, abnerr, setAbnErr }) => {
  const [inputError, setInputError] = useState(null);
  const onChange = async (e) => {
    const abnValue = e?.target?.value?.replace(/\s/g, "");

    if (!/^\d*$/.test(abnValue)) {
      setInputError("Please insert only digits.");
      return;
    }

    if (abnValue.length === 11) {
      const abnData = await abnService.fetch(abnValue);

      console.log("abnData", abnData);
      const isABNValid = abnData?.Abn !== "";
      if (!isABNValid) {
        setInputError("ABN is invalid");
        return;
      }

      const isABNActive = abnData?.AbnStatus === "Active";
      if (abnData?.AbnStatus && !isABNActive) {
        setInputError(`ABN is ${abnData.AbnStatus.toLowerCase()}`);
        return;
      }
    }

    setAbnErr(abnValue);
    setInputError(null);
  };
  return (
    <>
      <label className="form-label" htmlFor="phone">
        ABN
      </label>
      <input
        className="form-control form-control-lg"
        id="abn"
        name="abn"
        onChange={onChange}
        placeholder="ABN"
        ref={register({
          required: true,
        })}
      />
      {inputError ? (
        <div className="error-msg">{inputError}</div>
      ) : (
        <div className="error-msg">
          {errors.abn && errors.abn.type === "required" && "ABN is required"}
          {!errors.abn && abnerr && "Please ensure you enter a valid ABN"}
        </div>
      )}
    </>
  );
};

ABNInput.propTypes = {
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  abnerr: PropTypes.bool.isRequired,
  setAbnErr: PropTypes.func.isRequired,
};

ABNInput.defaultProps = {
  errors: {},
};

export default ABNInput;
