import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchOptionInfo } from "../../../redux/action/users";
import NewProjectWizard from "./NewProjectWizard";
import "./index.scss";

const baseClass = "new-project";

const NewProject = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOptionInfo());
  }, [dispatch]);

  return (
    <div className={baseClass} id={baseClass}>
      <div className={`${baseClass}__header`}>
        <div className={`${baseClass}__header-wrapper`}>
          <span className="nk-block-title page-title">New Project Wizard</span>
        </div>
      </div>
      <NewProjectWizard />
    </div>
  );
};

export default NewProject;
