import React, { useState } from "react";
import { useSelector } from "react-redux";
import FontAwesomePro from "../../FontAwesomePro";
import FirstNameChangeModal from "./FirstNameChangeModal";
import LastNameChangeModal from "./LastNameChangeModal";
import EmailChangeModal from "./EmailChangeModal";
import PhoneChangeModal from "./PhoneChangeModal";

import "./styles.scss";

const getListElement = (label, value, onClickAction = () => {}) => (
  <div className="settings-line">
    <div className="data-col">
      <div className="data-label">{label}</div>
      <div className="data-value">{value}</div>
    </div>
    <div className="settings-button" onClick={onClickAction}>
      <FontAwesomePro iconName="fal chevron-right" />
    </div>
  </div>
);

const ProfileSettings = () => {
  const userData = useSelector((state) => state.usersData.authUser.data.authUser);
  const { first_name, last_name, email, phone } = userData;

  const [isFirstNameModalShown, setIsFirstNameModalShown] = useState(false);
  const [isLastNameModalShown, setIsLastNameModalShown] = useState(false);
  const [isEmailModalShown, setIsEmailModalShown] = useState(false);
  const [isPhoneModalShown, setIsPhoneModalShown] = useState(false);

  const handleFirstNameButtonClick = () => setIsFirstNameModalShown(true);
  const handleLastNameButtonClick = () => setIsLastNameModalShown(true);
  const handleEmailButtonClick = () => setIsEmailModalShown(true);
  const handlePhoneButtonClick = () => setIsPhoneModalShown(true);

  return (
    <div className="profile-settings-wrapper">
      <div className="page-header">
        <div className="title">Personal Settings</div>
        <div className="subtitle">Your information on our platform</div>
      </div>
      <div className="settings-wrapper">
        {getListElement("First Name", first_name, handleFirstNameButtonClick)}
        {getListElement("Last Name", last_name, handleLastNameButtonClick)}
        {getListElement("Email", email, handleEmailButtonClick)}
        {getListElement("Phone", phone, handlePhoneButtonClick)}
      </div>
      {isFirstNameModalShown && (
        <FirstNameChangeModal visible={isFirstNameModalShown} changeVisible={setIsFirstNameModalShown} />
      )}
      {isLastNameModalShown && (
        <LastNameChangeModal visible={isLastNameModalShown} changeVisible={setIsLastNameModalShown} />
      )}
      {isEmailModalShown && (
        <EmailChangeModal visible={isEmailModalShown} changeVisible={setIsEmailModalShown} />
      )}
      {isPhoneModalShown && (
        <PhoneChangeModal visible={isPhoneModalShown} changeVisible={setIsPhoneModalShown} />
      )}
    </div>
  );
};

export default ProfileSettings;
