import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";

const NewProjectBudget = () => {
  const { tenderBudgets, budget } = useSelector((state) => ({
    tenderBudgets: state.usersData.tenderBudgets,
    budget: state.newProject.data.budget,
  }));
  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(newProjectSlice.actions.setBudget(e.target.value));
  };

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="fw-nationality">
        Budget
      </label>
      <div className="form-control-wrap ">
        <div className="form-control-select">
          <select className="form-control" onChange={onChange} value={budget}>
            {tenderBudgets.map((el) => (
              <option value={el.key} key={el.key}>
                {el.value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default NewProjectBudget;
