export default [
  {
    en: "Afghanistan",
    short: "AF",
    emoji: "🇦🇫",
    phoneCode: "+93",
  },
  {
    en: "Albania",
    short: "AL",
    emoji: "🇦🇱",
    phoneCode: "+355",
  },
  {
    en: "Algeria",
    short: "DZ",
    emoji: "🇩🇿",
    phoneCode: "+213",
  },
  {
    en: "American Samoa",
    short: "AS",
    emoji: "🇦🇸",
    phoneCode: "+1684",
  },
  {
    en: "Andorra",
    short: "AD",
    emoji: "🇦🇩",
    phoneCode: "+376",
  },
  {
    en: "Angola",
    short: "AO",
    emoji: "🇦🇴",
    phoneCode: "+244",
  },
  {
    en: "Anguilla",
    short: "AI",
    emoji: "🇦🇮",
    phoneCode: "+1264",
  },
  {
    en: "Antigua and Barbuda",
    short: "AG",
    emoji: "🇦🇬",
    phoneCode: "+1268",
  },
  {
    en: "Argentina",
    short: "AR",
    emoji: "🇦🇷",
    phoneCode: "+54",
  },
  {
    en: "Armenia",
    short: "AM",
    emoji: "🇦🇲",
    phoneCode: "+374",
  },
  {
    en: "Aruba",
    short: "AW",
    emoji: "🇦🇼",
    phoneCode: "+297",
  },
  {
    en: "Australia",
    short: "AU",
    emoji: "🇦🇺",
    phoneCode: "+61",
  },
  {
    en: "Austria",
    short: "AT",
    emoji: "🇦🇹",
    phoneCode: "+43",
  },
  {
    en: "Azerbaijan",
    short: "AZ",
    emoji: "🇦🇿",
    phoneCode: "+994",
  },
  {
    en: "Bahamas",
    short: "BS",
    emoji: "🇧🇸",
    phoneCode: "+1242",
  },
  {
    en: "Bahrain",
    short: "BH",
    emoji: "🇧🇭",
    phoneCode: "+973",
  },
  {
    en: "Bangladesh",
    short: "BD",
    emoji: "🇧🇩",
    phoneCode: "+880",
  },
  {
    en: "Barbados",
    short: "BB",
    emoji: "🇧🇧",
    phoneCode: "+1246",
  },
  {
    en: "Belarus",
    short: "BY",
    emoji: "🇧🇾",
    phoneCode: "+375",
  },
  {
    en: "Belgium",
    short: "BE",
    emoji: "🇧🇪",
    phoneCode: "+32",
  },
  {
    en: "Belize",
    short: "BZ",
    emoji: "🇧🇿",
    phoneCode: "+501",
  },
  {
    en: "Benin",
    short: "BJ",
    emoji: "🇧🇯",
    phoneCode: "+229",
  },
  {
    en: "Bermuda",
    short: "BM",
    emoji: "🇧🇲",
    phoneCode: "+1441",
  },
  {
    en: "Bhutan",
    short: "BT",
    emoji: "🇧🇹",
    phoneCode: "+975",
  },
  {
    en: "Bolivia",
    short: "BO",
    emoji: "🇧🇴",
    phoneCode: "+591",
  },
  {
    en: "Bosnia and Herzegovina",
    short: "BA",
    emoji: "🇧🇦",
    phoneCode: "+387",
  },
  {
    en: "Botswana",
    short: "BW",
    emoji: "🇧🇼",
    phoneCode: "+267",
  },
  {
    en: "Brazil",
    short: "BR",
    emoji: "🇧🇷",
    phoneCode: "+55",
  },
  {
    en: "Brunei",
    short: "BN",
    emoji: "🇧🇳",
    phoneCode: "+673",
  },
  {
    en: "Bulgaria",
    short: "BG",
    emoji: "🇧🇬",
    phoneCode: "+359",
  },
  {
    en: "Burkina Faso",
    short: "BF",
    emoji: "🇧🇫",
    phoneCode: "+226",
  },
  {
    en: "Burundi",
    short: "BI",
    emoji: "🇧🇮",
    phoneCode: "+257",
  },
  {
    en: "Cambodia",
    short: "KH",
    emoji: "🇰🇭",
    phoneCode: "+855",
  },
  {
    en: "Cameroon",
    short: "CM",
    emoji: "🇨🇲",
    phoneCode: "+237",
  },
  {
    en: "Canada",
    short: "CA",
    emoji: "🇨🇦",
    phoneCode: "+1",
  },
  {
    en: "Cape Verde",
    short: "CV",
    emoji: "🇨🇻",
    phoneCode: "+238",
  },
  {
    en: "Cayman Islands",
    short: "KY",
    emoji: "🇰🇾",
    phoneCode: "+1345",
  },
  {
    en: "Central African Republic",
    short: "CF",
    emoji: "🇨🇫",
    phoneCode: "+236",
  },
  {
    en: "Chad",
    short: "TD",
    emoji: "🇹🇩",
    phoneCode: "+235",
  },
  {
    en: "Chile",
    short: "CL",
    emoji: "🇨🇱",
    phoneCode: "+56",
  },
  {
    en: "China",
    short: "CN",
    emoji: "🇨🇳",
    phoneCode: "+86",
  },
  {
    en: "Colombia",
    short: "CO",
    emoji: "🇨🇴",
    phoneCode: "+57",
  },
  {
    en: "Comoros",
    short: "KM",
    emoji: "🇰🇲",
    phoneCode: "+269",
  },
  {
    en: "Cook Islands",
    short: "CK",
    emoji: "🇨🇰",
    phoneCode: "+682",
  },
  {
    en: "Costa Rica",
    short: "CR",
    emoji: "🇨🇷",
    phoneCode: "+506",
  },
  {
    en: "Croatia",
    short: "HR",
    emoji: "🇭🇷",
    phoneCode: "+385",
  },
  {
    en: "Cuba",
    short: "CU",
    emoji: "🇨🇺",
    phoneCode: "+53",
  },
  {
    en: "Curacao",
    short: "CW",
    emoji: "🇨🇼",
    phoneCode: "+599",
  },
  {
    en: "Cyprus",
    short: "CY",
    emoji: "🇨🇾",
    phoneCode: "+357",
  },
  {
    en: "Czech",
    short: "CZ",
    emoji: "🇨🇿",
    phoneCode: "+420",
  },
  {
    en: "Democratic Republic of the Congo",
    short: "CD",
    emoji: "🇨🇩",
    phoneCode: "+243",
  },
  {
    en: "Denmark",
    short: "DK",
    emoji: "🇩🇰",
    phoneCode: "+45",
  },
  {
    en: "Djibouti",
    short: "DJ",
    emoji: "🇩🇯",
    phoneCode: "+253",
  },
  {
    en: "Dominica",
    short: "DM",
    emoji: "🇩🇲",
    phoneCode: "+1767",
  },
  {
    en: "Dominican Republic",
    short: "DO",
    emoji: "🇩🇴",
    phoneCode: "+1809",
  },
  {
    en: "Ecuador",
    short: "EC",
    emoji: "🇪🇨",
    phoneCode: "+593",
  },
  {
    en: "Egypt",
    short: "EG",
    emoji: "🇪🇬",
    phoneCode: "+20",
  },
  {
    en: "El Salvador",
    short: "SV",
    emoji: "🇸🇻",
    phoneCode: "+503",
  },
  {
    en: "Equatorial Guinea",
    short: "GQ",
    emoji: "🇬🇶",
    phoneCode: "+240",
  },
  {
    en: "Eritrea",
    short: "ER",
    emoji: "🇪🇷",
    phoneCode: "+291",
  },
  {
    en: "Estonia",
    short: "EE",
    emoji: "🇪🇪",
    phoneCode: "+372",
  },
  {
    en: "Ethiopia",
    short: "ET",
    emoji: "🇪🇹",
    phoneCode: "+251",
  },
  {
    en: "Faroe Islands",
    short: "FO",
    emoji: "🇫🇴",
    phoneCode: "+298",
  },
  {
    en: "Fiji",
    short: "FJ",
    emoji: "🇫🇯",
    phoneCode: "+679",
  },
  {
    en: "Finland",
    short: "FI",
    emoji: "🇫🇮",
    phoneCode: "+358",
  },
  {
    en: "France",
    short: "FR",
    emoji: "🇫🇷",
    phoneCode: "+33",
  },
  {
    en: "French Guiana",
    short: "GF",
    emoji: "🇬🇫",
    phoneCode: "+594",
  },
  {
    en: "French Polynesia",
    short: "PF",
    emoji: "🇵🇫",
    phoneCode: "+689",
  },
  {
    en: "Gabon",
    short: "GA",
    emoji: "🇬🇦",
    phoneCode: "+241",
  },
  {
    en: "Gambia",
    short: "GM",
    emoji: "🇬🇲",
    phoneCode: "+220",
  },
  {
    en: "Georgia",
    short: "GE",
    emoji: "🇬🇪",
    phoneCode: "+995",
  },
  {
    en: "Germany",
    short: "DE",
    emoji: "🇩🇪",
    phoneCode: "+49",
  },
  {
    en: "Ghana",
    short: "GH",
    emoji: "🇬🇭",
    phoneCode: "+233",
  },
  {
    en: "Gibraltar",
    short: "GI",
    emoji: "🇬🇮",
    phoneCode: "+350",
  },
  {
    en: "Greece",
    short: "GR",
    emoji: "🇬🇷",
    phoneCode: "+30",
  },
  {
    en: "Greenland",
    short: "GL",
    emoji: "🇬🇱",
    phoneCode: "+299",
  },
  {
    en: "Grenada",
    short: "GD",
    emoji: "🇬🇩",
    phoneCode: "+1473",
  },
  {
    en: "Guadeloupe",
    short: "GP",
    emoji: "🇬🇵",
    phoneCode: "+590",
  },
  {
    en: "Guam",
    short: "GU",
    emoji: "🇬🇺",
    phoneCode: "+1671",
  },
  {
    en: "Guatemala",
    short: "GT",
    emoji: "🇬🇹",
    phoneCode: "+502",
  },
  {
    en: "Guinea",
    short: "GN",
    emoji: "🇬🇳",
    phoneCode: "+224",
  },
  {
    en: "Guinea-Bissau",
    short: "GW",
    emoji: "🇬🇼",
    phoneCode: "+245",
  },
  {
    en: "Guyana",
    short: "GY",
    emoji: "🇬🇾",
    phoneCode: "+592",
  },
  {
    en: "Haiti",
    short: "HT",
    emoji: "🇭🇹",
    phoneCode: "+509",
  },
  {
    en: "Honduras",
    short: "HN",
    emoji: "🇭🇳",
    phoneCode: "+504",
  },
  {
    en: "Hong Kong",
    short: "HK",
    emoji: "🇭🇰",
    phoneCode: "+852",
  },
  {
    en: "Hungary",
    short: "HU",
    emoji: "🇭🇺",
    phoneCode: "+36",
  },
  {
    en: "Iceland",
    short: "IS",
    emoji: "🇮🇸",
    phoneCode: "+354",
  },
  {
    en: "India",
    short: "IN",
    emoji: "🇮🇳",
    phoneCode: "+91",
  },
  {
    en: "Indonesia",
    short: "ID",
    emoji: "🇮🇩",
    phoneCode: "+62",
  },
  {
    en: "Iran",
    short: "IR",
    emoji: "🇮🇷",
    phoneCode: "+98",
  },
  {
    en: "Iraq",
    short: "IQ",
    emoji: "🇮🇶",
    phoneCode: "+964",
  },
  {
    en: "Ireland",
    short: "IE",
    emoji: "🇮🇪",
    phoneCode: "+353",
  },
  {
    en: "Israel",
    short: "IL",
    emoji: "🇮🇱",
    phoneCode: "+972",
  },
  {
    en: "Italy",
    short: "IT",
    emoji: "🇮🇹",
    phoneCode: "+39",
  },
  {
    en: "Ivory Coast",
    short: "CI",
    emoji: "🇨🇮",
    phoneCode: "+225",
  },
  {
    en: "Jamaica",
    short: "JM",
    emoji: "🇯🇲",
    phoneCode: "+1876",
  },
  {
    en: "Japan",
    short: "JP",
    emoji: "🇯🇵",
    phoneCode: "+81",
  },
  {
    en: "Jordan",
    short: "JO",
    emoji: "🇯🇴",
    phoneCode: "+962",
  },
  {
    en: "Kazakhstan",
    short: "KZ",
    emoji: "🇰🇿",
    phoneCode: "+7",
  },
  {
    en: "Kenya",
    short: "KE",
    emoji: "🇰🇪",
    phoneCode: "+254",
  },
  {
    en: "Kiribati",
    short: "KI",
    emoji: "🇰🇮",
    phoneCode: "+686",
  },
  {
    en: "Kuwait",
    short: "KW",
    emoji: "🇰🇼",
    phoneCode: "+965",
  },
  {
    en: "Kyrgyzstan",
    short: "KG",
    emoji: "🇰🇬",
    phoneCode: "+996",
  },
  {
    en: "Laos",
    short: "LA",
    emoji: "🇱🇦",
    phoneCode: "+856",
  },
  {
    en: "Latvia",
    short: "LV",
    emoji: "🇱🇻",
    phoneCode: "+371",
  },
  {
    en: "Lebanon",
    short: "LB",
    emoji: "🇱🇧",
    phoneCode: "+961",
  },
  {
    en: "Lesotho",
    short: "LS",
    emoji: "🇱🇸",
    phoneCode: "+266",
  },
  {
    en: "Liberia",
    short: "LR",
    emoji: "🇱🇷",
    phoneCode: "+231",
  },
  {
    en: "Libya",
    short: "LY",
    emoji: "🇱🇾",
    phoneCode: "+218",
  },
  {
    en: "Liechtenstein",
    short: "LI",
    emoji: "🇱🇮",
    phoneCode: "+423",
  },
  {
    en: "Lithuania",
    short: "LT",
    emoji: "🇱🇹",
    phoneCode: "+370",
  },
  {
    en: "Luxembourg",
    short: "LU",
    emoji: "🇱🇺",
    phoneCode: "+352",
  },
  {
    en: "Macau",
    short: "MO",
    emoji: "🇲🇴",
    phoneCode: "+853",
  },
  {
    en: "Macedonia",
    short: "MK",
    emoji: "🇲🇰",
    phoneCode: "+389",
  },
  {
    en: "Madagascar",
    short: "MG",
    emoji: "🇲🇬",
    phoneCode: "+261",
  },
  {
    en: "Malawi",
    short: "MW",
    emoji: "🇲🇼",
    phoneCode: "+265",
  },
  {
    en: "Malaysia",
    short: "MY",
    emoji: "🇲🇾",
    phoneCode: "+60",
  },
  {
    en: "Maldives",
    short: "MV",
    emoji: "🇲🇻",
    phoneCode: "+960",
  },
  {
    en: "Mali",
    short: "ML",
    emoji: "🇲🇱",
    phoneCode: "+223",
  },
  {
    en: "Malta",
    short: "MT",
    emoji: "🇲🇹",
    phoneCode: "+356",
  },
  {
    en: "Martinique",
    short: "MQ",
    emoji: "🇲🇶",
    phoneCode: "+596",
  },
  {
    en: "Mauritania",
    short: "MR",
    emoji: "🇲🇷",
    phoneCode: "+222",
  },
  {
    en: "Mauritius",
    short: "MU",
    emoji: "🇲🇺",
    phoneCode: "+230",
  },
  {
    en: "Mayotte",
    short: "YT",
    emoji: "🇾🇹",
    phoneCode: "+269",
  },
  {
    en: "Mexico",
    short: "MX",
    emoji: "🇲🇽",
    phoneCode: "+52",
  },
  {
    en: "Moldova",
    short: "MD",
    emoji: "🇲🇩",
    phoneCode: "+373",
  },
  {
    en: "Monaco",
    short: "MC",
    emoji: "🇲🇨",
    phoneCode: "+377",
  },
  {
    en: "Mongolia",
    short: "MN",
    emoji: "🇲🇳",
    phoneCode: "+976",
  },
  {
    en: "Montenegro",
    short: "ME",
    emoji: "🇲🇪",
    phoneCode: "+382",
  },
  {
    en: "Montserrat",
    short: "MS",
    emoji: "🇲🇸",
    phoneCode: "+1664",
  },
  {
    en: "Morocco",
    short: "MA",
    emoji: "🇲🇦",
    phoneCode: "+212",
  },
  {
    en: "Mozambique",
    short: "MZ",
    emoji: "🇲🇿",
    phoneCode: "+258",
  },
  {
    en: "Myanmar",
    short: "MM",
    emoji: "🇲🇲",
    phoneCode: "+95",
  },
  {
    en: "Namibia",
    short: "NA",
    emoji: "🇳🇦",
    phoneCode: "+264",
  },
  {
    en: "Nepal",
    short: "NP",
    emoji: "🇳🇵",
    phoneCode: "+977",
  },
  {
    en: "Netherlands",
    short: "NL",
    emoji: "🇳🇱",
    phoneCode: "+31",
  },
  {
    en: "New Caledonia",
    short: "NC",
    emoji: "🇳🇨",
    phoneCode: "+687",
  },
  {
    en: "New Zealand",
    short: "NZ",
    emoji: "🇳🇿",
    phoneCode: "+64",
  },
  {
    en: "Nicaragua",
    short: "NI",
    emoji: "🇳🇮",
    phoneCode: "+505",
  },
  {
    en: "Niger",
    short: "NE",
    emoji: "🇳🇪",
    phoneCode: "+227",
  },
  {
    en: "Nigeria",
    short: "NG",
    emoji: "🇳🇬",
    phoneCode: "+234",
  },
  {
    en: "Norway",
    short: "NO",
    emoji: "🇳🇴",
    phoneCode: "+47",
  },
  {
    en: "Oman",
    short: "OM",
    emoji: "🇴🇲",
    phoneCode: "+968",
  },
  {
    en: "Pakistan",
    short: "PK",
    emoji: "🇵🇰",
    phoneCode: "+92",
  },
  {
    en: "Palau",
    short: "PW",
    emoji: "🇵🇼",
    phoneCode: "+680",
  },
  {
    en: "Palestine",
    short: "BL",
    emoji: "🇵🇸",
    phoneCode: "+970",
  },
  {
    en: "Panama",
    short: "PA",
    emoji: "🇵🇦",
    phoneCode: "+507",
  },
  {
    en: "Papua New Guinea",
    short: "PG",
    emoji: "🇵🇬",
    phoneCode: "+675",
  },
  {
    en: "Paraguay",
    short: "PY",
    emoji: "🇵🇾",
    phoneCode: "+595",
  },
  {
    en: "Peru",
    short: "PE",
    emoji: "🇵🇪",
    phoneCode: "+51",
  },
  {
    en: "Philippines",
    short: "PH",
    emoji: "🇵🇭",
    phoneCode: "+63",
  },
  {
    en: "Poland",
    short: "PL",
    emoji: "🇵🇱",
    phoneCode: "+48",
  },
  {
    en: "Portugal",
    short: "PT",
    emoji: "🇵🇹",
    phoneCode: "+351",
  },
  {
    en: "Puerto Rico",
    short: "PR",
    emoji: "🇵🇷",
    phoneCode: "+1787",
  },
  {
    en: "Qatar",
    short: "QA",
    emoji: "🇶🇦",
    phoneCode: "+974",
  },
  {
    en: "Republic Of The Congo",
    short: "CG",
    emoji: "🇨🇬",
    phoneCode: "+242",
  },
  {
    en: "Romania",
    short: "RO",
    emoji: "🇷🇴",
    phoneCode: "+40",
  },
  {
    en: "Russia",
    short: "RU",
    emoji: "🇷🇺",
    phoneCode: "+7",
  },
  {
    en: "Rwanda",
    short: "RW",
    emoji: "🇷🇼",
    phoneCode: "+250",
  },
  {
    en: "Réunion Island",
    short: "RE",
    emoji: "🇷🇪",
    phoneCode: "+262",
  },
  {
    en: "Saint Kitts and Nevis",
    short: "KN",
    emoji: "🇰🇳",
    phoneCode: "+1869",
  },
  {
    en: "Saint Lucia",
    short: "LC",
    emoji: "🇱🇨",
    phoneCode: "+1758",
  },
  {
    en: "Saint Maarten (Dutch Part)",
    short: "SX",
    emoji: "🇳🇱",
    phoneCode: "+1721",
  },
  {
    en: "Saint Pierre and Miquelon",
    short: "PM",
    emoji: "🇵🇲",
    phoneCode: "+508",
  },
  {
    en: "Saint Vincent and The Grenadines",
    short: "VC",
    emoji: "🇻🇨",
    phoneCode: "+1784",
  },
  {
    en: "Samoa",
    short: "WS",
    emoji: "🇼🇸",
    phoneCode: "+685",
  },
  {
    en: "San Marino",
    short: "SM",
    emoji: "🇸🇲",
    phoneCode: "+378",
  },
  {
    en: "Sao Tome and Principe",
    short: "ST",
    emoji: "🇸🇹",
    phoneCode: "+239",
  },
  {
    en: "Saudi Arabia",
    short: "SA",
    emoji: "🇸🇦",
    phoneCode: "+966",
  },
  {
    en: "Senegal",
    short: "SN",
    emoji: "🇸🇳",
    phoneCode: "+221",
  },
  {
    en: "Serbia",
    short: "RS",
    emoji: "🇷🇸",
    phoneCode: "+381",
  },
  {
    en: "Seychelles",
    short: "SC",
    emoji: "🇸🇨",
    phoneCode: "+248",
  },
  {
    en: "Sierra Leone",
    short: "SL",
    emoji: "🇸🇱",
    phoneCode: "+232",
  },
  {
    en: "Singapore",
    short: "SG",
    emoji: "🇸🇬",
    phoneCode: "+65",
  },
  {
    en: "Slovakia",
    short: "SK",
    emoji: "🇸🇰",
    phoneCode: "+421",
  },
  {
    en: "Slovenia",
    short: "SI",
    emoji: "🇸🇮",
    phoneCode: "+386",
  },
  {
    en: "Solomon Islands",
    short: "SB",
    emoji: "🇸🇧",
    phoneCode: "+677",
  },
  {
    en: "Somalia",
    short: "SO",
    emoji: "🇸🇴",
    phoneCode: "+252",
  },
  {
    en: "South Africa",
    short: "ZA",
    emoji: "🇿🇦",
    phoneCode: "+27",
  },
  {
    en: "South Korea",
    short: "KR",
    emoji: "🇰🇷",
    phoneCode: "+82",
  },
  {
    en: "Spain",
    short: "ES",
    emoji: "🇪🇸",
    phoneCode: "+34",
  },
  {
    en: "Sri Lanka",
    short: "LK",
    emoji: "🇱🇰",
    phoneCode: "+94",
  },
  {
    en: "Sudan",
    short: "SD",
    emoji: "🇸🇩",
    phoneCode: "+249",
  },
  {
    en: "Suriname",
    short: "SR",
    emoji: "🇸🇷",
    phoneCode: "+597",
  },
  {
    en: "Swaziland",
    short: "SZ",
    emoji: "🇸🇿",
    phoneCode: "+268",
  },
  {
    en: "Sweden",
    short: "SE",
    emoji: "🇸🇪",
    phoneCode: "+46",
  },
  {
    en: "Switzerland",
    short: "CH",
    emoji: "🇨🇭",
    phoneCode: "+41",
  },
  {
    en: "Syria",
    short: "SY",
    emoji: "🇸🇾",
    phoneCode: "+963",
  },
  {
    en: "Taiwan",
    short: "TW",
    emoji: "🇨🇳",
    phoneCode: "+886",
  },
  {
    en: "Tajikistan",
    short: "TJ",
    emoji: "🇹🇯",
    phoneCode: "+992",
  },
  {
    en: "Tanzania",
    short: "TZ",
    emoji: "🇹🇿",
    phoneCode: "+255",
  },
  {
    en: "Thailand",
    short: "TH",
    emoji: "🇹🇭",
    phoneCode: "+66",
  },
  {
    en: "Timor-Leste",
    short: "TL",
    emoji: "🇹🇱",
    phoneCode: "+670",
  },
  {
    en: "Togo",
    short: "TG",
    emoji: "🇹🇬",
    phoneCode: "+228",
  },
  {
    en: "Tonga",
    short: "TO",
    emoji: "🇹🇴",
    phoneCode: "+676",
  },
  {
    en: "Trinidad and Tobago",
    short: "TT",
    emoji: "🇹🇹",
    phoneCode: "+1868",
  },
  {
    en: "Tunisia",
    short: "TN",
    emoji: "🇹🇳",
    phoneCode: "+216",
  },
  {
    en: "Turkey",
    short: "TR",
    emoji: "🇹🇷",
    phoneCode: "+90",
  },
  {
    en: "Turkmenistan",
    short: "TM",
    emoji: "🇹🇲",
    phoneCode: "+993",
  },
  {
    en: "Turks and Caicos Islands",
    short: "TC",
    emoji: "🇹🇨",
    phoneCode: "+1649",
  },
  {
    en: "Uganda",
    short: "UG",
    emoji: "🇺🇬",
    phoneCode: "+256",
  },
  {
    en: "Ukraine",
    short: "UA",
    emoji: "🇺🇦",
    phoneCode: "+380",
  },
  {
    en: "United Arab Emirates",
    short: "AE",
    emoji: "🇦🇪",
    phoneCode: "+971",
  },
  {
    en: "United Kingdom",
    short: "GB",
    emoji: "🇬🇧",
    phoneCode: "+44",
  },
  {
    en: "United States",
    short: "US",
    emoji: "🇺🇸",
    phoneCode: "+1",
  },
  {
    en: "Uruguay",
    short: "UY",
    emoji: "🇺🇾",
    phoneCode: "+598",
  },
  {
    en: "Uzbekistan",
    short: "UZ",
    emoji: "🇺🇿",
    phoneCode: "+998",
  },
  {
    en: "Vanuatu",
    short: "VU",
    emoji: "🇻🇺",
    phoneCode: "+678",
  },
  {
    en: "Venezuela",
    short: "VE",
    emoji: "🇻🇪",
    phoneCode: "+58",
  },
  {
    en: "Vietnam",
    short: "VN",
    emoji: "🇻🇳",
    phoneCode: "+84",
  },
  {
    en: "Virgin Islands, British",
    short: "VG",
    emoji: "🇻🇬",
    phoneCode: "+1340",
  },
  {
    en: "Virgin Islands, US",
    short: "VI",
    emoji: "🇻🇮",
    phoneCode: "+1284",
  },
  {
    en: "Yemen",
    short: "YE",
    emoji: "🇾🇪",
    phoneCode: "+967",
  },
  {
    en: "Zambia",
    short: "ZM",
    emoji: "🇿🇲",
    phoneCode: "+260",
  },
  {
    en: "Zimbabwe",
    short: "ZW",
    emoji: "🇿🇼",
    phoneCode: "+263",
  },
];
