import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { signUpUserrole } from "../../../utils/static";
import { fetchOptionInfo, signUp } from "../../../redux/action/users";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import CustomButtonSelect from "../../../components/Elements/CustomButtonSelect";
import Builder from "../../../components/Signup/builder";
import Subbie from "../../../components/Signup/subbie";
import PersonalInfo from "../../../components/Signup/PersonalInfo";
import Loader from "../../../components/Elements/Loading";

function SignUpBody({ fetchOptionInfo, signUp, isCreating, industryTrades, error }) {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [selectedUserRole, setUserRole] = useState(signUpUserrole[0].role);
  const [selectAgree, setSelectAgree] = useState(false);
  const [step, setStep] = useState("info");
  const [userData, setUserData] = useState(null);
  const [locationState, setLocationState] = useState(1);
  const [industry, setIndustry] = useState([]);
  const [abnVal, setAbnVal] = useState("");
  const [extraErr, setExtraErr] = useState({
    abnerr: false,
    agreeErr: false,
    addressErr: false,
  });
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyLocationData, setCompanyLocationData] = useState({
    latitude: undefined,
    longitude: undefined,
    postcode: undefined,
    state: undefined,
    suburb: undefined,
    street: undefined,
  });

  const setCompanyLocation = (address, addressMetadata) => {
    console.log("addressMetadata", addressMetadata);
    setCompanyAddress(address);
    setCompanyLocationData({
      latitude: addressMetadata.latitude,
      longitude: addressMetadata.longitude,
      postcode: addressMetadata.zip,
      state: addressMetadata.state,
      suburb: addressMetadata.suburb,
      street: addressMetadata.street,
    });
  };

  const onChangeRole = (role) => {
    setUserRole(role.target.value);
  };

  const changeState = (e) => {
    setLocationState(e.target.value);
  };

  const onChangeIndustry = (e) => {
    setIndustry(e);
  };

  const setAgreeFlag = () => {
    setSelectAgree(!selectAgree);
    setExtraErr((prevState) => ({
      ...prevState,
      agreeErr: false,
    }));
  };

  const setAbnErr = (val) => {
    setAbnVal(val);
    setExtraErr((prevState) => ({
      ...prevState,
      abnerr: false,
    }));
  };
  const checkABNLength = (abn) => abn.length < 11;

  const onSubmit = (data) => {
    if (step === "info") {
      setUserData(data);
      setStep("submit");
    } else {
      const sendData = {
        ...userData,
        ...data,
        user_type: selectedUserRole,
        id_state: locationState,
        company_address: companyAddress,
        company_latitude: companyLocationData.latitude,
        company_longitude: companyLocationData.longitude,
        company_state: companyLocationData.state,
        company_suburb: companyLocationData.suburb,
        company_street: companyLocationData.street,
        company_postcode: companyLocationData.postcode,
        industry,
      };

      console.log("sending signup data", sendData);

      setExtraErr((prevState) => ({
        ...prevState,
        abnerr: checkABNLength(sendData.abn),
        agreeErr: !selectAgree,
        addressErr: companyAddress.split(",").length < 0,
      }));

      if (!isCreating && selectAgree && !checkABNLength(sendData.abn)) {
        signUp(sendData).then(() => {
          history.push("/confirmWait");
        });
      }
    }
  };

  const validation = () => {
    if (step === "submit") {
      setExtraErr((prevState) => ({
        ...prevState,
        abnerr: checkABNLength(abnVal),
        agreeErr: !selectAgree,
        addressErr: companyAddress.split(",").length < 0,
      }));
    }
  };

  useEffect(() => {
    fetchOptionInfo();
  }, [fetchOptionInfo]);

  const filterIndustryTrades = industryTrades.filter((o) => !industry.includes(o));
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {step === "info" && (
        <CustomButtonSelect data={signUpUserrole} onChange={onChangeRole} role={selectedUserRole} />
      )}
      {error && <div className="validation-error">{error}</div>}
      {step === "info" && <PersonalInfo register={register} errors={errors} />}
      {step === "submit" &&
        (selectedUserRole === 1 ? (
          <Builder
            register={register}
            errors={errors}
            abnerr={extraErr.abnerr}
            setAbnErr={setAbnErr}
            setAgree={setAgreeFlag}
            selectAgree={extraErr.agreeErr}
            onChangeState={changeState}
            addressErr={extraErr.addressErr}
            setCompanyLocation={setCompanyLocation}
            companyAddress={companyAddress}
          />
        ) : (
          <Subbie
            register={register}
            errors={errors}
            abnerr={extraErr.abnerr}
            setAbnErr={setAbnErr}
            setAgree={setAgreeFlag}
            filterIndustryTrades={filterIndustryTrades}
            selectAgree={extraErr.agreeErr}
            onChangeState={changeState}
            onChangeIndustry={onChangeIndustry}
            addressErr={extraErr.addressErr}
            setCompanyLocation={setCompanyLocation}
            companyAddress={companyAddress}
          />
        ))}
      <div className="form-group">
        <button className="btn btn-lg btn-primary btn-block" onClick={validation}>
          {step === "info" ? (
            "Next"
          ) : (
            <>
              {isCreating && <div className="spinner-grow spinner-grow-sm" role="status" />}
              <span>Register</span>
            </>
          )}
        </button>
      </div>
    </form>
  );
}

SignUpBody.propTypes = {
  isCreating: PropTypes.bool.isRequired,
  signUp: PropTypes.func.isRequired,
  fetchOptionInfo: PropTypes.func.isRequired,
  error: PropTypes.string,
  industryTrades: PropTypes.array,
};

SignUpBody.defaultProps = {
  error: null,
  industryTrades: [],
};

const mapStateToProps = (store) => ({
  isCreating: store.usersData.isCreatingList,
  industryTrades: store.usersData.industryTrades,
  error: store.usersData.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOptionInfo,
      signUp,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignUpBody);
