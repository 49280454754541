import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

const QuotesHeader = ({ backLink }) => (
  <div className="quote-bar">
    <div className="container">
      <Link to={backLink ? backLink : "/dashboard"} className="back-link">
        &lt; Back
      </Link>
      <h2>Add quotes</h2>
    </div>
  </div>
);

export default QuotesHeader;
