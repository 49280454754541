import React, { useState } from "react";
import ProfileSettings from "../../components/Settings/ProfileSettings";
import Account from "../../components/Settings/Account";
import Notifications from "../../components/Settings/Notifications";
import Invoices from "../../components/Settings/Invoices";
import Licenses from "../../components/Settings/Licenses";
import SettingsHeader from "./SettingHeader";

import "./styles.scss";

const tabs = [
  { name: "Profile Settings", component: <ProfileSettings /> },
  { name: "Account", component: <Account /> },
  { name: "Notifications", component: <Notifications /> },
  { name: "Invoices", component: <Invoices /> },
  { name: "Licenses", component: <Licenses /> },
];

const Settings = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div className="settings-wrapper">
      <SettingsHeader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab.component}
    </div>
  );
};

export default Settings;
