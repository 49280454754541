import React from "react";
import SignInBody from "../../../components/SignIn";
import SigninHeader from "../../../components/SignIn/SigninHeader";
import SigninFooter from "./SigninFooter";

import "./style.scss";

function SigninLeft() {
  return (
    <div className="auth-wrapper">
      <SigninHeader />
      <SignInBody />
      <SigninFooter />
    </div>
  );
}

export default SigninLeft;
