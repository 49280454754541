import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";
import InputProjectName from "./InputProjectName";

const NewProjectName = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({ step: state.newProject.step, data: state.newProject.data }));

  const onChangeName = useCallback(
    (e) => {
      dispatch(newProjectSlice.actions.setName(e.target.value));
    },
    [dispatch]
  );

  return (
    <InputProjectName label="Project Name" id="new-project-name" value={data.name} onChange={onChangeName} />
  );
};

export default NewProjectName;
