import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

const Pagination = ({ data, page, fetchData, limit }) => {
  const pages = Array(Math.floor(data.length / limit))
    .fill(null)
    .map((_, index) => index + 1);

  const updateData = (pageNum) => {
    fetchData(pageNum, limit, null);
  };

  return (
    <nav aria-label="Page navigation" className="page_navigation">
      {data.length > 10 && (
        <ul className="pagination_body">
          <li>
            <button
              onClick={page > 1 ? () => updateData(page - 1) : () => {}}
              className={`pagination_bt ${page <= 1 ? "disabled" : ""}`}>
              Prev Page
            </button>
          </li>
          <>
            {pages.map((item) => (
              <li key={`pagination_${item}`} onClick={() => updateData(item)}>
                <button className={`pagination_bt page_num ${page === item ? "active" : ""}`}>{item}</button>
              </li>
            ))}
          </>
          <li>
            <button
              onClick={page <= pages.length ? () => updateData(page + 1) : () => {}}
              className={`pagination_bt ${page > pages.length ? "disabled" : ""}`}>
              Next Page
            </button>
          </li>
        </ul>
      )}
    </nav>
  );
};

Pagination.propTypes = {
  data: PropTypes.array,
  page: PropTypes.number,
  limit: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  data: [],
  page: 1,
  limit: 10,
};

export default Pagination;
