import React from "react";
import QuotesHeader from "./QuotesHeader";
import QuotesBody from "./QuotesBody";

import "./styles.scss";

const QuoteList = ({ fetching }) => {
  return (
    <div className="quotes-wrapper">
      <QuotesHeader />
      <QuotesBody fetching={fetching} />
    </div>
  );
};

export default QuoteList;
