import React from "react";
import AllTendersBody from "./AllTendersBody";
import AllTendersHeader from "./AllTendersHeader";
import "./style.scss";

function AllTenders({ yourProjects }) {
  return (
    <div className="all-tenders-content">
      <AllTendersHeader />
      <AllTendersBody yourProjects={yourProjects} />
    </div>
  );
}

export default AllTenders;
