import React from "react";
import DatePicker from "../../../../../components/Elements/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";

const NewProjectAnticipatedStartDate = () => {
  const dispatch = useDispatch();
  const { anticipatedStartDate } = useSelector((state) => ({
    anticipatedStartDate: state.newProject.data.anticipatedStartDate,
  }));

  const onChange = (date) => {
    dispatch(newProjectSlice.actions.setAnticipatedStartDate(date));
  };

  return (
    <div className="form-group">
      <label className="form-label">Anticipated Start Date</label>
      <div className="form-control-wrap">
        <DatePicker value={anticipatedStartDate} onChange={onChange} />
      </div>
    </div>
  );
};

export default NewProjectAnticipatedStartDate;
