import React from "react";
import DatePicker from "../../../../../components/Elements/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";

const NewProjectQuotesDueBy = () => {
  const dispatch = useDispatch();
  const { quotesDueBy } = useSelector((state) => ({ quotesDueBy: state.newProject.data.quotesDueBy }));

  const onChange = (date) => {
    dispatch(newProjectSlice.actions.setQuotesDueBy(date));
  };

  return (
    <div className="form-group">
      <label className="form-label">Quotes Due By</label>
      <div className="form-control-wrap">
        <DatePicker value={quotesDueBy} onChange={onChange} />
      </div>
    </div>
  );
};

export default NewProjectQuotesDueBy;
