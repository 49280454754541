import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { Form, message } from "antd";

import storage from "../../../../services/storage.service";
import { updateUserTrades } from "../../../../redux/action/users";
import { WizardSteps, newProjectSlice } from "../../../../redux/newProject/slice";
import { CreateNewTenders } from "../../../../redux/action/tenders";
import NewProjectName from "./NewProjectName";
import NewProjectVisibility from "./NewProjectVisibility";
import NewProjectAddress from "./NewProjectAddress";
import NewProjectBudget from "./NewProjectBudget";
import NewProjectQuotesDueBy from "./NewProjectQuotesDueBy";
import NewProjectAnticipatedStartDate from "./NewProjectAnticipatedStartDate";
import NewProjectAnticipatedEndDate from "./NewProjectAnticipatedEndDate";
import NewProjectDescription from "./NewProjectDescription";
import NewProjectDocRequests from "./NewProjectDocRequests";
import NewProjectCategory from "./NewProjectCategory";
import NewProjectCheckBoxes from "./NewProjectCheckBoxes";
import NewProjectTable from "./NewProjectTable";
import NewProjectClassification from "./NewProjectClassification";
import "./index.scss";

const baseClass = "new-project-wizard";

const NewProjectWizard = ({ CreateNewTenders }) => {
  const [formStep1] = Form.useForm();
  const [formStep2] = Form.useForm();
  const [formStep3] = Form.useForm();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const { step, data, userId, trades } = useSelector((state) => ({
    step: state.newProject.step,
    data: state.newProject.data,
    trades: state.newProject.data.trades,
    userId: state.usersData.authUser.data.authUser.id,
  }));

  const onClickContinue = () => {
    const container = document.getElementById("new-project");
    if (container) container.scrollTop = 0;
    const nextStep =
      step === WizardSteps.Info
        ? WizardSteps.Checkboxes
        : step === WizardSteps.Checkboxes
        ? WizardSteps.Result
        : WizardSteps.Result;
    dispatch(newProjectSlice.actions.setStep(nextStep));
  };

  const onClickBack = () => {
    const container = document.getElementById("new-project");
    if (container) container.scrollTop = 0;
    const nextStep =
      step === WizardSteps.Result
        ? WizardSteps.Checkboxes
        : step === WizardSteps.Checkboxes
        ? WizardSteps.Info
        : WizardSteps.Info;
    dispatch(newProjectSlice.actions.setStep(nextStep));
  };

  const onClickSubmit = () => {
    const updatedData = {
      ...data,
      userId,
    };

    console.log("onClickSubmit", updatedData);

    CreateNewTenders({ ...updatedData, file_ids: files }).then(() => {
      dispatch(updateUserTrades({ company_trades: updatedData.trades }));
      window.location.href = "/dashboard";
    });
  };

  const handleFinishStep1 = useCallback(
    () => {
      onClickContinue();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClickContinue, step]
  );

  const handleFinishStep2 = useCallback(
    () => {
      onClickContinue();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClickContinue, step]
  );

  const handleFinishStep3 = useCallback(
    () => {
      onClickSubmit();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClickSubmit, step]
  );
  const handleClickContinue = () => {
    switch (step) {
      case WizardSteps.Info:
        formStep1.submit();
        break;
      case WizardSteps.Checkboxes:
        formStep2.submit();
        break;

      case WizardSteps.Result:
        if (files.length < trades.length) message.error("Attach a file to each package, please");
        else formStep3.submit();
        break;

      default:
        break;
    }
  };

  const onFinishFailed = useCallback((errorInfo) => {
    console.log("Failed:", errorInfo);
  }, []);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__form`}>
        {step === WizardSteps.Info && (
          <Form
            layout="vertical"
            onFinish={handleFinishStep1}
            onFinishFailed={onFinishFailed}
            form={formStep1}
            initialValues={{}}>
            <NewProjectName />
            <NewProjectClassification />
            <NewProjectVisibility />
            <div className="details-container">
              <h4 className={`title ${baseClass}__details`}>Site Details</h4>
              <NewProjectAddress />
            </div>
            <NewProjectBudget />
            <NewProjectQuotesDueBy />
            <div className={`${baseClass}__dates`}>
              <NewProjectAnticipatedStartDate />
              <NewProjectAnticipatedEndDate />
            </div>
            <NewProjectDescription />
            <NewProjectDocRequests />
            <NewProjectCategory />
          </Form>
        )}
        {step === WizardSteps.Checkboxes && (
          <Form
            layout="vertical"
            onFinish={handleFinishStep2}
            onFinishFailed={onFinishFailed}
            form={formStep2}
            initialValues={{}}>
            <NewProjectCheckBoxes />
          </Form>
        )}
        {step === WizardSteps.Result && (
          <>
            <Form
              layout="vertical"
              onFinish={handleFinishStep3}
              onFinishFailed={onFinishFailed}
              form={formStep3}
              initialValues={{}}>
              <NewProjectTable files={files} setFiles={setFiles} />
            </Form>
          </>
        )}
        <div className={`${baseClass}__continue`}>
          {step === WizardSteps.Info ? (
            <div />
          ) : (
            <button href="#" className="btn btn-wider btn-light" onClick={onClickBack}>
              <em className="icon ni ni-arrow-left" />
              <span>Back</span>
            </button>
          )}
          <button
            disabled={step === WizardSteps.Result && files.length < trades.length}
            href="#"
            onClick={handleClickContinue}
            className="btn btn-wider btn-primary">
            <span>{step === WizardSteps.Result ? "Add project" : "Continue"}</span>
            {step !== WizardSteps.Result && <em className="icon ni ni-arrow-right" />}
          </button>
        </div>
      </div>
    </div>
  );
};

NewProjectWizard.propTypes = {
  CreateNewTenders: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      CreateNewTenders,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(NewProjectWizard);
