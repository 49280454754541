import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";

const NewProjectDocRequests = () => {
  const dispatch = useDispatch();
  const { docRequest } = useSelector((state) => ({ docRequest: state.newProject.data.docRequest }));

  const onChange = useCallback(
    (e) => {
      dispatch(newProjectSlice.actions.setDocRequests(e.target.value));
    },
    [dispatch]
  );

  return (
    <div className="form-group">
      <label className="form-label">Doc Requests</label>
      <div className="form-control-wrap">
        <div className="new-project-wizard__documents">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="new-project-document-auto"
              name="new-project-document-requests"
              className="custom-control-input"
              value="auto"
              checked={docRequest === "auto"}
              onChange={onChange}
            />
            <label className="custom-control-label" htmlFor="new-project-document-auto">
              Auto-approve all download requests
            </label>
          </div>
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="new-project-document-approve"
              name="new-project-document-requests"
              className="custom-control-input"
              value="approve"
              checked={docRequest === "approve"}
              onChange={onChange}
            />
            <label className="custom-control-label" htmlFor="new-project-document-approve">
              I must first approve download requests
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProjectDocRequests;
