import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

function MoveToHome({ authUser }) {
  return authUser.userid !== "" ? <Redirect to="/dashboard" /> : <Redirect to="/login" />;
}

MoveToHome.propTypes = {
  authUser: PropTypes.object,
};

MoveToHome.defaultProps = {
  authUser: {
    userid: "",
    first_name: "",
    last_name: "",
    email: "",
    role: "",
  },
};

const mapStateToProps = (store) => ({
  authUser: store.usersData.authUser,
});

export default connect(mapStateToProps)(MoveToHome);
