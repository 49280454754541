import React, { useEffect, useState } from "react";
import { Timeline, Divider } from "antd";

import "./style.scss";
import tendersService from "../../../../services/tenders.service";

function History({ id_tender }) {
  const [history, setHistory] = useState([]);
  useEffect(() => {
    tendersService.GetTenderHistory({ id_tender }).then((res) => setHistory(res));
  }, []);

  return (
    <div className="project-history">
      <Divider className="sub-menu-divider">
        <span className="description">History</span>
      </Divider>
      <div>
        <Timeline>
          {history.map((historyItem, index) => (
            <Timeline.Item key={index}>
              {historyItem.company_name} is now also tendering this project.
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </div>
  );
}

export default React.memo(History);
