import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { newProjectSlice } from "../../../../../redux/newProject/slice";

const NewProjectCheckBoxes = () => {
  const { industryTrades, trades, categories } = useSelector((state) => ({
    industryTrades: state.usersData.industryTrades,
    trades: state.newProject.data.trades,
    categories: state.usersData.categories,
  }));
  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(newProjectSlice.actions.setTrades(Number(e.target.value)));
  };

  return (
    <div className="new-project-wizard__checkboxes">
      {categories.map((category) => {
        const data = industryTrades.filter((el) => el.tradeCategory === category.value);

        return (
          <React.Fragment key={category.key}>
            <h5 className="title new-project-wizard__checkbox-title">{category.value}</h5>
            {data.map((el) => (
              <div className="custom-control custom-checkbox new-project-wizard__checkbox" key={el.key}>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  value={el.key}
                  checked={trades.includes(el.key)}
                  onChange={onChange}
                  id={`new-project-trades-${el.key}`}
                />
                <label className="custom-control-label" htmlFor={`new-project-trades-${el.key}`}>
                  {el.value}
                </label>
              </div>
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default NewProjectCheckBoxes;
