import tendersService from "../../services/tenders.service";

import types from "../actionType";
import { fetchCompanyWatchList } from "./companyWatchList";

export const fetchData =
  (page, state, limit, filters = {}, yourProjects) =>
  (dispatch, getState) => {
    if (getState().tendersData.isFetchingList) {
      return Promise.reject();
    }

    const pageNumber = page ? parseInt(page, 10) : getState().tendersData.page;
    const tenderState = state || getState().tendersData.tenderState;
    const companyOffice = getState().usersData.companyOffice;
    const pageLimit = limit ? parseInt(limit, 10) : getState().tendersData.limit;

    dispatch({
      type: types.TENDERS_FETCH_REQUEST,
    });

    return tendersService
      .GetAll(pageNumber, tenderState, pageLimit, filters, yourProjects)
      .then((data) => {
        dispatch({
          type: types.TENDERS_FETCH_SUCCESS,
          payload: {
            data,
            pageNumber,
            pageLimit,
            tenderState,
            companyOffice,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.TENDERS_FETCH_FAILURE,
          payload: { error },
        });
        throw error;
      });
  };

export const SelectWatchList = (idProject, idWatch, idCompany) => (dispatch, getState) => {
  if (getState().tendersData.isUpdating) {
    return Promise.reject();
  }

  dispatch({
    type: types.WATCH_LIST_OPTION_REQUEST,
  });

  return tendersService
    .UpdateWatchList(idProject, idWatch, idCompany)
    .then(() => {
      dispatch({
        type: types.WATCH_LIST_OPTION_SUCCESS,
        payload: {
          idProject,
          idWatch,
          idCompany,
        },
      });

      dispatch(fetchCompanyWatchList(idCompany));
    })
    .catch((error) => {
      dispatch({
        type: types.WATCH_LIST_OPTION_FAILURE,
        payload: { error },
      });
      throw error;
    });
};

export const CreateNewTenders = (tenderData) => (dispatch, getState) => {
  if (getState().tendersData.isUpdating) {
    return Promise.reject();
  }

  dispatch({
    type: types.CREATE_NEW_TENDER_REQUEST,
  });

  return tendersService
    .CreateNewTenders(tenderData)
    .then(() => {
      dispatch({
        type: types.CREATE_NEW_TENDER_SUCCESS,
        payload: {
          tenderData,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: types.CREATE_NEW_TENDER_FAIL,
        payload: { error },
      });
      throw error;
    });
};
