import React from "react";

import "./styles.scss";

const DirectoryHeader = () => (
  <div className="directory-bar">
    <div className="container">
      <ul className="menu-items">
        <li>
          <a href="/db/" className="active">
            Builders
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default DirectoryHeader;
