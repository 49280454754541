import React from "react";
import PropTypes from "prop-types";
import { loginSteps } from "../../../utils/static";
import SigninLeft from "../Signin/SigninLeft";
import AfterSignUp from "../../../components/Signup/afterSignUp";
import RightSide from "../RightSide";
import ForgotPassword from "../../../components/Signup/forgotPassword";
import ResetPassword from "../../../components/Signup/resetPassword";
import UpdatePassword from "../../../components/Signup/updatePassword";
import SignUpLeft from "./SignupLeft";

function StepPage({ step }) {
  const getStepPage = () => {
    switch (step) {
      case loginSteps.login:
        return <SigninLeft />;
      case loginSteps.signUp:
        return <SignUpLeft />;
      case loginSteps.afterSignUp:
        return <AfterSignUp />;
      case loginSteps.forgotPassword:
        return <ForgotPassword />;
      case loginSteps.resetPassword:
        return <ResetPassword />;
      case loginSteps.updatePassword:
        return <UpdatePassword />;
      default:
        return "";
    }
  };
  return (
    <div className="steps">
      <RightSide />
      <div className="left-side">{getStepPage()}</div>
    </div>
  );
}
StepPage.propTypes = {
  step: PropTypes.string,
};

StepPage.defaultProps = {
  step: loginSteps.login,
};

export default StepPage;
