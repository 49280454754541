import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { SearchOutlined, CloseCircleFilled } from "@ant-design/icons";

import "./style.scss";

function CustomSearchInput({ searchString, placeholder, onChange, onKeyDown, clearSearch }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <>
      <Input
        className="custom-search-box"
        placeholder={placeholder}
        value={searchString}
        suffix={<SearchOutlined />}
        prefix={
          searchString ? (
            <CloseCircleFilled
              onClick={() => {
                onChange("");
                if (clearSearch) clearSearch();
              }}
            />
          ) : (
            ""
          )
        }
        onChange={handleChange}
        onKeyDown={onKeyDown}
      />
    </>
  );
}

CustomSearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

CustomSearchInput.defaultProps = {
  placeholder: "Search by project name or address",
  onChange: () => {},
};

export default CustomSearchInput;
