import React, { useState, useEffect, useCallback } from "react";
import { usePath } from "hookrouter";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/pro-light-svg-icons";
import "./style.scss";
import CustomSelectSetting from "../../components/Elements/CustomSelectSetting";
import AddNewProjectButton from "./NewProject/AddNewProjectButton";

const otherTabs = [
  { value: "Tenders", link: "/dashboard" },
  // { value: "Invitation & Requests", link: "/invitations" },
  { value: "Quotes", link: "/quote_list" },
  { value: "Watchlist", link: "/watchlist" },
  { value: "Directory", link: "/db/" },
  { value: "Team", link: "/team" },
  { value: "Settings", link: "/account/profile" },
];

const builderTabs = [
  { value: "Your projects", link: "/dashboard" },
  { value: "All Tenders", link: "/alltenders" },
  { value: "Settings", link: "/account/profile" },
];

const Menu = ({ open, setOpenMenu, tabs, url, authUser, signOut, isBuilder }) => {
  const handleClose = () => {
    setOpenMenu(false);
  };

  return (
    <ul className={`menu-nav tab-content ${open ? "close-menu" : "open-menu"}`}>
      <div className="dashboard-header_selector mt-3">
        <CustomSelectSetting onSelect={handleClose} authUser={authUser} signOut={signOut} />
      </div>
      {tabs.map((item) => (
        <li
          onClick={handleClose}
          className={`tab ${url === item.link ? "active" : ""}`}
          key={`builder-tab-${item.link}`}>
          <Link to={item.link}>{item.value}</Link>
        </li>
      ))}
      {isBuilder && <AddNewProjectButton onClick={handleClose} />}
    </ul>
  );
};

function HeaderTabs({ menu, setMenu, openMenu, setOpenMenu, authUser, signOut }) {
  const history = useHistory();
  const path = usePath();
  const [url, setUrl] = useState(path);
  history.listen((location) => {
    setUrl(location.pathname);
  });

  const showMenu = useCallback(() => {
    if (window.outerWidth < 889 || window.innerWidth < 889) {
      setMenu(false);
    } else {
      setMenu(true);
    }
  }, [setMenu]);

  useEffect(() => {
    showMenu();
    window.addEventListener("resize", showMenu);
    return () => {
      window.removeEventListener("resize", showMenu);
    };
  }, [showMenu]);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const isBuilder = authUser.user_type === "1" || authUser.user_type === 1;

  const tabs = isBuilder ? builderTabs : otherTabs;
  return (
    <div className="tab-menu">
      {menu ? (
        <>
          <ul className="tab-content">
            {tabs.map((item) => (
              <li className={`tab ${url === item.link ? "active" : ""}`} key={`builder-tab-${item.link}`}>
                <Link to={item.link}>{item.value}</Link>
              </li>
            ))}
          </ul>
          {isBuilder && <AddNewProjectButton />}
        </>
      ) : (
        <>
          <FontAwesomeIcon className="bar-icon" icon={openMenu ? faTimes : faBars} onClick={handleOpenMenu} />
          <Menu
            authUser={authUser}
            tabs={tabs}
            url={url}
            open={openMenu}
            setOpenMenu={setOpenMenu}
            signOut={signOut}
            isBuilder={isBuilder}
          />
        </>
      )}
    </div>
  );
}

HeaderTabs.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default HeaderTabs;
