import React, { useState } from "react";
import WatchingListBody from "./WatchingListBody/WatchingListBody";
import InvitationCorresp from "./InvitationCorresp";
import InvitationRequest from "./InvitationRequest";
import WatchlistHeader from "./watchlistHeader";

import "./style.scss";

function Watchlist() {
  const invitationHeader = [
    { key: "open_recent", value: "Open & Recent" },
    // { key: "activity", value: "Activity" },
    // { key: "archive", value: "Archive" },
  ];

  const [selectHeader, setSelectHeader] = useState("open_recent");

  const onSelectHeader = (selectedKey) => {
    setSelectHeader(selectedKey);
  };

  return (
    <div className="invitation-content">
      <WatchlistHeader data={invitationHeader} onChangeTab={onSelectHeader} selectedItem={selectHeader} />
      {selectHeader === "open_recent" && <WatchingListBody />}
      {selectHeader === "requests" && <InvitationRequest />}

      {selectHeader === "correspondence" && <InvitationCorresp />}
    </div>
  );
}

export default Watchlist;
