import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

function WatchlistHeader({ data, onChangeTab, selectedItem }) {
  return (
    <div className="invitation-header d-flex justify-content-center">
      <div className="wrapper">
        <ul className="menu-items">
          {data.map((dataItem) => (
            <li>
              <span className={dataItem.key === selectedItem ? "active" : ""} onClick={() => onChangeTab(dataItem.key)}>
                {dataItem.value}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

WatchlistHeader.propTypes = {
  data: PropTypes.array,
  onChangeTab: PropTypes.func,
  selectedItem: PropTypes.string,
};

WatchlistHeader.defaultProps = {
  data: [],
  onChangeTab: () => {},
  selectedItem: "",
};

export default WatchlistHeader;
