import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

function Notes({ notes }) {
  return (
    <div className="project-note">
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <h3>Notes</h3>
        <div className="pull-right">
          <strong className="mr-1">Tip:</strong>
          Notes are seen by your team only.
        </div>
      </div>
      <div className="notes_form">
        <textarea name="note[text]" className="form-control" rows={3} placeholder="Type a new note here" />
        <button className="disable notes_button">Save Note</button>
      </div>
      <div>
        <span>No notes against this project yet.</span>
      </div>
    </div>
  );
}

Notes.propTypes = {
  notes: PropTypes.array,
};

Notes.defaultProps = {
  notes: [],
};

export default Notes;
