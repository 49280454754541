import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, Dropdown } from "antd";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import { Redirect, Link } from "react-router-dom";

import "./style.scss";

export const profile = [
  {
    label: "Settings",
    key: "settings",
  },
  {
    label: "Logout",
    key: "logout",
  },
  {
    label: "Logout",
    key: "logout",
    disable: true,
  },
  {
    label: "View Guides & FAQs",
    key: "view_guide",
  },
  {
    label: "Contact TNDR",
    key: "contact_tndr",
  },
];

function CustomSelectSetting({ authUser, signOut, onSelect }) {
  const [signoffFg, setSignOffFg] = useState(false);
  const handleMenuClick = (e) => {
    onSelect();
    if (e.key === "logout") {
      signOut();
      localStorage.clear();
      setSignOffFg(true);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="settings">
        <Link to="/account/profile">Settings</Link>
      </Menu.Item>
      <Menu.Item key="logout">Logout</Menu.Item>
      <Menu.Item key="help_support" disabled style={{ fontWeight: 900 }}>
        HELP & SUPPORT
      </Menu.Item>
      <Menu.Item key="view_guide">View Guides & FAQs</Menu.Item>
      <Menu.Item key="contact_tndr">
        <a href="mailto:support@tndr.com.au">Contact TNDR</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {signoffFg && <Redirect to="/" />}
      <Dropdown overlay={menu} trigger={["click"]}>
        <span className="header-selector d-flex justify-between" style={{ marginLeft: 8 }}>
          <div className="info-label flex-column d-flex">
            <span className="username">{`${authUser.first_name} ${authUser.last_name}`}</span>
          </div>
          <DownOutlined />
        </span>
      </Dropdown>
    </div>
  );
}

CustomSelectSetting.propTypes = {
  authUser: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};

CustomSelectSetting.defaultProps = {
  authUser: {},
  signOut: () => {},
  onSelect: () => {},
};

export default CustomSelectSetting;
