import httpService from "./http.service";

const companiesUrl = "/companies";

const UpdateYourCompany = (companyData) =>
  httpService
    .put(`${companiesUrl}/update-company`, companyData)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

const UpdateYourCompanyOffice = (companyData) =>
  httpService
    .put(`${companiesUrl}/update-company-office`, companyData)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.message));

export default {
  UpdateYourCompany,
  UpdateYourCompanyOffice,
};
