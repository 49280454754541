import React, { useState } from "react";
// import PropTypes from 'prop-types';
import CustomSearchInput from "../../../components/Elements/CustomSearchInput";
import CustomSelect from "../../../components/Elements/CustomSelect";

import "./style.scss";
import InvitationTable from "../../../components/Elements/InvitationTable";

function InvitationRequest() {
  const [selectItem, setSelectItem] = useState("active");

  const selectActive = (item) => () => {
    setSelectItem(item);
  };

  const searchByName = (searchKey) => {
    console.log(searchKey);
  };

  const responseBuilder = [
    { key: "all", label: "All" },
    { key: "unactioned", label: "Unactioned" },
    { key: "not_quoting", label: "Not Quoting" },
    { key: "quoting", label: "Quoting" },
    { key: "quoted", label: "Quoted" },
  ];

  const data = [
    {
      id: "84264",
      project: "Indooroopilly Shopping Centre Mini Majors Project",
      invited: "ATG Projects",
      team: ["DB"],
      budget: ["10m", "15m"],
      location: "Indooroopilly, QLD",
      package: "Full Set",
      quotes_due: [
        {
          date_tender_close: "02 Oct 2020",
        },
      ],
      response: {
        value: "quoting",
        data: [
          { key: "quoting", value: "Quoting" },
          { key: "not_quoting", value: "Not Quoting" },
        ],
        onChange: () => {},
        onClick: () => {},
      },
      docstatus: "obsolete",
    },
    {
      id: "85563",
      project: "216 Gympie Road, Kedron",
      invited: "Westcon Site Management Pty Ltd",
      team: ["DB"],
      budget: ["10m", "15m"],
      location: "Kedron, QLD",
      package: "Full Set",
      quotes_due: [
        {
          date_tender_close: "02 Oct 2020",
        },
      ],
      response: {
        value: "quoting",
        data: [
          { key: "quoting", value: "Quoting" },
          { key: "not_quoting", value: "Not Quoting" },
        ],
        onChange: () => {},
        onClick: () => {},
      },
      docstatus: "obsolete",
    },
    {
      id: "85434",
      project: "19 Bronte Ave Burwood",
      invited: "Pulis Constructions",
      team: ["DB"],
      budget: ["10m", "15m"],
      location: "Burwood, VIC",
      package: "Full Set",
      quotes_due: [
        {
          date_tender_close: "02 Oct 2020",
        },
      ],
      response: {
        value: "quoting",
        data: [
          { key: "quoting", value: "Quoting" },
          { key: "not_quoting", value: "Not Quoting" },
        ],
        onChange: () => {},
        onClick: () => {},
      },
      docstatus: "obsolete",
    },
  ];

  return (
    <div className="invitation_body">
      <ul className="nav nav-tabs" role="tablist">
        <li role="presentation" className={selectItem === "active" ? "active" : ""}>
          <label aria-controls="invitations-active" onClick={selectActive("active")}>
            Active &nbsp;
            <span className="badge badge-round-xs active-request-count">3</span>
          </label>
        </li>
        <li role="presentation" className={selectItem === "archive" ? "active" : ""}>
          <label aria-controls="invitations-archive" onClick={selectActive("archive")}>
            Archive &nbsp;
            <span className="badge badge-round-xs archive-request-count">3</span>
          </label>
        </li>
      </ul>

      <div className="invitation_container row">
        <div className="search-section row w-100">
          <div className="search-invitations col-4">
            <label>Search Invitations</label>
            <CustomSearchInput placeholder="Search by account name" onChange={searchByName} />
          </div>
        </div>
        <InvitationTable data={data} />
      </div>
    </div>
  );
}

InvitationRequest.propTypes = {};

InvitationRequest.defaultProps = {};

export default InvitationRequest;
