import React from "react";
import "./style.scss";
import { Divider } from "antd";
import QuotesBody from "../../../../container/Quotes/QuotesBody";

function TenderQuotes({ id_project }) {
  return (
    <div className="project-quotes">
      <Divider className="sub-menu-divider">
        <span className="description">Quotes</span>
      </Divider>
      <div>
        <QuotesBody id_project={id_project} fetching={false} />
      </div>
    </div>
  );
}

export default TenderQuotes;
