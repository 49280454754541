/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { faUpload, faEye, faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";

import "./style.scss";
import { Link } from "react-router-dom";
import { Button, Table } from "antd";

function Builder({ data, industryTrades }) {
  const columns = [
    {
      key: "builder",
      title: "Builder",
      dataIndex: "builder_name",
      render: (buiderName) => (
        <>
          <h5 className="builder_name">{buiderName.company_name}</h5>
          <div className="contact_details flex-column d-flex">
            <span className="phone">{buiderName.phone}</span>
            <span>{buiderName.name}</span>
            <a
              href="mailto:estimatingsa@hutchinsonbuilders.com.au?subject=RE%3A%20Ziptrak%20Development%20-%20Lot%20332%20Tonsley (via EstimateOne)"
              className="link-sidebar"
              target="_self">
              {buiderName.email}
            </a>
          </div>
        </>
      ),
    },
    {
      key: "quotes_due_by",
      title: "Quotes Due By",
      dataIndex: "quotes_due_by",
    },
    {
      key: "project_documents",
      title: "Project Documents",
      dataIndex: "project_documents",
      render: (project_documents) => (
        <div className="label">
          {Object.keys(project_documents).length ? (
            <>
              {Object.keys(project_documents).map((packageId) => {
                const trade = industryTrades.find((elem) => elem.key === Number(packageId));
                return (
                  <div className="d-flex justify-content-between package-line mb-2" key={packageId}>
                    <div>{trade.value}</div>
                    <div className="ml-2">
                      <Button className="button-icon mr-1 add_quote medium w-30">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="position-absolute"
                          href={`${process.env.REACT_APP_API_ORIGIN}/files/tenders/${project_documents[packageId]}`}>
                          <FontAwesomeIcon icon={faEye} />
                        </a>
                      </Button>
                      <Button className="button-icon add_quote medium w-30">
                        <a
                          rel="noreferrer"
                          className="position-absolute"
                          download
                          href={`${process.env.REACT_APP_API}/files/tender_file/${project_documents[packageId]}`}>
                          <FontAwesomeIcon className="small-icon" icon={faDownload} />
                        </a>
                      </Button>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            "No Package Available"
          )}
        </div>
      ),
    },
  ];

  const tableData = data.builders.map((builder) => ({
    builder_name: {
      phone: builder.phone,
      name: builder.name,
      company_name: builder.company_name,
      email: builder.email,
    },
    quotes_due_by: moment(builder.date_tender_close).format("dddd, MMMM Do YYYY"),
    project_documents: builder.packagesWithFileNames,
  }));
  return (
    <div className="project-stages">
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <h3>Builders & Documents</h3>
        <Link className="medium" to={`quotes/${data.key}`}>
          <Button>
            <FontAwesomeIcon icon={faUpload} />
            <span className="ml-1">Add Quotes</span>
          </Button>
        </Link>
      </div>
      <div className="d-flex flex-wrap justify-content-between">
        <Table className="w-100" columns={columns} dataSource={tableData} />
      </div>
    </div>
  );
}

Builder.propTypes = {
  data: PropTypes.object,
  industryTrades: PropTypes.array.isRequired,
};

Builder.defaultProps = {
  data: {
    builders: [],
  },
};

export default Builder;
